import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function AIImmaginationRange({ updateUserChoices, initialValue }) {

  const [valueAIImmagination, setValueAIImmagination] = useState(initialValue);

  const handleAIImmaginationChange = (event) => {
    const value = event.target.value;
    setValueAIImmagination(value);
    updateUserChoices("AIImmaginationRange", value);
  };

  const {t} = useTranslation();
  
  return (
    <div className="items-center flex flex-col">
      <div className="flex">
      <h1 className='font-title lg:justify-center flex items-center font-bold py-8 lg:py-2 lg:mb-2'>
        {t("AI IMMAGINATION")}
      <div className="w-[0.01px] h-[0.01px] relative">
        <InfoBox
        customClass={"absolute left-1 top-1/2 -translate-y-1/2"}
        text={t("How much imaginative the AI will be, high values tend to follow the concept better but while increasing contrast.")}/>
      </div>
      </h1>
      </div>
      <div className="flex justify-between max-w-screen items-center w-[93vw] mb-4 lg:w-[25.5rem]">
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">1</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">2</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">3</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">4</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">5</span>
      </div>
      <input
        type="range"
        min="1"
        max="5"
        value={valueAIImmagination}
        onChange={handleAIImmaginationChange}
        className="lg:w-[24.8rem] w-[90vw] appearance-none h-1 rounded-full bg-black opacity-85 outline-none transition-all duration-300"
      />
      <p className='font-paragraph text-xl mt-3 mb-1 lg:text-justify'>{t("The higher this value is, more the AI will follow your concept")}</p>
    </div>
  );


}