import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaHeart, FaDownload, FaTrash } from "react-icons/fa";
import styles from "./MasonryBox.module.css";
import axios from "axios";
import { add_Back } from "../../../../../address";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const MasonryBox = ({
  wallSrc,
  userProf,
  userName,
  userId,
  userQde_likes,
  onImageClick,
}) => {
  const [isHeartRed, setIsHeartRed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [likes, setLikes] = useState(userQde_likes);
  const [successMessage, setSuccessMessage] = useState("");

  const handleImageClick = () => {
    if (onImageClick) {
      onImageClick({
        wallSrc,
        userProf,
        userName,
        userId,
      });
    }
  };

  const token = Cookies.get("token");
  function verificarToken() {
    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const handleHeartClick = async (event) => {
    event.stopPropagation();
    setIsHeartRed(!isHeartRed);

    setLikes(isHeartRed ? likes - 1 : likes + 1);
    try {
      const response = await axios.put(
        `${add_Back}/galeria/${userId}`,
        {
          qde_likes: isHeartRed ? likes - 1 : likes + 1,
        },
        {
          headers: {
            Authorization: `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        }
      );
      console.log(
        "Número de likes atualizado no banco de dados:",
        response.data
      );
    } catch (error) {
      console.error("Erro ao atualizar o número de likes:", error);
    }
  };

  const handleDownloadClick = (event) => {
    event.stopPropagation();

    const byteCharacters = atob(wallSrc);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpg" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${userName}.jpg`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleDeleteClick = async (event) => {
    event.stopPropagation();
    const confirmDelete = window.confirm(
      "Você tem certeza que deseja excluir esta imagem?"
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${add_Back}/galeria/${userId}`, {
          headers: {
            Authorization: `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          setSuccessMessage("Imagem excluída com sucesso!");
          window.location.reload();
        } else {
          setSuccessMessage("Erro ao excluir a imagem. Tente novamente.");
        }
        console.log("Imagem excluída com sucesso:", response.data);
      } catch (error) {
        console.error("Erro ao excluir a imagem:", error);
        setSuccessMessage("Erro ao excluir a imagem. Tente novamente.");
      }
    }
  };

  return (
    <div
      className={styles["my-masonry"]}
      onClick={handleImageClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={`data:image/png;base64,${wallSrc}`}
        style={{ width: "100%" }}
        alt=""
      />
      {isHovered && (
        <>
          <div
            className={styles["download-icon"]}
            onClick={handleDownloadClick}
          >
            <FaDownload
              color="white"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                zIndex: 1,
              }}
            />
          </div>
          <div className={styles["delete-icon"]} onClick={handleDeleteClick}>
            <FaTrash
              color="white"
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                cursor: "pointer",
                zIndex: 1,
              }}
            />
          </div>
        </>
      )}
      <div className={`${styles["my-masnry-description"]} flex`}>
        <div
          className={`${styles["my-masnry-user-box"]} flex align-items-center`}
        >
          <div className={styles["my-masnry-user-prof"]}>
            <img src={userProf} alt="" />
          </div>
          <div
            className={`${styles["my-masnry-user-prof-desc"]} flex flex-column`}
          >
            <h1>{userName}</h1>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <FaHeart
              onClick={handleHeartClick}
              color={isHeartRed ? "red" : "white"}
              style={{
                transition: "color 0.5s ease",
                cursor: "pointer",
              }}
            />
            <span
              className="font-title ml-[0.0625rem] text-md mt-1"
              style={{ color: "white" }}
            >
              {likes}
            </span>
          </div>
        </div>
      </div>
      {successMessage && (
        <div className={styles["success-message"]}>{successMessage}</div>
      )}
    </div>
  );
};

MasonryBox.propTypes = {
  wallSrc: PropTypes.string.isRequired,
  userProf: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userQde_likes: PropTypes.number.isRequired,
  onImageClick: PropTypes.func,
};

export default MasonryBox;
