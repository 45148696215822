import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { FaHeart, FaDownload, FaTriangleExclamation } from "react-icons/fa6";
import styles from "./MasonryBox.module.css";
import axios from "axios"; // Importe o axios
import { add_Back } from "../../../../../address";
import { Context } from "../../../../../App";
import Cookies from "js-cookie";

const MasonryBox = ({
  wallSrc,
  userProf,
  userName,
  userId,
  userQde_likes,
  onImageClick,
}) => {
  const [isHeartRed, setIsHeartRed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [likes, setLikes] = useState(userQde_likes);
  const [warningMessage, setWarningMessage] = useContext(Context);

  const handleImageClick = () => {
    if (onImageClick) {
      onImageClick({
        wallSrc,
        userProf,
        userName,
        userId,
      });
    }
  };
  const handleHeartClick = async (event) => {
    event.stopPropagation();
    setIsHeartRed(!isHeartRed);

    // Atualizar o número de likes no banco de dados
    const token = Cookies.get("token");
    setLikes(isHeartRed ? likes - 1 : likes + 1);
    try {
      const response = await axios.put(
        `${add_Back}/galeria/${userId}`,
        {
          qde_likes: isHeartRed ? likes - 1 : likes + 1,
        },
        {
          headers: {
            Authorization: `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        }
      );

      console.log(
        "Número de likes atualizado no banco de dados:",
        response.data
      );
      // Atualizar o número de likes no estado do componente
    } catch (error) {
      console.error("Erro ao atualizar o número de likes:", error);
    }
  };

  const handleDownloadClick = (event) => {
    event.stopPropagation();

    // Converter a representação base64 em um blob
    const byteCharacters = atob(wallSrc);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpg" });

    // Criar um link de download com o blob
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${userName}.jpg`;

    // Simular o clique no link
    document.body.appendChild(link);
    link.click();

    // Remover o link
    document.body.removeChild(link);
  };
  //console.log('Imagem mansory layout:',wallSrc);

  const handleReportClick = () => {
    // Lógica para denúncia
    // alert("Denúncia registrada!");
    setWarningMessage("Reported successfully!");
  };

  return (
    <div
      className={styles["my-masonry"]}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleImageClick}
    >
      <img
        src={`data:image/jpg;base64,${wallSrc}`}
        className="w-full h-auto block"
        alt=""
      />
      {isHovered && (
        <>
          <div
            className={styles["download-icon"]}
            onClick={handleDownloadClick}
          >
            <FaDownload
              color="white"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                zIndex: 1,
              }}
            />
          </div>
          <div className={styles["report-icon"]} onClick={handleReportClick}>
            <FaTriangleExclamation
              color="white"
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                cursor: "pointer",
                zIndex: 1,
              }}
            />
          </div>
        </>
      )}
      <div className={`${styles["my-masnry-description"]} flex`}>
        <div
          className={`${styles["my-masnry-user-box"]} flex align-items-center`}
        >
          <div className={styles["my-masnry-user-prof"]}>
            <img src={userProf} alt="" />
          </div>
          <div className={`${styles["my-masnry-user-prof-desc"]} flex`}>
            <h1 className=" font-paragraph text-[140%] self-start">{userName}</h1>
          </div>
          <div className="flex items-end w-full flex-col">
            <div className="flex flex-col items-center justify-center">
              <FaHeart
                onClick={handleHeartClick}
                color={isHeartRed ? "red" : "white"}
                style={{
                  transition: "color 0.5s ease",
                  cursor: "pointer",
                }}
              />
              <span
                className="font-title ml-[0.0625rem] text-md mt-1"
                style={{ color: "white" }}
              >
                {likes}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MasonryBox.propTypes = {
  wallSrc: PropTypes.string.isRequired,
  userProf: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userQde_likes: PropTypes.number.isRequired, // Corrigi o typo aqui
  onImageClick: PropTypes.func,
};

export default MasonryBox;
