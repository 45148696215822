import React, { useEffect, useContext, useRef, useState } from "react";
import { Context } from "../../App";
import { useTranslation } from "react-i18next";

const NewPopUpComponent = ({ isClosable }) => {
  const [warningMessage, setWarningMessage] = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {
    if (warningMessage !== "Rendering..." && 
      warningMessage !== "Upscaling..." && 
      warningMessage !== "The rendering is taking up more time than expected..."
    ) {
      const timer = setTimeout(() => {
        setWarningMessage(null)
      }, 6700);

      return () => clearTimeout(timer);
    }
  }, [warningMessage]); // Inclua 'title' como dependência aqui

  const handlePopUpClose = () => {
    if(warningMessage !== null && 
      warningMessage !== "Rendering..." && 
      warningMessage !== "Upscaling..." && 
      warningMessage !== "The rendering is taking up more time than expected..."
    ) {
      setWarningMessage(null);
    }
  }

  const intervalRef = useRef(null);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (warningMessage === "Rendering..." || 
      warningMessage === "Upscaling..." || 
    warningMessage === "The rendering is taking up more time than expected...") {
      // Inicia o cronômetro quando a mensagem for "Rendering..." ou "Upscaling..."
      intervalRef.current = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000); // Incrementa o cronômetro a cada segundo
    } else {
      // Reinicia o cronômetro quando a mensagem muda ou é null
      clearInterval(intervalRef.current);
      setTimer(0);
    }

    return () => clearInterval(intervalRef.current); // Limpa o intervalo ao desmontar
  }, [warningMessage]);

  useEffect(() => {
    if (timer === 60 && (warningMessage === "Rendering..." || warningMessage === "Upscaling...")) {
      setWarningMessage("The rendering is taking up more time than expected...");
    } else if (timer >= 120) {
      setWarningMessage("Server under maintenance");
    }
  }, [timer, warningMessage]);

  return (
    <div  
      onClick={handlePopUpClose}
      className={`bg-white shadow-2xl w-[24rem] h-[6rem] fixed z-50
                flex justify-center                                items-center border-2 -top-16 right-1/2 
                translate-x-1/2 font-title font-bold transition-transform opacity-100
                ease-in-out duration-700 hover:cursor-pointer border-solid border-black
                ${warningMessage !== null ?
                `translate-y-[100%]` : `-translate-y-[100%]`}`}>
                  <p
                  className={`
                    ${(warningMessage === "Rendering..." ||
                      warningMessage === "Upscaling..." ||
                      warningMessage === "The rendering is taking up more time than expected...") 
                      && `animate-pulse`}`}>
                  {t(warningMessage)}
                  </p>
      <div
        className={`absolute bottom-0 left-0 bg-black h-1 
                        transition-all
                    ${warningMessage !== null && 
                      warningMessage !== "Rendering..." &&
                      warningMessage !== "Upscaling..." && 
                      warningMessage !== "The rendering is taking up more time than expected..."
                       ?
            "w-full duration-[6700ms] delay-700" :
            "w-0 duration-300"} ease-in-out`}
      />
    </div>
  )
}

NewPopUpComponent.defaultProps = {
  isClosable: true
}

export default NewPopUpComponent;
