export function geraPrompt(userChoices, user, nomeImage) {


  let prompt_default = `{
"2": {
  "inputs": {
    "stop_at_clip_layer": -2,
    "clip": [
      "121",
      1
    ]
  },
  "class_type": "CLIPSetLastLayer",
  "_meta": {
    "title": "CLIP Set Last Layer"
  }
},
"20": {
  "inputs": {
    "text": "A modern bedroom",
    "clip": [
      "2",
      0
    ]
  },
  "class_type": "CLIPTextEncode",
  "_meta": {
    "title": "CLIP Text Encode (Prompt)"
  }
},
"21": {
  "inputs": {
    "text": "blur, distortion, jiggly, low quality",
    "clip": [
      "2",
      0
    ]
  },
  "class_type": "CLIPTextEncode",
  "_meta": {
    "title": "CLIP Text Encode (Prompt)"
  }
},
"52": {
  "inputs": {
    "conditioning_to": [
      "53",
      0
    ],
    "conditioning_from": [
      "20",
      0
    ]
  },
  "class_type": "ConditioningConcat",
  "_meta": {
    "title": "Conditioning (Concat)"
  }
},
"53": {
  "inputs": {
    "text": "",
    "clip": [
      "2",
      0
    ]
  },
  "class_type": "CLIPTextEncode",
  "_meta": {
    "title": "CLIP Text Encode (Prompt)"
  }
},
"77": {
  "inputs": {
    "upscale_by": 1.34,
    "seed": 20,
    "steps": 10,
    "cfg": 1.5,
    "sampler_name": "euler",
    "scheduler": "sgm_uniform",
    "denoise": 0.64,
    "mode_type": "Linear",
    "tile_width": 1648,
    "tile_height": 2472,
    "mask_blur": 8,
    "tile_padding": 32,
    "seam_fix_mode": "None",
    "seam_fix_denoise": 1,
    "seam_fix_width": 64,
    "seam_fix_mask_blur": 8,
    "seam_fix_padding": 16,
    "force_uniform_tiles": true,
    "tiled_decode": false,
    "image": [
      "99",
      0
    ],
    "model": [
      "130",
      0
    ],
    "positive": [
      "79",
      0
    ],
    "negative": [
      "79",
      1
    ],
    "vae": [
      "121",
      2
    ],
    "upscale_model": [
      "122",
      0
    ]
  },
  "class_type": "UltimateSDUpscale",
  "_meta": {
    "title": "Ultimate SD Upscale"
  }
},
"79": {
  "inputs": {
    "strength": 0.55,
    "start_percent": 0,
    "end_percent": 0.4,
    "positive": [
      "83",
      0
    ],
    "negative": [
      "83",
      1
    ],
    "control_net": [
      "125",
      0
    ],
    "image": [
      "99",
      0
    ]
  },
  "class_type": "ControlNetApplyAdvanced",
  "_meta": {
    "title": "Apply ControlNet (Advanced)"
  }
},
"83": {
  "inputs": {
    "strength": 0.65,
    "start_percent": 0,
    "end_percent": 1,
    "positive": [
      "52",
      0
    ],
    "negative": [
      "21",
      0
    ],
    "control_net": [
      "123",
      0
    ],
    "image": [
      "88",
      0
    ]
  },
  "class_type": "ControlNetApplyAdvanced",
  "_meta": {
    "title": "Apply ControlNet (Advanced)"
  }
},
"88": {
  "inputs": {
    "low_threshold": 100,
    "high_threshold": 200,
    "resolution": 1024,
    "image": [
      "99",
      0
    ]
  },
  "class_type": "CannyEdgePreprocessor",
  "_meta": {
    "title": "Canny Edge"
  }
},
"99": {
  "inputs": {
    "image": "1840x1224.jpg",
    "upload": "image"
  },
  "class_type": "LoadImage",
  "_meta": {
    "title": "Load Image"
  }
},
"116": {
  "inputs": {
    "filename_prefix": "Upscaler_canvas alchemy_1224x1840",
    "file_type": "JPEG",
    "images": [
      "147",
      0
    ]
  },
  "class_type": "SaveImageExtended",
  "_meta": {
    "title": "Save Image (Extended)"
  }
},
"121": {
  "inputs": {
    "ckpt_name": "Arcseed_V0.2.safetensors",
    "key_opt": "",
    "mode": "Auto"
  },
  "class_type": "CheckpointLoaderSimpleShared //Inspire",
  "_meta": {
    "title": "Shared Checkpoint Loader (Inspire)"
  }
},
"122": {
  "inputs": {
    "model_name": "swift_srgan_2x.pth",
    "key_opt_u": "",
    "mode": "Auto"
  },
  "class_type": "UpscaleLoaderSimpleShared //Inspire",
  "_meta": {
    "title": "Shared Upscale Loader (Inspire)"
  }
},
"123": {
  "inputs": {
    "control_net_name": "XLcontrol-lora-canny-rank256.safetensors",
    "key_opt_cn": "",
    "mode": "Auto"
  },
  "class_type": "ControlnetLoaderSimpleShared //Inspire",
  "_meta": {
    "title": "Shared CN Loader (Inspire)"
  }
},
"124": {
  "inputs": {
    "control_net_name": "XLcontrol-lora-depth-rank256.safetensors",
    "key_opt_cn": "",
    "mode": "Auto"
  },
  "class_type": "ControlnetLoaderSimpleShared //Inspire",
  "_meta": {
    "title": "Shared CN Loader (Inspire)"
  }
},
"125": {
  "inputs": {
    "control_net_name": "XLttplanetSDXLControlnet_v10Fp16.safetensors",
    "key_opt_cn": "",
    "mode": "Auto"
  },
  "class_type": "ControlnetLoaderSimpleShared //Inspire",
  "_meta": {
    "title": "Shared CN Loader (Inspire)"
  }
},
"126": {
  "inputs": {
    "clip_name": "clipvis_ViT-H_1.5_.safetensors",
    "key_opt_cv": "",
    "mode": "Auto"
  },
  "class_type": "CLIPVisionLoaderSimpleShared //Inspire",
  "_meta": {
    "title": "Shared Clip Loader (Inspire)"
  }
},
"127": {
  "inputs": {
    "key": 1
  },
  "class_type": "RetrieveBackendDataNumberKey //Inspire",
  "_meta": {
    "title": "Retrieve Backend Data [NumberKey] (Inspire)"
  }
},
"130": {
  "inputs": {
    "weight": 0.75,
    "weight_type": "linear",
    "combine_embeds": "concat",
    "start_at": 0,
    "end_at": 0.6,
    "embeds_scaling": "V only",
    "model": [
      "121",
      0
    ],
    "ipadapter": [
      "127",
      0
    ],
    "image": [
      "99",
      0
    ],
    "clip_vision": [
      "126",
      0
    ]
  },
  "class_type": "IPAdapterAdvanced",
  "_meta": {
    "title": "IPAdapter Advanced"
  }
},
"147": {
  "inputs": {
    "sharpen_radius": 1,
    "sigma": 0.25,
    "alpha": 2,
    "image": [
      "77",
      0
    ]
  },
  "class_type": "ImageSharpen",
  "_meta": {
    "title": "ImageSharpen"
  }
},
"157": {
  "inputs": {
    "seed": 127,
    "denoise_steps": 6,
    "ensemble_size": 3,
    "processing_resolution": 768,
    "scheduler": "LCMScheduler",
    "use_taesd_vae": false,
    "marigold_model": [
      "158",
      0
    ]
  },
  "class_type": "MarigoldDepthEstimation_v2",
  "_meta": {
    "title": "MarigoldDepthEstimation_v2"
  }
},
"158": {
  "inputs": {
    "key": 2
  },
  "class_type": "RetrieveBackendDataNumberKey //Inspire",
  "_meta": {
    "title": "Retrieve Backend Data [NumberKey] (Inspire)"
  }
}
}`;

  prompt_default = JSON.parse(prompt_default);

  const { floor, walls, ceiling } = userChoices["materials"] || {};
  
  
  const modelos = userChoices["aiModels"] || '';
  let modeloFrase = "";

  switch (modelos) {
    case "DEFAULT":
      modeloFrase = " ";
      break;
    case "RENDER":
      modeloFrase = "A 3D render of";
      break;
    case "CINEMATIC":
      modeloFrase = "A Cinematic professional shot of";
      break;
    case "PHOTOGRAPHER":
      modeloFrase = "A Photograph of";
      break;
    case "ARCHVIZ":
      modeloFrase = "A Professional architectural rendering of";
      break;
    case "PRISTINE":
      modeloFrase = "A Pristine photograph of";
      break;
  }

  const anStyles = [
    "arabic", "art deco", "art nouveau", "asian fusion", "eclectic", "english",
    "industrial", "japandi", "oriental", "urban modern"
  ];
  const style = userChoices["style"] || '';
  
  // Se o estilo não for selecionado, styleFrase será uma string vazia.
  let styleFrase = '';
  if (style) {
    styleFrase = anStyles.includes(style) ? `an ${style}` : `a ${style}`;
  }


  let whereExterior = userChoices["whereExterior"] || '';
  let time = userChoices["time"] || '';
  let colorPalette = userChoices["colorPalette"] || [];
  let what = userChoices["what"] || '';
  let Style = styleFrase;
  let Modes = `${modeloFrase}` || '';
  let weather = userChoices["weather"] || '';

  if (what === "Square") {
    what = "a public plaza";
  }

  // Remove duplicatas da paleta de cores
  colorPalette = [...new Set(colorPalette)];

  // Construir a frase do chão
  let floorFrase = userChoices["floor"] ? `${userChoices["floor"]} floor` : '';

  // Construir a frase das paredes

  let wallsFrase = '';
if (Array.isArray(walls) && walls.length > 0) {
  if (walls.length === 1) {
    wallsFrase = `${walls[0]} walls`;
  } else if (walls.length === 2) {
    wallsFrase = `${walls[0]} and ${walls[1]} walls`;
  } else {
    wallsFrase = `${walls.slice(0, -1).join(', ')} and ${walls[walls.length - 1]} walls`;
  }
}

  // Construir a frase do teto
  let ceilingFrase = userChoices["ceiling"] ? `${userChoices["ceiling"]} ceiling` : '';

  // Construa o prompt apenas com os parâmetros fornecidos
  const promptParts = [
    Modes,
    Style,
    what ? `${what},` : '',  // Adiciona vírgula após what
    time ? `${time},` : '',    // Adiciona vírgula após time
    whereExterior ? `${whereExterior},` : '',
    weather
  ].filter(Boolean).map(part => part.trim()); // Remove valores falsos e trim

  // Junta os elementos em uma única string
  const promptText = promptParts.join(' ').replace(/\s{2,}/g, ' ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

  // Atualiza o texto no nó 20
  prompt_default["20"]["inputs"]["text"] = promptText.trim();
  prompt_default["53"]["inputs"]["text"] = [...colorPalette, floorFrase, wallsFrase, ceilingFrase].filter(Boolean).join(', ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');


  prompt_default["77"]["inputs"]["seed"] = `${userChoices["seed"]}`;

  prompt_default["99"]["inputs"]["image"] = `${nomeImage}`;

  prompt_default["116"]["inputs"]["filename_prefix"] = `${user}`;

  const prompt = prompt_default;
  //console.log(prompt);
  return prompt;
}
