import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

function RenderSlides(props) {

  const { id,
    title,
    paragraph1,
    paragraph2,
    imageUrl,
    buttonText,
    buttonColor,
    currentSlide,
    ...otherProps } = props;

  const isActiveSlide = currentSlide === id;
  const [currentSlideId, setCurrentSlideId] = useState(id);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentSlideId(id);
  }, [id]);

  const verificarToken = () => {
    const token = Cookies.get('token');
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp >= currentTime;
    } catch (error) {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (!verificarToken()) {
      navigate('/Login');
    } else {
      navigate(`/${currentSlideId}`);
    }
  };

  return (
    <div className={`flex w-screen lg:px-0 overflow-hidden h-screen max-h-screen ${isActiveSlide ? 'active-slide' : ''} relative`} {...otherProps}>
      <div className="flex flex-col w-screen max-w-screen md:flex-row items-center">
        <div className="flex flex-col px-[12vw] lg:px-[5vw] items-center h-screen max-h-screen lg:w-1/2 justify-between py-[10vh]">
          <div className='text-center lg:text-right'>
            <h1 className="font-title text-[200%] lg:text-6xl">{t("SOLUTIONS")}:</h1>
            <h3 className={`font-title`}><b
            className='text-[200%] lg:text-2xl'>{title}</b></h3>
          </div>
          <div className='flex flex-col overflow-hidden'>
            <p className="font-paragraph mb-6 text-justify items-center leading-7 text-[120%]">{paragraph1}</p>
            <p className="font-paragraph text-justify text-[120%] leading-7">{paragraph2}</p>
          </div>
          <button
            style={{ backgroundColor: buttonColor }}
            className={`font-title mb-[3.5vh] px-4 py-2 text-black font-bold rounded-full transition-transform ease-in-out duration-300 hover:scale-105`}
            onClick={handleButtonClick}
          >
            {buttonText}
          </button>
        </div>
        <div className='w-[0.06rem] hidden lg:inline bg-black h-[70vh]' />
        <div className="w-1/2 hidden px-[3%] lg:flex justify-center items-center">
          <img className='hidden xl:inline' src={imageUrl} alt={title} />
        </div>
      </div>
    </div>
  );
}

export default RenderSlides;