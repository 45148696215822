import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PlanInfoComponents from "./PlanInfoComponent";
import NavbarButtons from "../NavbarButtons";
import InfoContainer from "./InfoContainer";
import Footer from "../Footer.jsx";
import { useTranslation } from "react-i18next";

const PlansPage = () => {
  const { param } = useParams(); // Substitua 'param' pelo nome do parâmetro que você deseja acessar

  useEffect(() => {
    document.title = 'Plans Page - Arcseed AI';
    return () => {
      document.title = "Arcseed AI";
    };
  }, []);

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.init({ ns: "plansPage" })
  }, [i18n])

  return (
    <div className="w-full max-w-screen min-h-screen bg-slate-100">
      {/* <MetaPixel/> */}
      <div className="absolute right-0 top-4 mr-4 z-10">
        <NavbarButtons />
      </div>
      <div>
        <PlanInfoComponents param={param} /> {/* Passe o parâmetro para o componente PlanInfoComponents */}
      </div>
      <div id="info-container">
        <InfoContainer />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default PlansPage;
