// import styles of this component
import styles from "./MasonryLayout.module.css";
// import other react pkg to use
import Masonry from "react-masonry-css";
// import other component to use
import MasonryBox from './MasonryBox/MasonryBox';
import perfil_default from "../../../../assets/perfil_default.jpg"; 

// MasonryLayout Component
const MasonryLayout = ({ images, onImageClick }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };
  
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className={styles["my-masonry-grid"]}
      columnClassName={styles["my-masonry-grid_column"]}
    >
      {images
        //.filter(item => item.wallSrc) // Filtra apenas os itens com wallSrc definido
        .map(item => (
          <div key={item.id} className={styles["masonry-item"]}>
            <MasonryBox 
              key={item.id} 
              wallSrc={item.imagem}
              userProf={item.imagem_perfil || perfil_default}
              userName={item.nick_name}
              userId={item.id}
              userQde_likes={item.qde_likes}
              onImageClick={() => onImageClick(item)}
            />
          </div>
        ))
      }
    </Masonry>
  );
};

export default MasonryLayout;
