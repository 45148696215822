import React, { useEffect, useState } from "react";
import building from '../../assets/exteriores.webp';
import livingroom from '../../assets/interiores.webp';
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function Type({ verticalSet, updateUserChoices,
  userChoice
 }) {

  const {t} = useTranslation();

  const isIphone = () => {
    return /iPhone/i.test(navigator.userAgent);
  }

  return (
    <div className={`flex justify-center lg:ml-0 items-center
    ${((window.innerWidth > 1024) && (verticalSet === false)) ? "flex-row-reverse" : "flex-col my-6"}`}>
      <p className={` 
      ${((window.innerWidth > 1024) && verticalSet === false) && ("vertical-text ml-4")}
      font-bold text-3xl font-title text-black items-center flex
      relative ${(window.innerWidth > 1024 && verticalSet === false) && ("flex-col-reverse")}`}>
        {t("TYPE")}
        <div className="w-[1px] h-[1px] relative bg-black z-30">
        <InfoBox
        customClass={`absolute horizontal-writing text-center top-1/2 -translate-y-1/2 ${((window.innerWidth > 1024) && verticalSet === false) ? "left-0" : ("left-2")}`}
        text={t("\"Type\" basically is what kind of scene you want to do, Interior Scenes or Exterior Scenes")}/>
        </div>
        </p>
      <div className={`flex flex-col items-center justify-center`}>
        <button 
        onClick={() => updateUserChoices("type", 'EXTERIORS')} 
        className={`w-64 my-4 outline outline-black
        flex-shrink px-10 py-8 flex justify-center transition-all 
        ease-in-out duration-150 items-center filter
        ${userChoice === "EXTERIORS" ? 'grayscale-0 outline-[6px]' : 'grayscale outline-2'}`}>
          <p className="font-bold font-title text-3xl focus:bg-black z-10">
            {t("EXTERIORS")}
          </p>
          <img
          alt="Not found"
            src={building}
            className={`absolute top-0 left-0 w-full h-full 
              hover:opacity-75 focus:grayscale-0 transition ease-in-out 
              duration-300
              ${isIphone() && "hidden" }`}
          />
        </button>

        <button 
        onClick={() => updateUserChoices("type", 'INTERIORS')} 
        className={`w-64 outline outline-black
        flex-shrink px-10 py-8 flex justify-center transition-all 
        ease-in-out duration-150 items-center filter
        ${userChoice === "INTERIORS" ? 'grayscale-0 outline-[6px]' : 'grayscale outline-2'}`}>
          <p className="font-bold font-title text-3xl focus:bg-black z-10">{t("INTERIORS")}</p>
          <img
          alt="Not found"
            src={livingroom}
            className={`absolute top-0 left-0 w-full h-full hover:opacity-75 focus:grayscale-0 transition ease-in-out duration-300
              ${isIphone() && "hidden" }`}
          />
        </button>

      </div>

    </div>
  );
}

Type.defaultProps = {
  verticalSet: false,
}
