import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next'; // Use isto se você estiver usando React, caso contrário, remova esta linha.
import InstallInYourStudioBR from "./languages/pt/InstallInYourStudio.json";
import commonBR from "./languages/pt/common.json";
import DashboardBR from "./languages/pt/Dashboard.json";
import RenderComponentsBR from "./languages/pt/renderComponents.json";
import indexPageBR from "./languages/pt/indexPage.json";
import galleryBR from "./languages/pt/gallery.json";
import makeYourAiBR from "./languages/pt/createYourModel.json";
import plansPageBR from "./languages/pt/plansPage.json";

const storedLanguage = localStorage.getItem("linguagem");

i18n
  .use(HttpBackend) // Configura o backend para carregar arquivos de tradução
  .use(initReactI18next) // Inicializa o i18next com o React (remova se não estiver usando React)
  .init({
    lng: storedLanguage,
    fallbackLng: 'en-US', // Idioma padrão caso o idioma atual não tenha traduções
    debug: false, // Defina como false em produção
    ns: ["common"], // Lista de namespaces vazia inicialmente
    fallbackNS: "common",
    defaultNS: 'common', // Namespace padrão
    resources: {
      "pt": {
        "common": commonBR, 
        'InstallInYourStudio': InstallInYourStudioBR,
        "Dashboard": DashboardBR,
        "renderComponents": RenderComponentsBR,
        "indexPage": indexPageBR,
        "gallery": galleryBR,
        "makeYourAi": makeYourAiBR,
        "plansPage": plansPageBR
      }
    },
    backend: {
      loadPath: `./languages/{{lng}}/{{ns}}.json` // Caminho para carregar os arquivos de tradução
    },
    interpolation: {
      escapeValue: false
    },  
    react: {
      useSuspense: true // Defina como true se estiver usando React Suspense
    },
    errorHandler: function(error) {
    }
  });

export {i18n};
