import React from 'react';
import { i18n } from '../locales/i18n';
import { useLocation, useNavigate } from 'react-router-dom';

function LanguageSelector({ supportedLanguages, customClass}) {

  // Handle language change using i18n.changeLanguage()
  const handleChange = (event) => {
    const newLang = event.target.value;
    i18n.changeLanguage(newLang); // Pass new language to parent component if needed
    localStorage.removeItem("linguagem");
    localStorage.setItem("linguagem", newLang);
    window.location.reload();
  };

  return (
    <div className='py-2' 
    style={{ display: 'flex', justifyContent: 'center' }}>
      {/* Render the select element with the correct language order */}
      <select
        id="LanguageSelector"
        name="lang"
        lang={localStorage.getItem("linguagem")}
        value={i18n.language}
        onChange={handleChange}
        className={customClass}
      >
          <>
            <option key='pt' value='pt'>PT-BR</option>
            <option key='en-US' value='en-US'>EN</option>
          </>
      </select>
    </div>
  );
}

export default LanguageSelector;