import React, { useState, useEffect, useRef } from "react";
import { IoMdInformationCircle } from "react-icons/io";

const InfoBox = ({ text, customClass, enableHover=false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const infoBoxRef = useRef(null);

    const handleClickOutside = (event) => {
        if (infoBoxRef.current && !infoBoxRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
        className={customClass}>
        <div 
        onMouseOverCapture={() => enableHover ? setIsOpen(true) : null}
        onMouseOutCapture={() => enableHover ? setIsOpen(false) : null}
        className={`flex flex-col-reverse relative items-center`}>
          <IoMdInformationCircle
          onClick={() => setIsOpen(!isOpen)}
            size={30}
            className={`ml-1 ${isOpen ? ("fill-black") : "fill-slate-300"}
            cursor-pointer hover:fill-black
            ease-in-out duration-300 transition-colors flex`}
          />
          <div className="absolute w-[1px] h-[1px] top-0 ml-1 flex flex-col-reverse
          items-center">
          {isOpen && (
              <div
                className="border-solid border-2 border-black 
                font-paragraph w-[11rem] min-h-36 mb-2 z-50
                animate-fade-up animate-duration-300 text-wrap
                bg-white text-[20px] overflow-y-auto leading-5 
                p-1 custom-scrollbar font-bold flex-col flex justify-around"
              >
                {text}
              </div>
          )}
          </div>
        </div>
        </div>
    );
};

InfoBox.defaultProps = {
    customClass: "w-auto h-auto"
}
export default InfoBox;