import React, { useState, useEffect, useCallback } from 'react';
import 'tailwindcss/tailwind.css';
import { useTranslation } from 'react-i18next';
import { FaTrash } from "react-icons/fa";
import InfoBox from '../InfoBox';
import Accordion from "./Accordion";

function ColorPalette({ updateUserChoices }) {
  const [selectedColors, setSelectedColors] = useState([]);
  const [lastThreeColors, setLastThreeColors] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const {t} = useTranslation();

  const handleAccordionOpen = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const accordionStyle = {
    border: "none",
    boxShadow: "none",
    textAlign: "center",
    backgroundColor: "#f3f4f6",
  };

  const titleStyle = {
    boxSizing: 'border-box',
  };

  const whatOptions = [
    { name: 'White', backgroundColor: 'bg-white' },
    { name: 'Black', backgroundColor: 'bg-black' },
    { name: 'Gray', backgroundColor: 'bg-gray-600' },
    { name: 'Brown', backgroundColor: 'bg-yellow-950' },
    { name: 'Red', backgroundColor: 'bg-red-600' },
    { name: 'Orange', backgroundColor: 'bg-orange-500' },
    { name: 'Yellow', backgroundColor: 'bg-yellow-400' },
    { name: 'Green', backgroundColor: 'bg-green-500' },
    { name: 'Blue', backgroundColor: 'bg-blue-600' },
    { name: 'Purple', backgroundColor: 'bg-purple-600' },
  ];

  const colorCycle = (colors, color) => {
    const updatedColors = colors.length < 3 ? [...colors, color] : [colors[1], colors[2], color];
    setLastThreeColors(updatedColors);
    return updatedColors;
  };

  const getColorNameFromBackgroundColor = (backgroundColor) => {
    const colorObj = whatOptions.find(option => option.backgroundColor === backgroundColor);
    return colorObj ? colorObj.name : null;
  };

  const updateChoicesCallback = useCallback(() => {
    const colorNames = selectedColors.map(colorObj => getColorNameFromBackgroundColor(colorObj));
    updateUserChoices("colorPalette", colorNames.filter(name => name !== null));
  }, [selectedColors, updateUserChoices]);

  useEffect(() => {
    updateChoicesCallback();
  }, [selectedColors]); // Dependências que controlam quando updateChoicesCallback deve ser chamado


  const handleColorSelect = (index) => {
    if(index === null) {
      setSelectedColors([null, null, null])
      setLastThreeColors([null, null, null])
    }
    else {
      const selectedColor = whatOptions[index].backgroundColor;
      setSelectedColors(prevColors => colorCycle(prevColors, selectedColor));
    }
  };

  return (
    <div className={`relative bg-[#f3f4f6] `}>
      <div className='flex flex-col w-full max-w-screen items-center justify-center' style={accordionStyle}>
          <div className='flex flex-col relative justify-center w-screen items-center font-bold text-2xl' style={titleStyle}>
            <div className='flex items-center'>
            <h1 
            onClick={handleAccordionOpen}
            className='group font-title mt-1 cursor-pointer'>{t("COLOR PALETTE")}
              <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-[0.06rem] -mt-1 mb-1 bg-black"></span>
            </h1>
            <div className='w-[1px] h-[1px] relative'>
            {isAccordionOpen && (
              <div className='z-50 absolute top-1/2 -translate-y-1/2'>
              <InfoBox
                text={t("You can choose up to three different colors to formulate the color pallet of the scene, be aware that this option can also influence other parameters or create hallucinations.")}
                customClass={"animate-fade-right animate-duration-300 mt-1"}/>
              </div>
            )}
            </div>
          </div>
            </div>
            <span
              className="h-[0.06rem] font-title w-[75%] bottom-0 max-w-screen text-transparent duration-500
            bg-black">
            </span>
            <div
              id="black-triangle"
              className="w-0 h-0
              border-l-[15px] border-l-transparent
              border-t-[15px] border-t-black
              border-r-[15px] border-r-transparent
            "/>
          </div>
        <Accordion 
        isOpen={isAccordionOpen}>
          <div 
          className='flex flex-wrap flex-col w-full
        justify-center items-center relative'>
          <div className="grid grid-cols-5 sm:flex md:flex lg:flex lg:flex-nowrap flex-row-reverse z-10 justify-center items-center max-w-screen relative">
            {whatOptions.map((option, index) => (
              <div
                key={index}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                onClick={() => handleColorSelect(index)}
              >
                <div className={`flex flex-col cursor-pointer scale-50 text-center transform ease-in-out duration-300`}>
                  <div className={`w-20 h-20 -mx-4 border rounded border-black animate-fade-down animate-duration-300 ${option.backgroundColor} ${selectedColors.includes(option.backgroundColor) && 'grayscale-0'}`} alt={option.name} />
                </div>
              </div>
            ))}
          </div>
          <div className={`absolute ${lastThreeColors.length === 3 ? `flex-row-reverse` : `flex-row`} w-full flex h-14`}>
            {lastThreeColors.map((color, index) => (
              <div key={index} className={`w-1/3 h-full ${color} opacity-40 animate-ease-in-out duration-300`}></div>
            ))}
          </div>
          </div>
        <div className='flex justify-center items-center w-full'>
          <FaTrash
              size={22}
              color='black'
              className='cursor-pointer hover:animate-pulse 
              hover:animate-infinite'
              onClick={() => handleColorSelect(null)}
              />
            </div>
        </Accordion>
      </div>
  );
}

export default ColorPalette;
