import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function AIModels(props) {
  const [selectedModel, setSelectedModel] = useState(null);
  const [modelBg, setModelBg] = useState({
    "DEFAULT": true,
    "RENDER": false,
    "CINEMATIC": false,
    "PHOTOGRAPHER": false,
    "ARCHVIZ": false,
    "PRISTINE": false
  });
  
  const { updateUserChoices } = props;

  const {t} = useTranslation();

  useEffect(() => {
    handleModelClick("DEFAULT");
  }, [])

  const handleModelClick = (aiModel) => {
    setSelectedModel(aiModel);
    updateUserChoices("aiModels", aiModel);

    setModelBg((prevModelBg) => {
      const newModelBg = { ...prevModelBg };
      Object.keys(newModelBg).forEach((model) => {
        newModelBg[model] = false;
      });
      newModelBg[aiModel] = true;
      return newModelBg;
    });
  };

  const setFlexCol = () => {
    return props.verticalSet ? "flex-col items-center text-nowrap mb-8" : "flex-col lg:flex-row";
  };

  const handleScreenSize = () => {
    return window.innerWidth < 1040 ? '' : 'lg:mr-8 vertical-text';
  }

  useEffect(() => {
    const handleResize = () => {
      handleScreenSize();
    };

    window.addEventListener("resize", handleResize);

    // Remove o listener quando o componente é desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`flex ${setFlexCol()} max-w-screen items-center text-nowrap justify-center`}>
      <InfoBox
      customClass="hidden lg:inline"
      text={t("By Choosing a different Mode your image will change its style, experiment for amazing variations.")}/>
      <p className={`font-bold ${handleScreenSize()} font-title text-3xl text-center text-black flex items-center`}>{t("MODES")}
        <div className="relative w-[1px] h-[1px]">
        <InfoBox
        customClass="inline lg:hidden absolute top-1/2 -translate-y-1/2 left-2"
        text={t("By Choosing a different Mode your image will change its style, experiment for amazing variations.")}/>
        </div>
      </p>
      <div className="flex flex-col justify-center items-center">
        <button onClick={() => handleModelClick("DEFAULT")} className={`bg-black w-[255px] py-2 transition ease-in-out duration-300 hover:opacity-70 ${modelBg["DEFAULT"] ? "bg-white text-black" : "text-white"} font-title font-bold text-2xl my-2`}>{t("DEFAULT")}</button>
        <button onClick={() => handleModelClick("RENDER")} className={`bg-black w-[255px] py-2 transition ease-in-out duration-300 hover:opacity-70 ${modelBg["RENDER"] ? "bg-white text-black" : "text-white"} font-title font-bold text-2xl my-2`}>{t("RENDER")}</button>
        <button onClick={() => handleModelClick("CINEMATIC")} className={`bg-black w-[255px] py-2 transition ease-in-out duration-300 hover:opacity-70 ${modelBg["CINEMATIC"] ? "bg-white text-black" : "text-white"} font-title font-bold text-2xl my-2`}>{t("CINEMATIC")}</button>
        <button onClick={() => handleModelClick("PHOTOGRAPHER")} className={`bg-black w-[255px] py-2 transition ease-in-out duration-300 hover:opacity-70 ${modelBg["PHOTOGRAPHER"] ? "bg-white text-black" : "text-white"} font-title font-bold text-2xl my-2`}>{t("PHOTOGRAPHER")}</button>
        <button onClick={() => handleModelClick("ARCHVIZ")} className={`bg-black w-[255px] py-2 transition ease-in-out duration-300 hover:opacity-70 ${modelBg["ARCHVIZ"] ? "bg-white text-black" : "text-white"} font-title font-bold text-2xl my-2`}>{t("ARCHVIZ")}</button>
        <button onClick={() => handleModelClick("PRISTINE")} className={`bg-black w-[255px] py-2 transition ease-in-out duration-300 hover:opacity-70 ${modelBg["PRISTINE"] ? "bg-white text-black" : "text-white"} font-title font-bold text-2xl my-2`}>{t("PRISTINE")}</button>
      </div>
    </div>
  );
}

AIModels.defaultProps = {
  verticalSet: false
};
