import React, { useState } from "react";
import { useEffect } from "react";
import "./index.css";
import "./App.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { add_Back } from "../../address";
import { add_Front } from "../../address";
import { jwtDecode } from "jwt-decode";
import NewPopUpComponent from "../../components/Generic/NewPopUpComponent";
import { Context } from "../../App";
import { useContext } from "react";

export default function Login() {
  //
  const [warningMessage, setWarningMessage] = useContext(Context);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    senha: "",
    confirmasenha: "",
  });


  // Função para verificar se o token está presente e válido
  function verificarToken() {
    const token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLinkClick = () => {
    // Recarrega a página ao clicar no link
    window.location.href = "/Register";
  };

  const handleOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest(".menu-container")) {
      setIsMenuOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    /* Função responsável pelo título das páginas */
    document.title = "Forgot password - Arcseed AI";
    return () => {
      document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
    };
  }, []);

  const handleInputChangeSenha = (e) => {
    const { value } = e.target;
    const trimmedValue = value.slice(0, 255);

    setFormData((prevData) => ({
      ...prevData,
      senha: trimmedValue,
    }));

  };

  const handleInputChangeConfirmaSenha = (e) => {
    const { value } = e.target;
    const trimmedValue = value.slice(0, 255);
    setFormData((prevData) => ({
      ...prevData,
      confirmasenha: trimmedValue,
    }));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const [passwordEqual, setPasswordEqual] = useState(null);
  const [message, setMessage] = useState("");

  const handleSubmitClick = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsSubmitting(true);
    if (!validatePassword(formData.senha)) {
      setWarningMessage(
        "A senha deve ter pelo menos 8 caracteres, incluindo letras maiúsculas, números e caracteres especiais."
      );
      setIsSubmitting(false);
      return;
    }

    if (formData.senha !== formData.confirmasenha) {
      setWarningMessage("The passwords aren't the same");
      setIsSubmitting(false);
      return;
    }


    // Send password reset request to backend
    try {
      const token = new URLSearchParams(window.location.search).get("token"); // Extract token from URL
      console.log(token);
      const response = await axios.post(`${add_Back}/alteraSenha`, {
        token: token,
        novaSenha: formData.senha,
      });

      if (response.status === 200) {
        setWarningMessage("Password changed successfully! Redirecting...");//Traduzido
        window.location.href = `${add_Front}/login`;
      } else {
        setWarningMessage("An error occurred. Please try again later.");//Traduzido
        console.error("Error:", response.data.error);
      }
    } catch (error) {
      setWarningMessage("An error occurred. Please try again later.");//Traduzido
      console.error("Error:", error);
    }
  };



  return (
    <section className="min-h-screen bg-black">
      <NewPopUpComponent isClosable={true} />
      <div className="absolute w-full flex items-center justify-center h-full">
        <div className="absolute top-0 left-0 h-full max-h-full w-full bg-black opacity-100" />

        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4  ">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-zinc-100 border-0">
                <div className="flex flex-col justify-center px-4 lg:px-10 py-6 pt-0">
                  <div className="text-blueGray-400 text-center mt-6 mb-3 font-bold">
                    <small className="font-title text-sm">
                      {t("Forgot password")}
                    </small>
                    <p className="font-paragraph font-light text-xl">
                      {t("Type your new passowrd down below")}
                    </p>
                  </div>
                  <form onSubmit={handleSubmitClick}>
                    <div className="relative w-full mb-3">
                      <label
                        className="relative w-full font-paragraph text-left text-xl mb-3"
                        htmlFor="grid-password"
                      >
                        {t("New password")}
                      </label>
                      <input
                        id="newPasswordInput"
                        value={formData.senha}
                        onChange={handleInputChangeSenha}
                        type="password"
                        className="border-0 p-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="New password"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="relative w-full font-paragraph text-left text-xl mb-3"
                        htmlFor="grid-password"
                      >
                        {t("Confirm your new password")}
                      </label>
                      <input
                        value={formData.confirmasenha}
                        onChange={handleInputChangeConfirmaSenha}
                        id="confirmNewPasswordInput"
                        type="password"
                        className="border-0 p-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="New password"
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-slate-300 font-title bg-opacity-70 hover:bg-opacity-100 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? t("Submitting...") :t("Confirm password")}
                      </button>
                    </div>

                    <p
                      className={`font-paragraph mt-4 font-light ${passwordEqual ? `` : `text-red-600`
                        }`}
                    >
                      {message}
                    </p>
                  </form>
                </div>
              </div>
              <div className="flex justify-between -mt-4 px-4 relative">
                <div className="font-paragraph hover:underline text-xl text-white">
                  <Link to="/Login">
                    <small>{t("Sign in")}</small>
                  </Link>
                </div>
                <div className="font-paragraph hover:underline text-xl text-white">
                  <Link onClick={handleLinkClick}>
                    <small>{t("Create new account")}</small>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
