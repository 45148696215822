import React, { useState, useEffect, useRef } from 'react';
import 'tailwindcss/tailwind.css';
import { useTranslation } from 'react-i18next';
import { FaSearch } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import notAvailable from '../../assets/notAvailable.jpg';
import { FaTrash } from "react-icons/fa";
import InfoBox from '../InfoBox';
import Accordion from "./Accordion";

function GenericRenderComponent({ updateUserChoices, renderParamJSONConfig,
  isOpen, onToggle
}) {
  const [selected, setSelected] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);
  const { i18n } = useTranslation();

  const options = renderParamJSONConfig.param.options;

  useEffect(() => {
    setSelected(null)
  }, [renderParamJSONConfig])

  useEffect(() => {
    setIsAccordionOpen(isOpen);
  }, [isOpen])

  //INICIO
  const handleOptionClick = (index) => {
    const selectedOption = filteredOptions[index];
    setSelected(index);
    updateUserChoices(renderParamJSONConfig.param.paramName, selectedOption ? selectedOption.name : null);
  };
  //FIM

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const refThumbViewer = useRef(null);
  const [thumbsViewerWidth, setThumbsViewerWidth] = useState(0)

  useEffect(() => {
    setThumbsViewerWidth(refThumbViewer.current.offsetWidth)
  }, [refThumbViewer.current])

  const returnsInitialThumbs = () => {
    if (window.innerWidth < 1024) return 6;

    if (window.innerWidth / 2 < thumbsViewerWidth) {
      return 10
    }
    else {
      return 5
    }
  }

  const filteredOptions = options.filter(option =>
    i18n.t(option.name.toLowerCase()).includes(searchText.toLowerCase())
  );

  const handleSubmit = (event) => {
    event.preventDefault(); // Impede o comportamento padrão do formulário
    // Adicione aqui a lógica para lidar com o envio do formulário, se necessário
  };

  const [showFullList, setShowFullList] = useState(false);

  const imageRequest = (name) => {
    try {
      return require(
        `../../assets/lightThumbnails/${renderParamJSONConfig.param.ambience}/${renderParamJSONConfig.param.title}/` + name + `.webp`
      )
    }
    catch (error) {
      return notAvailable;
    }
  }

  const absoluteTitleRef = useRef(null);


  useEffect(() => {
    if (isAccordionOpen) {
      const timer = setTimeout(() => {
        setShowInfoBox(true);
      }, 500);
      return () => clearTimeout(timer);
    }
    else {
      setShowInfoBox(false);
    }
  }, [isAccordionOpen]);

  const [showInfoBox, setShowInfoBox] = useState(true);

  const accordionStyle = {
    border: "none",
    boxShadow: "none",
    textAlign: "center",
    backgroundColor: "transparent",
  };

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (absoluteTitleRef.current.clientHeight !== null) {
      setHeight(absoluteTitleRef.current.clientHeight);
    }
  }, [absoluteTitleRef])

  return (
    <div
      ref={refThumbViewer}
      className='relative'>
      <div
        style={accordionStyle}
        className={`
      flex flex-col w-full max-w-screen items-center justify-center`}>
        <div
          style={accordionStyle}>
          <div className='flex flex-col relative justify-center items-center font-bold text-2xl'>
            <div
              className='flex items-center relative'>
              <h1
                onClick={onToggle}
                className={`
              ${isAccordionOpen ? `-translate-y-[0.3rem] text-6xl mt-2` : ``}
              -mb-1 font-title cursor-pointer relative transition-all ease-in-out duration-500 flex items-center
              `}>
                {i18n.t((renderParamJSONConfig.param.title).toUpperCase())}
                <div
                  className={`h-[0.06rem] absolute font-title w-full
              bg-black flex flex-col items-center transition-all ease-in-out duration-300
                ${isAccordionOpen ? ("bottom-[120%]") : "bottom-0"}
                transition-opacity ease-in-out duration-500
                ${(isAccordionOpen) ? "opacity-0" : "opacity-100"}`}>

                  <div
                    style={{
                      position: 'absolute',
                      width: 0,
                      height: 0,
                      borderLeft: '15px solid transparent',
                      borderTop: '15px solid black',
                      borderRight: '15px solid transparent'
                    }}
                    className={`
              ${isAccordionOpen ? `rotate-180 bottom-[0%]` : `top-[100%]`} translate-x-[50%] right-1/2`} />
                </div>
              </h1>
              <div className='w-[1px] h-[1px] relative'>
                {showInfoBox && (
                  <div
                    onClick={null}
                    className='z-40 p-[5%]'>
                    <InfoBox
                      text={i18n.t(renderParamJSONConfig.param.infoBoxDescription)}
                      customClass={"animate-fade absolute animate-duration-300 left-3 top-1/2 -translate-y-1/2"
                      } />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Accordion
          isOpen={isAccordionOpen}
          className='flex flex-col justify-center items-center relative'>
          <div className="flex flex-wrap justify-center items-center">
            {filteredOptions.length === 0 && (
              <div
                className='h-[152px] flex flex-col 
              font-bold font-title text-2xl justify-center animate-fade animate-duration-500'>
                {i18n.t("Nothing found...")}
              </div>
            )}
            {filteredOptions.slice(0, returnsInitialThumbs()).map((option, index) => (
              <div
                key={index}
                className={`flex flex-col mt-2 flex-wrap lg:w-auto mr-[3px] min-w-[930px]:w-1/2 cursor-pointer items-center text-center`}
                onClick={() => handleOptionClick(index)}
              >
                <img
                  className={`w-[7rem] h-[7rem]
                    ease-in-out duration-500 transition-all filter
                  ${index === selected ?
                      'grayscale-0' :
                      'grayscale'}`}
                  loading='lazy'
                  src={imageRequest(option.name)}
                  alt={option.name} />
                <div
                  style={{
                    marginBottom: height + 20
                  }}
                  className="pt-1 w-[7rem] h-[1px] relative text-justify"
                >
                  <h5
                    ref={absoluteTitleRef}
                    className="absolute left-1/2 -translate-x-1/2 leading-5 w-full font-title font-bold uppercase text-neutral-800 text-center whitespace-normal"
                  >
                    {i18n.t(option.name.toLowerCase())}
                  </h5>
                </div>

              </div>
            ))}
          </div>
          {options.length >= 6 ? (
            <form
              onSubmit={handleSubmit}
              className='w-full justify-center flex items-center'>
              <div className='flex flex-col relative'>
                <div
                  className='relative flex items-center'>
                  <input
                    className='input-black-border font-title bg-transparent
              h-8 w-[10rem] mt-2 text-center font-bold mb-1 border-solid border-b-2 border-black'
                    placeholder={i18n.t('SEARCH')}
                    type='text'
                    value={searchText}
                    onChange={handleSearchChange}
                  >
                  </input>
                  {showFullList
                    ?
                    (
                      searchText !== ""
                        ?
                        <IoCloseSharp
                          size={24}
                          backgroundColor="white"
                          onClick={() => setSearchText("") && setShowFullList(false)
                          }
                          className='cursor-pointer right-0 mb-1 mr-1 -ml-8
                outline-2  animate-fade animate-duration-500'/>
                        :
                        <IoCloseSharp
                          size={24}
                          onClick={() => setShowFullList(false)}
                          className='cursor-pointer right-0 mb-1 mr-1 -ml-8
                outline-2  animate-fade animate-duration-500'/>
                    )
                    :
                    (searchText !== ""
                      ?
                      <IoCloseSharp
                        size={24}
                        onClick={() => setSearchText("") && setShowFullList(false)
                        }
                        className='cursor-pointer right-0 mb-1 -ml-8
                outline-2  animate-fade animate-duration-500'/>
                      :
                      <FaSearch
                        size={24}
                        onClick={() => setShowFullList(true)}
                        className='cursor-pointer right-0 -ml-8
                outline-2  animate-fade animate-duration-500'/>
                    )
                  }
                  <FaTrash
                    size={22}
                    color='black'
                    className='ml-4 cursor-pointer hover:animate-pulse 
              hover:animate-infinite'
                    onClick={() => handleOptionClick(null)}
                  />
                </div>
                {(searchText === "") ?
                  showFullList ?
                    <div className='bg-white absolute z-30 left-1/2
                -translate-x-1/2 top-[98%] font-title font-bold 
                max-w-[100vw] border border-black flex animate-fade animate-duration-500
                items-center flex-col overflow-y-scroll max-h-[264px] no-scrollbar'>
                      {filteredOptions.slice(0, 100).map((option, index) => (
                        <div
                          key={index}
                          onClick={() => handleOptionClick(index)}
                          className={`p-3 flex justify-center items-center border border-solid
                    border-black cursor-pointer bg-white
                  hover:bg-black hover:text-white transition-colors ease-in-out duration-300
                  w-[14rem] max-h-[44px] min-h-[44px]
                  ${option.name.length > 12 ? `text-[80%]` : ``}`}>
                          {i18n.t(option.name.toLowerCase())}
                        </div>
                      ))}
                    </div>
                    :
                    <p />
                  :
                  <div className='bg-white absolute z-30 left-1/2
                -translate-x-1/2 top-[98%] font-title font-bold 
                max-w-[100vw] border border-black flex  
                items-center flex-col overflow-y-scroll max-h-[264px] no-scrollbar'>
                    {filteredOptions.slice(10, 100).map((option, index) => (
                      <div
                        key={index + 5}
                        onClick={() => handleOptionClick(index + 5)}
                        className={`p-3 flex justify-center items-center border border-solid border-black cursor-pointer bg-white
                  hover:bg-black hover:text-white transition-colors ease-in-out duration-300
                  w-[14rem] max-h-[44px] min-h-[44px]
                  ${option.name.length > 12 ? `text-[80%]` : ``}`}>
                        {i18n.t(option.name.toLowerCase())}
                      </div>
                    ))}
                  </div>
                }
              </div>
            </form>
          )
            :
            (
              <div className='flex justify-center'>
                <FaTrash
                  size={22}
                  color='black'
                  className='mt-2 cursor-pointer
              hover:animate-infinite hover:animate-pulse'
                  onClick={() => handleOptionClick(null)}
                />
              </div>
            )}
        </Accordion>
      </div>
    </div>
  );
}

GenericRenderComponent.defaultProps = {
  isOpen: false
}

export default GenericRenderComponent;
