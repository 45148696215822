import { React, useRef, useState } from "react";
import { useEffect } from "react";
import ImageUploader from "../components/RenderComponents/ImageUploader";
import Crop from "../components/RenderComponents/ImageCropper";
import Type from "../components/RenderComponents/Type";
import Seed from "../components/RenderComponents/Seed";
// import Floors from "../components/RenderComponents/Floors";
import Format from "../components/RenderComponents/Format";
import { FaTrash } from "react-icons/fa";
import Visibility from "../components/RenderComponents/Visibility";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange";
import RenderButton from "../components/RenderComponents/RenderButton";
import Navbar, { navbarHeight } from "../components/Navbar";
import whereExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whereExteriors.json";
import whatExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatExteriors.json";
import whatInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
import weatherExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/weather.json";
import timeExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeExteriors.json";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import styleExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleExteriors.json";
import Footer from "../components/Footer";
import Precision from "../components/RenderComponents/Precision";
import Originality from "../components/RenderComponents/Originality";

import { geraPrompt as geraPromptLandscapeProImg } from "../apiComfy/Canvas/Canvas_Img_Landscape_Pro";
import { geraPrompt as geraPromptSquareProImg } from "../apiComfy/Canvas/Canvas_Img_Square_Pro";
import { geraPrompt as geraPromptPortraitProImg } from "../apiComfy/Canvas/Canvas_Img_Portrait_Pro";

import { geraPrompt as geraPromptLandscapeStandartImg } from "../apiComfy/Canvas/Canvas_Img_Landscape_Standart";
import { geraPrompt as geraPromptSquareStandartImg } from "../apiComfy/Canvas/Canvas_Img_Square_Standart";
import { geraPrompt as geraPromptPortraitStandartImg } from "../apiComfy/Canvas/Canvas_Img_Portrait_Standart";

//Text
import { geraPrompt as geraPromptLandscapeProText } from "../apiComfy/Canvas/Canvas_Text_Landscape_Pro";
import { geraPrompt as geraPromptSquareProText } from "../apiComfy/Canvas/Canvas_Text_Square_Pro";
import { geraPrompt as geraPromptPortraitProText } from "../apiComfy/Canvas/Canvas_Text_Portrait_Pro";

import { geraPrompt as geraPromptLandscapeStandartText } from "../apiComfy/Canvas/Canvas_Text_Landscape_Standart";
import { geraPrompt as geraPromptSquareStandartText } from "../apiComfy/Canvas/Canvas_Text_Square_Standart";
import { geraPrompt as geraPromptPortraitStandartText } from "../apiComfy/Canvas/Canvas_Text_Portrait_Standart";

import Cookies from "js-cookie";
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import CustomRange from "../components/RenderComponents/CustomRange";
import Accordion from "../components/RenderComponents/Accordion";
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent";

export default function CanvasAlchemyPage() {
  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
    window.innerWidth < 1024 ? 
      (
        <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
          <div className="flex">{items}</div>
          <div className="flex">{items}</div>
        </div>
      )
      :
      ( 
        <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
      )
    )
  };

  // Função para verificar se o token está presente e válido
  const token = Cookies.get("token");
  function verificarToken() {
    
    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const [userChoices, setUserChoices] = useState({
    imageUploader: null,
    imageUploader2: null,
    imageCropper: null,
    seed: null,
    type: null,
    what: null,
    whereExterior: null,
    weather: null,
    time: null,
    style: "Modern",
    visibility: "PUBLIC",
    precision: "STANDARD",
  });

  const { t } = useTranslation();

  const [openAccordion, setOpenAccordion] = useState(null);
  const [openAccordion1, setOpenAccordion1] = useState(null);
  const [hoveredMode, setHoveredMode] = useState(-1);

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };

  const updateUserChoices = (category, choice) => {
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };

  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId; 

  useEffect(() => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("nomeImage2");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
  }, [])

  const convertToJson = () => {
    console.log("oioi");
    
    if(userChoices.imageUploader === null || 
      (userChoices.imageUploader !== null && 
        userChoices.imageUploader2 === null)) {
          return;
        }
    //const user = Cookies.get("clientId");
    let nomeImage1;
    let nomeImage2 = null;

    // if(selectedMode === "txt2img") {
    //   nomeImage1 = `${user}_${Date.now()}.png`
    //   //sessionStorage.setItem("nomeImage2", nomeImage1);
    // }
    // else {
    //   nomeImage1 = `${user}_${Date.now()}_1.png`
    //   nomeImage2 = `${user}_${Date.now()}_2.png`
    // }
      nomeImage1 = `${user}_${Date.now()}_1.png`
      
    if(selectedMode !== "txt2img") {
      console.log('oieeeeee madu');
      nomeImage2 = `${user}_${Date.now()}_2.png`
    }else{
      sessionStorage.setItem("croppedImage",sessionStorage.getItem("imageUploader"));
    }
   
    

    sessionStorage.setItem("nomeImage", nomeImage1);
    if (nomeImage2) {
      sessionStorage.setItem("nomeImage2", nomeImage2);
    }

    console.log("Nome image 1: ", nomeImage1);
    console.log("Nome image 2: ", nomeImage2);
    let geraPrompt;

    if (nomeImage2) {
      if (userChoices.precision === "PROFESSIONAL") {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapeProImg(
            userChoices,
            user,
            nomeImage1,
            nomeImage2
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitProImg(
            userChoices,
            user,
            nomeImage1,
            nomeImage2
          );
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquareProImg(
            userChoices,
            user,
            nomeImage1,
            nomeImage2
          );
        }
      } else {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapeStandartImg(
            userChoices,
            user,
            nomeImage1,
            nomeImage2
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitStandartImg(
            userChoices,
            user,
            nomeImage1,
            nomeImage2
          );
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquareStandartImg(
            userChoices,
            user,
            nomeImage1,
            nomeImage2
          );
        }
      }
    } else {
      // Text
      if (userChoices.precision === "PROFESSIONAL") {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapeProText(
            userChoices,
            user,
            nomeImage1
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitProText(userChoices, user, nomeImage1);
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquareProText(userChoices, user, nomeImage1);
        }
      } else {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapeStandartText(
            userChoices,
            user,
            nomeImage1
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitStandartText(
            userChoices,
            user,
            nomeImage1
          );
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquareStandartText(
            userChoices,
            user,
            nomeImage1
          );
        }
      }
    }

    const saida = geraPrompt;
    console.log(userChoices);
    sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));
  };

  const handleRenderingModeChange = (renderingMode) => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("nomeImage2");
    setSelectedMode(renderingMode);
    if (renderingMode === "img2img") {
      setUserChoices({
        imageUploader: null,
        imageUploader2: null,
        imageCropper: null,
        seed: userChoices.seed,
        type: null,
        what: null,
        whereExterior: null,
        weather: null,
        time: null,
        style: "Modern",
        visibility: "PUBLIC",
        precision: "STANDARD",
      });
      if(userChoices.precision === "STANDARD") {
        setUserChoices((prevChoices) => ({
          ...prevChoices,
          AIImmaginationRange: "2",
          OriginalityRange: "0.5",
          StyleTransferRange: "4",
          StyleExposureRange: "4",
          ShapeStrengthRange: "2",  
          ShapeExposureRange: "2",
        }))
      }
      else {
        setUserChoices((prevChoices) => ({
          ...prevChoices,
          AIImmaginationRange: "2",
          OriginalityRange: "0.5",
          StyleTransferRange: "4",
          StyleExposureRange: "4",
          ShapeStrengthRange: "2",  
          ShapeExposureRange: "2",
        }))
      }
    } else {
      setUserChoices({
        imageUploader: null,
        imageCropper: null,
        seed: userChoices.seed,
        type: null,
        what: null,
        whereExterior: null,
        weather: null,
        time: null,
        style: "Modern",
        visibility: "PUBLIC",
        precision: 'STANDARD',
      });
      if(userChoices.precision === "STANDARD") {
        setUserChoices((prevChoices) => ({
          ...prevChoices,
          AIImmaginationRange: "2",
          StyleTransferRange: "4",
          StyleExposureRange: "3",
          OriginalityRange: null,
          ShapeStrengthRange: null,
          ShapeExposureRange: null,
        }))
      }
      else {
        setUserChoices((prevChoices) => ({
          ...prevChoices,
          AIImmaginationRange: "2",
          StyleTransferRange: "4",
          StyleExposureRange: "3",
          OriginalityRange: null,
          ShapeStrengthRange: null,
          ShapeExposureRange: null,
        }))
      }
    }
  };

  const cropRef = useRef(null);

  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });


  useEffect(() => {
    console.log(userChoices)
  }, [userChoices])

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };

  useEffect(() => {
    /* Função responsável pelo título das páginas */

    document.title = "Canvas Alchemy - Arcseed AI";
    return () => {
      document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
    };
  }, []);

  const [height ,setHeight] = useState(0);
  useEffect(() => {
    if(cropRef.current !== null) {
      setHeight(cropRef.current.clientHeight)
    }
  }, [cropRef.current])

  useEffect(() => {
    setopenFinetuning(false);
    setOpenCustomization(false);
  }, [userChoices.type]);

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });
  }, [i18n]);

  const [isInterior, setIsInterior] = useState(null);
  const [selectedMode, setSelectedMode] = useState("");
  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  const removeImageUp2 = () => {
    updateUserChoices("imageUploader2", null);
    sessionStorage.removeItem("imageUploader2");
  }
  
  useEffect(() => {
    if(selectedMode === "txt2img") {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        imageUploader: undefined
      }))
    }
  }, [selectedMode]);

  const handleTypeChoose = () => {
    if (userChoices.type !== isInterior) {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        what: null,
        whereExterior: null,
        time: null,
        weather: null,
        style: "Modern",
        visibility: "PUBLIC",
        precision: "STANDARD",
      }));
      setIsInterior(userChoices.type);
    }

    if (userChoices.type === "INTERIORS") {
      return (
        <div>
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatInteriorsJSON}
          isOpen={true}
          />
          {/* <WhatInteriors
            isOpen={openAccordion1 === "style"}
            onToggle={() => handleAccordionToggle1("style")}
            updateUserChoices={updateUserChoices}
          /> */}
          <div className="w-full flex justify-center items-center">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleInteriorsJSON}
                isOpen={(openAccordion === "styleInteriors")}
                onToggle={() => handleAccordionToggle("styleInteriors")}
                />
                </div>
              <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeInteriorsJSON}
                isOpen={(openAccordion === "timeInteriors")}
                onToggle={() => handleAccordionToggle("timeInteriors")}
                />
            </div>
            )
            :
            (
            <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleInteriorsJSON}
                isOpen={(openAccordion === "styleInteriors")}
                onToggle={() => handleAccordionToggle("styleInteriors")}
                />
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
              <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeInteriorsJSON}
                isOpen={(openAccordion1 === "timeInteriors")}
                onToggle={() => handleAccordionToggle1("timeInteriors")}
                />
                {/* <Time
                  isOpen={openAccordion === "time"}
                  onToggle={() => handleAccordionToggle("time")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>

            )}

          </Accordion>
          <div className="w-full justify-center flex flex-col">
            <div className="w-full flex justify-center items-center">
              <h1
                onClick={() => setopenFinetuning(!openFinetuning)}
                className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
              >
                {t("Fine-tuning")}
              </h1>
            </div>
            <Accordion isOpen={openFinetuning}>
              {selectedMode === "img2img" ? (    
                <div className="w-screen lg:px-[10vw] flex lg:flex-row flex-col justify-center">
                  <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
                    <div className="my-4">
                    <Originality
                    initialValue={"0.5"}
                      updateUserChoices={updateUserChoices} />
                    </div>
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"STYLE TRANSFER"}
                      startsAt={userChoices.StyleTransferRange}
                      maxRange={"7"}
                      infoBoxDescription={
                        "How much the Style will be transferred to the render, strong values can change completely how the render looks."
                      }
                      description={
                        "The strenght that the style will influence the final render."
                      }
                      variableName={"StyleTransferRange"}
                      options={["Weak".toUpperCase(), "Strong".toUpperCase()]}
                      className="mb-4" />
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"STYLE EXPOSURE"}
                      startsAt={userChoices.StyleExposureRange}
                      maxRange={"5"}
                      infoBoxDescription={
                        "This finetune tells how long the style transfer will influence the final render."
                      }
                      description={
                        "The higher this value is longer the style image will influence the render"
                      }
                      variableName={"StyleExposureRange"}
                      options={["Weak".toUpperCase(), "Strong".toUpperCase()]} />
                  </div>
                  <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
                    <div className="my-4">
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"SHAPE STRENGTH"}
                      startsAt={userChoices.ShapeStrengthRange}
                      maxRange={"3"}
                      infoBoxDescription={
                        "How much the AI will try to keep the shape over trying to be creative."
                      }
                      description={
                        "More creative the AI is less precise it tends to be"
                      }
                      variableName={"ShapeStrengthRange"}
                      options={["Creative", "Balanced", "Precise"]}/>
                      </div>
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"SHAPE EXPOSURE"}
                      startsAt={userChoices.ShapeExposureRange}
                      maxRange={"3"}
                      infoBoxDescription={
                        "Stronger the exposure more the AI will let the shape participate on the final render."
                      }
                      description={
                        "How long the AI should hold to the original image's form"
                      }
                      variableName={"ShapeExposureRange"}
                      className={"mb-4"}
                      options={["Soft".toUpperCase(), "Moderate".toUpperCase(), "Strong".toUpperCase()]}/>
                    <AIImmaginationRange 
                    initialValue={userChoices.AIImmaginationRange}
                    updateUserChoices={updateUserChoices} />
                  </div>
                </div>
              )
                :
                (
                  <div>
                  <div className="flex flex-col">
                  <div className="w-screen lg:px-[10vw] flex lg:flex-row flex-col justify-center">
                    <div className="lg:w-1/2 lg:justify-center lg:items-center lg:flex lg:flex-col">
                      <CustomRange
                        updateUserChoices={updateUserChoices}
                        title={"STYLE TRANSFER"}
                        startsAt={userChoices.StyleTransferRange}
                        maxRange={"7"}
                        infoBoxDescription={"How much the Style will be transferred to the render, strong values can change completely how the render looks."}
                        description={"The strenght that the style will influence the final render."}
                        variableName={"StyleTransferRange"}
                        options={["Weak".toUpperCase(), "Strong".toUpperCase()]}
                        className="my-4" />
                        </div>
                        <div className="lg:w-1/2 lg:justify-center lg:items-center lg:flex lg:flex-col">
                      <CustomRange
                        updateUserChoices={updateUserChoices}
                        title={"STYLE EXPOSURE"}
                        startsAt={userChoices.StyleExposureRange}
                        maxRange={"5"}
                        infoBoxDescription={"This finetune tells how long the style transfer will influence the final render."}
                        description={"The higher this value is longer the style image will influence the render"}
                        variableName={"StyleExposureRange"}
                        options={["Weak".toUpperCase(), "Strong".toUpperCase()]} />
                        </div>
                  </div>
                    <div className="justify-center flex">
                      <AIImmaginationRange 
                      initialValue={userChoices.AIImmaginationRange}
                      updateUserChoices={updateUserChoices} />
                    </div>
                  </div>
                  </div>
              )}
            </Accordion>
          </div>
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={
              selectedMode === "img2img"
                ? {
                    active: "bg-[#fffe80] text-black",
                    inactive: "bg-[#ffffb3] text-black",
                    hover: "hover:bg-[#fffe80] hover:text-black",
                    valor: 2,
                  }
                : {
                    active: "bg-[#a1ff80] text-black",
                    inactive: "bg-[#d4ffb3] text-black",
                    hover: "hover:bg-[#a1ff80] hover:text-black",
                    valor: 1,
                  }
            }
            professionalColors={
              selectedMode === "img2img"
                ? {
                    active: "bg-[#fcac7f] text-black",
                    inactive: "bg-[#fdd7bc] text-black",
                    hover: "hover:bg-[#fcac7f] hover:text-black",
                    valor: 3,
                  }
                : {
                    active: "bg-[#fffe80] text-black",
                    inactive: "bg-[#ffffb3] text-black",
                    hover: "hover:bg-[#fffe80] hover:text-black",
                    valor: 2,
                  }
            }
          />
          <RenderButton
            onRenderButtonClick={convertToJson}
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
          />
        </div>
      );
    } else if (userChoices.type === "EXTERIORS") {
      return (
        <div>
          {/* <Floors updateUserChoices={updateUserChoices} /> */}
          {/* <WhatExteriors updateUserChoices={updateUserChoices} /> */}
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatExteriorsJSON}
          isOpen={true}
          />
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleExteriorsJSON}
                isOpen={(openAccordion === "styleExteriors")}
                onToggle={() => handleAccordionToggle("styleExteriors")}
                />
                </div>
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={(openAccordion === "whereExteriors")}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                </div>
                {/* <Where
                  isOpen={openAccordion1 === "where"}
                  onToggle={() => handleAccordionToggle1("where")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={(openAccordion === "timeExteriors")}
                onToggle={() => handleAccordionToggle("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExteriors"}
                  onToggle={() => handleAccordionToggle("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherExteriorsJSON}
                isOpen={(openAccordion === "weatherExteriors")}
                onToggle={() => handleAccordionToggle("weatherExteriors")}
                />
                {/* <Weather
                  isOpen={openAccordion === "weatherExteriors"}
                  onToggle={() => handleAccordionToggle("weatherExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
            </div>
            )
            :
            (
            <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleExteriorsJSON}
                isOpen={(openAccordion === "styleExteriors")}
                onToggle={() => handleAccordionToggle("styleExteriors")}
                />
                </div>
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={(openAccordion === "whereExteriors")}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                {/* <Where
                  isOpen={openAccordion1 === "where"}
                  onToggle={() => handleAccordionToggle1("where")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={(openAccordion1 === "timeExteriors")}
                onToggle={() => handleAccordionToggle1("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExteriors"}
                  onToggle={() => handleAccordionToggle("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherExteriorsJSON}
                isOpen={(openAccordion1 === "weatherExteriors")}
                onToggle={() => handleAccordionToggle1("weatherExteriors")}
                />
                {/* <Weather
                  isOpen={openAccordion === "weatherExteriors"}
                  onToggle={() => handleAccordionToggle("weatherExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>


            )}

          </Accordion>
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion
          isOpen={openFinetuning}>
          <div className="w-full justify-center flex flex-col">
          {selectedMode === "img2img" ? (
                <div className="w-screen px-[10vw] flex flex-col lg:flex-row justify-center">
                  <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
                    <div className="my-4">
                    <Originality
                    initialValue={"0.5"}
                      updateUserChoices={updateUserChoices} />
                      </div>
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"STYLE TRANSFER"}
                      startsAt={userChoices.StyleTransferRange}
                      maxRange={"7"}
                      infoBoxDescription={
                        "How much the Style will be transferred to the render, strong values can change completely how the render looks."
                      }
                      description={
                        "The strenght that the style will influence the final render."
                      }
                      variableName={"StyleTransferRange"}
                      options={["Weak".toUpperCase(), "Strong".toUpperCase()]}
                      className="mb-4" />
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"STYLE EXPOSURE"}
                      startsAt={userChoices.StyleExposureRange}
                      maxRange={"5"}
                      infoBoxDescription={
                        "This finetune tells how long the style transfer will influence the final render."
                      }
                      description={
                        "The higher this value is longer the style image will influence the render"
                      }
                      variableName={"StyleExposureRange"}
                      options={["Weak".toUpperCase(), "Strong".toUpperCase()]} />
                  </div>
                  <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"SHAPE STRENGTH"}
                      startsAt={userChoices.ShapeStrengthRange}
                      maxRange={"3"}
                      infoBoxDescription={
                        "How much the AI will try to keep the shape over trying to be creative."
                      }
                      description={
                        "More creative the AI is less precise it tends to be"
                      }
                      variableName={"ShapeStrengthRange"}
                      options={["Creative", "Balanced", "Precise"]}
                      className={"my-4"} />
                    <CustomRange
                      updateUserChoices={updateUserChoices}
                      title={"SHAPE EXPOSURE"}
                      startsAt={userChoices.ShapeExposureRange}
                      maxRange={"3"}
                      infoBoxDescription={
                        "Stronger the exposure more the AI will let the shape participate on the final render."
                      }
                      description={
                        "How long the AI should hold to the original image's form"
                      }
                      variableName={"ShapeExposureRange"}
                      options={["Soft".toUpperCase(), "Moderate".toUpperCase(), "Strong".toUpperCase()]}
                      className={"mb-4"} />
                    <AIImmaginationRange 
                    initialValue={userChoices.AIImmaginationRange}
                    updateUserChoices={updateUserChoices} />
                  </div>
                </div>
              )
                :
                (
                  <div>
                  <div className="flex flex-col">
                  <div className="w-screen lg:px-[10vw] flex lg:flex-row flex-col justify-self-center">
                    <div className="lg:w-1/2 lg:justify-center lg:items-center lg:flex lg:flex-col">
                      <CustomRange
                        updateUserChoices={updateUserChoices}
                        title={"STYLE TRANSFER"}
                        startsAt={userChoices.StyleTransferRange}
                        maxRange={"7"}
                        infoBoxDescription={"How much the Style will be transferred to the render, strong values can change completely how the render looks."}
                        description={"The strenght that the style will influence the final render."}
                        variableName={"StyleTransferRange"}
                        options={["Weak".toUpperCase(), "Strong".toUpperCase()]}
                        className="my-4" />
                        </div>
                        <div className="lg:w-1/2 lg:justify-center lg:items-center lg:flex lg:flex-col">
                      <CustomRange
                        updateUserChoices={updateUserChoices}
                        title={"STYLE EXPOSURE"}
                        startsAt={userChoices.StyleExposureRange}
                        maxRange={"5"}
                        infoBoxDescription={"This finetune tells how long the style transfer will influence the final render."}
                        description={"The higher this value is longer the style image will influence the render"}
                        variableName={"StyleExposureRange"}
                        options={["Weak".toUpperCase(), "Strong".toUpperCase()]} />
                        </div>
                  </div>
                    <div className="justify-center flex">
                      <AIImmaginationRange 
                      initialValue={userChoices.AIImmaginationRange}
                      updateUserChoices={updateUserChoices} />
                    </div>
                  </div>
                  </div>
              )}
            </div>
          </Accordion>
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={
              selectedMode === "img2img"
                ? {
                    active: "bg-[#fffe80] text-black",
                    inactive: "bg-[#ffffb3] text-black",
                    hover: "hover:bg-[#fffe80] hover:text-black",
                    valor: 2,
                  }
                : {
                    active: "bg-[#a1ff80] text-black",
                    inactive: "bg-[#d4ffb3] text-black",
                    hover: "hover:bg-[#a1ff80] hover:text-black",
                    valor: 1,
                  }
            }
            professionalColors={
              selectedMode === "img2img"
                ? {
                    active: "bg-[#fcac7f] text-black",
                    inactive: "bg-[#fdd7bc] text-black",
                    hover: "hover:bg-[#fcac7f] hover:text-black",
                    valor: 3,
                  }
                : {
                    active: "bg-[#fffe80] text-black",
                    inactive: "bg-[#ffffb3] text-black",
                    hover: "hover:bg-[#fffe80] hover:text-black",
                    valor: 2,
                  }
            }
          />
          <div className="z-50">
          <RenderButton
            onRenderButtonClick={convertToJson}
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
          />
          </div>
        </div>
      );
    } else if (userChoices.type === null) {
      return (
        <div className="flex w-full max-w-screen my-2 justify-center">
          <p className="font-paragraph px-6 lg:px-0 text-2xl">
            {t("Tip: select the ambient type to access the other inputs!")}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="overflow-hidden">
      <NewPopUpComponent/>
      <div className="z-30">
      <Navbar
        title="CANVAS ALCHEMY"
        userChoices={userChoices}
        description="Stylize your project with this unique tool, first add the stylizing image and if you want a place to be stylized, you can also not upload a place and create ideas from scratch! if you are an advanced user click here for the advanced toolset or turn the advanced toolset on your settings page here ,there all the generative pages will be in one place for fast editing and access."
        moedas={moedas}
        showPoints={true}
      />
      </div>
      {selectedMode === "" ? (
        <div className="h-screen animate-fade animate-once relative">
          <div
            className="h-full w-full flex flex-col
      justify-center items-center font-title font-bold tracking-widest leading-7 text-[3vw] lg:text-[1vw]"
          >
            <div className="flex items-center mb-[1%]">
              {t(
                "THE SIMPLE ALCHEMY USES ONLY THE STYLIZING IMAGE AND THE AI CREATES THE IMAGE TO BE STYLIZED FOR YOU."
              )}
              <br />
              {t(
                "THE ADVANCED ALCHEMY USES THE STYLIZING IMAGE AND YOU CAN UPLOAD ANY IMAGE TO BE STYLIZED"
              )}
              {/* <InfoBox
                text="By selecting a mode of render in Canvas Alchemy, the input fields will be diferent from each other." /> */}
            </div>
            <div className="flex flex-row-reverse items-center justify-center">
              <button
                onMouseEnter={() => setHoveredMode(0)}
                onClick={() => handleRenderingModeChange("img2img")}
                className="font-title font-bold border-2 border-solid border-black
          text-[6vw] lg:text-[2vw] hover:bg-black bg-white hover:text-white 
          text-black transition-colors ease-in-out duration-300 p-[4%] text-nowrap ml-[4%]"
              >
                {t("ADVANCED")}
              </button>
              <button
                onMouseEnter={() => setHoveredMode(1)}
                onClick={() => handleRenderingModeChange("txt2img")}
                className="font-title font-bold border-2 border-solid border-black
          text-[6vw] lg:text-[2vw] hover:bg-black bg-white hover:text-white 
          text-black transition-colors ease-in-out duration-300 p-[4%] text-nowrap"
              >
                {t("SIMPLE")}
              </button>
            </div>
          </div>
          {/* {hoveredMode !== -1 && (
            <div className="absolute w-[28vw] max-w-[28vw] 
            left-1/2 mt-3 -translate-x-1/2  h-[18vw] 
            border border-solid leading-5 bottom-[1%]
            border-black custom-scrollbar overflow-auto
            text-wrap font-paragraph text-[150%] break-words p-1
              ">
              <p className="animate-fade animate-duration-300">
                {hoveredMode === 0 ?
                  "With IMG2IMG, you can use two images as references to render the best ideas you have!"
                  :
                  "With TXT2IMG, you can render by using one photo as reference to render the most incredible idea you have!"}
              </p>
            </div>
          )} */}
        </div>
      ) : (
        <div className="animate-fade animate-duration-300 animate-once">
          <div 
            style={{
              marginTop: navbarHeight
            }}>
            <div className="w-full h-auto flex flex-row-reverse justify-center items-center p-[0.5%]">
              <button
                onClick={() => handleRenderingModeChange("img2img")}
                className={`font-title text-[3.6vw] lg:text-[0.8vw] transition-colors ease-in-out duration-300
            border-2 border-solid border-black p-[0.5%] ml-[4%] lg:ml-[1%]
          ${
            selectedMode === "img2img"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
              >
                {t("ADVANCED")}
              </button>
              <button
                onClick={() => handleRenderingModeChange("txt2img")}
                className={`font-title text-[3.6vw] lg:text-[0.8vw] transition-colors ease-in-out duration-300
            border-2 border-solid border-black p-[0.5%]
          ${
            selectedMode === "txt2img"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
              >
                {t("SIMPLE")}
              </button>
            </div>
            <ImageUploader
                updateUserChoices={updateUserChoices}
                title={t(selectedMode === "txt2img" ? 
                  "DROP THE IMAGE WITH THE STYLE HERE" : 
                  "DROP THE IMAGE YOU WANT TO BE STYLIZED HERE")}
                paramName={"imageUploader"}
                pasteEnable={true}
                enableHover={true}
                infoText={selectedMode === "txt2img" ? 
                  t(
                  "The style image can be anything, a painting, a drawing, a landscape, the AI will use the data on the image to create something unique."
                ) : 
                t(
                  "This image will receive the Style and will be changed by the AI, you don't need to add any image here, if you don't upload an image here the AI will generate one based in your instructions and stylize it."
                )}
              />
              {(selectedMode === "txt2img" && sessionStorage.getItem("imageUploader") !== null) && (
                <div className="w-screen justify-center flex">
                <img
                src={sessionStorage.getItem("imageUploader")}
                className="size-[25%] object-cover animate-fade animate-duration-300 
                mb-[1%] p-[3%] bg-black"
              />
              </div>
              )}
          </div>
          {selectedMode === "img2img" && (
            <div className="relative">
              <ImageUploader
              enableHover={true}
              updateUserChoices={updateUserChoices}
              title="DROP THE IMAGE WITH THE STYLE HERE"
              bgColor={"bg-[#1a2748]"}
              paramName={"imageUploader2"}
              pasteEnable={false}
              infoText={t(
                "The style image can be anything, a painting, a drawing, a landscape, the AI will use the data on the image to create something unique."
              )}
            />
              <FaTrash
                size={22}
                color="black"
                className="cursor-pointer hover:animate-pulse 
              hover:animate-infinite animate-duration-1000 
              absolute right-[17%] bottom-[9%]"
                onClick={
                  removeImageUp2
                }
              />
            </div>
          )}
          <div className="flex lg:flex-row flex-col w-screen justify-around items-center px-2">
            {selectedMode === "img2img" ? (
              <div 
              ref={cropRef}>
              <Crop
                paramName="imageUploader"
                image={sessionStorage.getItem("imageUploader")}
                updateUserChoices={updateUserChoices}
              />
              </div>
            ) : (
              <Format 
              image={sessionStorage.getItem("imageUploader")}
              updateUserChoices={updateUserChoices} />
            )}
            {selectedMode === "img2img" && (
              <div className="flex lg:w-auto w-full justify-center py-4">
                <div 
                style={{
                  minHeight: height - 32
                }}
                className="bg-[#1a2748] flex justify-center items-center lg:w-[34rem] w-full p-2">
                  {userChoices.imageUploader2 !== null ? (
                    <img
                      src={sessionStorage.getItem("imageUploader2")}
                      className="w-[34rem] h-[34rem] object-cover animate-fade animate-duration-300"
                    />  
                  ) : (
                    <p className="font-paragraph text-xl text-white">
                      {t("The second image will appear here")}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full pb-8 lg:py-8 flex-col lg:flex-row items-center justify-center max-w-screen">
            <div className="w-1/2">
              <Type 
              userChoice={userChoices.type}
              verticalSet={true} 
              updateUserChoices={updateUserChoices} />
            </div>
            <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
            <div className="w-1/2">
              <Seed
                className="md:mt-8 sm:mt-8"
                updateUserChoices={updateUserChoices}
              />
            </div>
          </div>
          {handleTypeChoose()}
        </div>
      )}
      <Footer />
    </div>
  );
} 