import React, { useState, useEffect } from "react";
import ImageUploader from "../components/RenderComponents/ImageUploader";
import ImageCropper from "../components/RenderComponents/ImageCropper";
import AIModels from "../components/RenderComponents/AIModels";
import Seed from "../components/RenderComponents/Seed";
import whatInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
// import SizeDecorate from "../components/RenderComponents/SizeDecorate";
import Materials from "../components/RenderComponents/Materials";
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import ColorPalette from '../components/RenderComponents/ColorPalette.jsx';
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent.jsx";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import Visibility from "../components/RenderComponents/Visibility";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange";
import Navbar, { navbarHeight } from "../components/Navbar";
import RenderButton from "../components/RenderComponents/RenderButton";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Precision from "../components/RenderComponents/Precision.jsx";
import CustomRange from "../components/RenderComponents/CustomRange.jsx";
//import { geraPrompt } from '../apiComfy/ReDecorate/Redecorate_Pro_Landscape.js';
import { geraPrompt as geraPromptLandscapePro } from "../apiComfy/ReDecorate/Redecorate_Pro_Landscape.js";
import { geraPrompt as geraPromptSquarePro } from "../apiComfy/ReDecorate/Redecorate_Pro_Square.js";
import { geraPrompt as geraPromptPortraitPro } from "../apiComfy/ReDecorate/Redecorate_Pro_Portrait.js";

import { geraPrompt as geraPromptLandscapeStandart } from "../apiComfy/ReDecorate/Redecorate_Standart_Landscape.js";
import { geraPrompt as geraPromptSquareStandart } from "../apiComfy/ReDecorate/Redecorate_Standart_Square.js";
import { geraPrompt as geraPromptPortraitStandart } from "../apiComfy/ReDecorate/Redecorate_Standart_Portrait.js";
import Accordion from "../components/RenderComponents/Accordion.jsx";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent.jsx";

export default function ReDecoratePage() {
  const standardColors = {
    active: "bg-[#fffe80] text-black",
    inactive: "bg-[#ffffb3] text-black",
    hover: "hover:bg-[#fffe80] hover:text-black",
    valor: 2,
  };

  const { t } = useTranslation();

  const professionalColors = {
    active: "bg-[#fcac7f] text-black",
    inactive: "bg-[#fdd7bc] text-black",
    hover: "hover:bg-[#fcac7f] hover:text-black",
    valor: 3,
  };
  const token = Cookies.get("token");
  // Função para verificar se o token está presente e válido
  function verificarToken() {
    const token = Cookies.get("token");
    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const [userChoices, setUserChoices] = useState({
    imageUploader: null,
    imageCropper: null,
    aiModels: null,
    seed: null,
    size: null,
    what: null,
    time: null,
    style: "Modern",
    materials: null,
    effects: null,
    visibility: "PUBLIC",
    precision: "STANDARD",
    AIImmaginationRange: "3",
    RoomWeightRange: "2",
    RoomWeightStyleRange: "5",
    ShapeStrengthRange: "2",
    ShapeExposureRange: "3",
    ShapeBoostRange: "2",
    BoostExposureRange: "1",
  });

  useEffect(() => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
  }, [])

  const updateUserChoices = (category, choice) => {
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });
  }, [i18n]);

  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId; 
  
  const convertToJson = () => {
    //const user = Cookies.get("clientId"); //pega o user da sessão

    const nomeImage = `${user}_${Date.now()}.png`;

    sessionStorage.setItem("nomeImage", nomeImage);

    let geraPrompt;

    if (userChoices.precision === "PROFESSIONAL") {
      if (userChoices.imageCropper === "landscape") {
        geraPrompt = geraPromptLandscapePro(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "portrait") {
        geraPrompt = geraPromptPortraitPro(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "square") {
        geraPrompt = geraPromptSquarePro(userChoices, user, nomeImage);
      }
    } else {
      if (userChoices.imageCropper === "landscape") {
        geraPrompt = geraPromptLandscapeStandart(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "portrait") {
        geraPrompt = geraPromptPortraitStandart(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "square") {
        geraPrompt = geraPromptSquareStandart(userChoices, user, nomeImage);
      }
    }

    const saida = geraPrompt;
    console.log(userChoices);
    sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));
  };

  useEffect(() => {
    /* Função responsável pelo título das páginas */
    document.title = "Re-Decorate - Arcseed AI";
    return () => {
      document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
    };
  }, []);

  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };

  const [openAccordion, setOpenAccordion] = useState(null);
  const [openAccordion1, setOpenAccordion1] = useState(null);

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };

  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
    window.innerWidth < 1024 ? 
      (
        <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
          <div className="flex">{items}</div>
          <div className="flex">{items}</div>
        </div>
      )
      :
      ( 
        <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
      )
    )
  };

  return (
    <div className="overflow-hidden">
      <NewPopUpComponent/>
      <Navbar
        title={t("Re-Decorate ")}
        userChoices={userChoices}
        description="Decorate an empty room in a few seconds with this tool! if you are an advanced user click here for the advanced toolset or turn the advanced toolset on your settings page here ,there all the generative pages will be in one place for fast editing and access."
        showPoints={true}
        moedas={moedas}
      />
      <div 
      style={{
        marginTop: navbarHeight
      }}>
        <ImageUploader
          title={"Drop the environment to be furnished here"}
          subtitle={
            "You can take a screenshot (PrtScr no teclado) and paste it here with Ctrl+v, to crop it below."
          }
          updateUserChoices={updateUserChoices}
        />
      </div>
      <ImageCropper
        image={sessionStorage.getItem("imageUploader")}
        updateUserChoices={updateUserChoices}
      />
      <div className="flex w-full py-8 flex-col lg:flex-row items-center justify-center max-w-screen">
        <div className="w-1/2">
          <AIModels verticalSet={false} updateUserChoices={updateUserChoices} />
        </div>
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
        <div className="w-1/2">
          <Seed
            className="md:mt-8 sm:mt-8"
            updateUserChoices={updateUserChoices}
          />
        </div>
      </div>
      {/* <What updateUserChoices={updateUserChoices} /> */}
      <GenericRenderComponent
      updateUserChoices={updateUserChoices}
      renderParamJSONConfig={whatInteriorsJSON}
      isOpen={true}
      />
      <div className="flex w-full justify-center items-center">
        <h1
          onClick={() => setOpenCustomization(!openCustomization)}
          className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
        >
          {t("CUSTOMIZATION")}
        </h1>
      </div>
      <Accordion isOpen={openCustomization}>
        {window.innerWidth < 1024 ? 
        (
        <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
          {renderWallpaperImages()}
            {/* <SizeDecorate updateUserChoices={updateUserChoices} /> */}
            <div className="mb-2">
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={timeInteriorsJSON}
            isOpen={openAccordion === "time"}
            onToggle={() => handleAccordionToggle("time")}
            />
            {/* <Time
              isOpen={openAccordion1 === "time"}
              onToggle={() => handleAccordionToggle1("time")}
              updateUserChoices={updateUserChoices}
            /> */}
          </div>
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={styleInteriorsJSON}
            isOpen={openAccordion === "style"}
            onToggle={() => handleAccordionToggle("style")}
            />
            {/* <Style
              isOpen={openAccordion === "style"}
              onToggle={() => handleAccordionToggle("style")}
              updateUserChoices={updateUserChoices}
            /> */}
        </div>
        )
        :
        (
        <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
          {renderWallpaperImages()}
          <div className="flex flex-col h-[100%] justify-center w-[50vw]">
            {/* <SizeDecorate updateUserChoices={updateUserChoices} /> */}
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={timeInteriorsJSON}
            onToggle={() => handleAccordionToggle("time")}
            isOpen={(openAccordion === "time")}
            />
            {/* <Time
              isOpen={openAccordion1 === "time"}
              onToggle={() => handleAccordionToggle1("time")}
              updateUserChoices={updateUserChoices}
            /> */}
          </div>
          <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
          <div className="flex flex-col h-[100%] justify-center w-[50vw]">
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={styleInteriorsJSON}
            onToggle={() => handleAccordionToggle1("style")}
            isOpen={(openAccordion1 === "style")}
            />
            {/* <Style
              isOpen={openAccordion === "style"}
              onToggle={() => handleAccordionToggle("style")}
              updateUserChoices={updateUserChoices}
            /> */}
          </div>
        </div>
        )}
        <ColorPalette updateUserChoices={updateUserChoices}/>
        <Materials updateUserChoices={updateUserChoices} />
      </Accordion>
      <div className="flex w-full justify-center items-center">
        <h1
          onClick={() => setopenFinetuning(!openFinetuning)}
          className={`
      font-title cursor-pointer transition-all ease-in-out 
      duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
      border-solid border-black leading-[99%]`}
        >
          {t("Fine-tuning")}
        </h1>
      </div>
      <Accordion isOpen={openFinetuning}>
        <div className="w-full lg:px-[10vw] flex flex-col lg:flex-row justify-center">
          <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
            <div className="mt-4">
              <AIImmaginationRange
                initialValue={"3"}
                updateUserChoices={updateUserChoices}
              />
            </div>
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"ROOM WEIGHT"}
              startsAt={"2"}
              maxRange={"5"}
              infoBoxDescription={
                "Room weight will try to hold heavier to the original shape to decorate, but it can also decrease the amount of objects decorating the scene."
              }
              description={
                "How much the Shape of the room will influence on the AI calculation"
              }
              variableName={"RoomWeightRange"}
              options={["NULL", "HEAVY"]}
              className="mt-4"
            />
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"SHAPE EXPOSURE"}
              startsAt={"3"}
              maxRange={"5"}
              infoBoxDescription={
                "Stronger the exposure more the AI will let the shape participate on the final render."
              }
              description={
                "How long the AI should hold to the original image's form"
              }
              variableName={"ShapeExposureRange"}
              options={["SOFT", "MODERATE", "STRONG"]}
              className={"mt-4"}
            />
          </div>
          <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center">
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"ROOM WEIGHT EXPOSURE"}
              startsAt={"5"}
              maxRange={"5"}
              infoBoxDescription={
                "This slider will make more of the style of the room influence the render, it can be helpful to increase this value if the final image are not following the shape of the room properly."
              }
              description={
                "How much the shape of the room will stylize the final image"
              }
              variableName={"RoomWeightStyleRange"}
              className={"mt-4"}
              options={["WEAK", "STRONG"]}
            />
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"SHAPE STRENGTH"}
              startsAt={"2"}
              maxRange={"5"}
              infoBoxDescription={
                "How much the AI will try to keep the shape over trying to be creative."
              }
              description={
                "More creative the AI is less precise it tends to be"
              }
              variableName={"ShapeStrengthRange"}
              options={["Creative", "Precise"]}
              className="mt-4"
            />
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"SHAPE BOOST"}
              startsAt={"2"}
              maxRange={"5"}
              infoBoxDescription={
                "This is an extra boost on the shape of the room in case its no following properly"
              }
              description={"Give a boost on the room shape"}
              variableName={"ShapeStrengthRange"}
              options={["1", "2", "3", "4", "5"]}
              className="mt-4"
            />
          </div>
        </div>
        <CustomRange
          updateUserChoices={updateUserChoices}
          title={"BOOST EXPOSURE"}
          startsAt={"1"}
          maxRange={"5"}
          infoBoxDescription={
            "This value will determine how much the boost will linger on the rendering, sometimes its better to have lower boost but let it linger longer."
          }
          description={"How long the boost will stay active"}
          variableName={"BoostExposureRange"}
          className={"my-4"}
          options={["1", "2", "3", "4", "5"]}
        />
      </Accordion>
      <Visibility updateUserChoices={updateUserChoices} />
      <Precision
        updateUserChoices={updateUserChoices}
        standardColors={standardColors}
        professionalColors={professionalColors}
      />
      <RenderButton
        onRenderButtonClick={convertToJson}
        userChoices={userChoices}
        onMoedasUpdate={handleMoedasUpdate}
      />
      <Footer />
    </div>
  );
}
