import React, { useState, useEffect, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { add_Back, add_Front } from "../../address";
import { jwtDecode } from "jwt-decode";
const stripePromise = loadStripe(
  "pk_live_51PiHIWRuWqLo33c7oSRNcbB0NIl0pHEFfLX5ZWVbRt1Xq1sfRpXL8JWPqqH5HgOYQsHZoqVG7JsiedC1yBniiQO800PBeyCFKz"
);

const Return = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Função para verificar se o token está presente e válido
  const verificarToken = useCallback(() => {
    const token = Cookies.get("token");
    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      return false; // Token inválido
    }
  }, []);

  // Função para redirecionar para a página de login
  const redirectToLogin = useCallback(() => {
    window.location.href = "/Login"; // Redireciona para a página de login
  }, []);

  // Verificar o token ao carregar a página
  useEffect(() => {
    if (!verificarToken()) {
      redirectToLogin(); // Redireciona para a página de login se o token não for válido
    }
  }, [verificarToken, redirectToLogin]);

  // Obtenha o parâmetro planId da URL
  let planId,requestBody;
  const token = Cookies.get("token");
  try {
    const searchParams = new URLSearchParams(location.search);
    const encodedData = searchParams.get("data");

    if (encodedData) {
      const decodedData = atob(encodedData);
      const decodedParams = new URLSearchParams(decodedData);
      planId = decodedParams.get("planId");

      sessionStorage.setItem("planId", planId);

      if (token) {
        const decodedToken = jwtDecode(token);
        const clienteId = decodedToken.clienteId;

        requestBody = {
          planId: planId,
          clienteId: clienteId,
        };
      } else {
        throw new Error("Token não encontrado ou inválido.");
      }
    } else {
      throw new Error("Parâmetro 'data' não encontrado na URL.");
    }
  } catch (error) {
    console.error("Erro ao processar os parâmetros da URL:", error);
    navigate("/"); // Redireciona para a página inicial em caso de erro
  }
  useEffect(() => {
    if (!planId) {
      navigate("/");
    }
  }, [planId, navigate]);

  const fetchClientSecret = useCallback(async () => {
    setIsRequesting(true); // Marca que a requisição está em andamento

    try {
      const response = await fetch(`${add_Back}/checkout`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("Resposta da requisição:", data);

      if (data.error === "Ja esta no plano escolhido.") {
        alert("Não é possivel escolher esse plano.");
        window.location.href = "/Dashboard/initial";
      }
      if (data.message === "Erro") {
        alert(
          "Só é possivel assinar um novo plano após o termino do plano atual."
        );
        window.location.href = "/Dashboard/initial";
      }
      if (data.message === "Plano atualizado com sucesso") {
        if (data.subscription) {
          sessionStorage.setItem(
            "subscription",
            JSON.stringify(data.subscription)
          );
        }
        navigate("/ThanksPage");
      }
      if (data.error === "Plano inválido.") {
        alert(
          "Erro ao processar pagamento."
        );
        window.location.href = "/Dashboard/initial";
      }

      setClientSecret(data.clientSecret);
    } catch (error) {
      alert("Erro ao processar pagamento.");
      window.location.href = "/Dashboard/initial";
      console.error("Erro na requisição:", error);
    } finally {
      setIsRequesting(false); // Marca que a requisição terminou
    }
  }, []);

  useEffect(() => {
    if (verificarToken()) {
      if (!isRequesting && !clientSecret) {
        fetchClientSecret();
      }
    } else {
      redirectToLogin();
    }
  }, [
    fetchClientSecret,
    planId,
    isRequesting,
    clientSecret,
    verificarToken,
    redirectToLogin,
    navigate,
  ]);

  return (
    <div className="App">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default Return;
