import React, { useState, useEffect, useRef } from 'react';
import './ImageCropper/croppie.css';
import Croppie from 'croppie';
import { useTranslation } from 'react-i18next';

function ImageCropper({ image, updateUserChoices, paramName="imageUploader" }) {
  const [cropOptions, setCropOptions] = useState({ width: 300, height: 300 });
  const [outputSize, setOutputSize] = useState({ width: 1024, height: 1024 });
  const [userChoice, setUserChoice] = useState("square");
  const { i18n } = useTranslation();
  const imgContainerRef = useRef(null);
  const uploadRef = useRef(null);
  const croppedImageRef = useRef(null);
  const cropButtonRef = useRef(null);
  const croppieInstanceRef = useRef(null);

  const handleDoneButtonClick = () => {
    updateUserChoices("imageCropper", userChoice);
    setIsDoneButtonClicked(true);

    if(croppieInstanceRef !== undefined ) {
      if(image !== undefined){
      croppieInstanceRef.current.result({type: 'canvas',size: { width: outputSize.width, height: outputSize.height }}).then((result) => {
        croppedImageRef.current.src = result;       
        console.log(croppedImageRef.current)
        sessionStorage.setItem('croppedImage', result);
      });

      }
    }


  };

  useEffect(() => {
    // console.log(imgContainerRef + image + croppedImageRef + cropButtonRef + croppieInstanceRef +
    //   uploadRef  
    // );
    image = null;
      if(sessionStorage.length !== 0) {
        sessionStorage.removeItem(paramName); // Remove a imagem ao carregar o componente
      }
  }, [sessionStorage]);

  useEffect(() => {
    try {
        if (imgContainerRef.current) {
            if (croppieInstanceRef.current) {
              croppieInstanceRef.current.destroy();
            }

              if(image !== undefined) {
              croppieInstanceRef.current = new Croppie(imgContainerRef.current, {
                viewport: cropOptions,
                boundary: { width: 400, height: 400 },
                enableZoom: true,
                url: image,
              });
            }
    
          const handleFileUpload = (e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            try {
              reader.onload = (event) => {
                croppieInstanceRef.current.bind({
                  url: event.target.result
                });
              };
              reader.readAsDataURL(file);
            }
            catch(error) { console.log("a") }
    
            // imgContainerRef.current.style.display = 'block';
          };
    
          const handleCropButtonClick = () => {
            if(croppieInstanceRef !== undefined) {
                croppieInstanceRef.current.result({type: 'canvas',size: { width: outputSize.width, height: outputSize.height }}).then((result) => {
                croppedImageRef.current.src = result;
                sessionStorage.setItem('croppedImage', result);
              });
            }
          };
    
          if (uploadRef.current !== undefined) {
            uploadRef.current.addEventListener('change', handleFileUpload);
          }
    
          if (cropButtonRef.current !== undefined) {
            cropButtonRef.current.addEventListener('click', handleCropButtonClick);
          }
    
          return () => {
            if (uploadRef.current !== undefined) {
              uploadRef.current.removeEventListener('change', handleFileUpload);
            }
    
            if (cropButtonRef.current !== undefined) {
              cropButtonRef.current.removeEventListener('click', handleCropButtonClick);
            }
    
            if (croppieInstanceRef.current !== undefined) {
              croppieInstanceRef.current.destroy();
              croppieInstanceRef.current = null;
            }
          };
        }
    }
    catch(error) { console.log("oi") }
  }, [cropOptions, image]);

  useEffect(() => {
    setIsDoneButtonClicked(false);
  }, [userChoice])

  const waitingForImageUpload = () => {
    if (image === null) {
      return (
        <p className='text-white font-paragraph lg:text-xl text-[130%] lg:mt-0 -mt-[3vw]'>{i18n.t("First, you have to upload an image to set the resolution.")}</p>
      );
    } else {
      return (
        <div>
          <button 
            onClick={handleDoneButtonClick} 
            id="upload"
            ref={cropButtonRef}
            className={`mt-8 font-title px-5 py-1  
            white-border font-bold tracking-widest
            hover:opacity-70 ease-in-out duration-300 transition-all
            ${isDoneButtonClicked ? (`
            hover:bg-black hover:text-white 
            bg-white text-black`) : 
            "text-white"}`}>
            {i18n.t("DONE")}
          </button>
        </div>
      );
    }
  };

  const [isDoneButtonClicked, setIsDoneButtonClicked] = useState(false);

  const updateCropOptions = (width, height, outputWidth, outputHeight, userChoice) => {
    setCropOptions({ width, height });
    setOutputSize({ width: outputWidth, height: outputHeight });
    setUserChoice(userChoice);
  };

  const optionsHeight = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if(optionsHeight.current !== null) {
      setHeight(optionsHeight.current.clientHeight)
    }
  }, [optionsHeight])
  return (
    <div className='flex w-full max-w-screen justify-center py-4'>
      <div className='bg-black flex justify-center items-center h-full lg:w-[34rem] w-full'>
        <div className='w-[96%] h-[96%] flex flex-col'>
          <h2 className='text-white font-title font-bold text-3xl mt-2'>{i18n.t("CROP")}</h2>
          <div 
          style={{
            height: height
          }}
          className='w-full px-4 relative'>
            <div 
            className='absolute left-[5%] top-3'>
            <div
              id="square-option"
              onClick={() => updateCropOptions(300, 300, 1024, 1024, 'square')}
              className={`flex flex-col cursor-pointer justify-between items-center ease-in-out duration-300 transition-all bg-black
                ${userChoice === "square" ? "outline outline-1 outline-white p-2" : "outline outline-1 outline-transparent p-2"}`}
            >
              <div className='size-[15vw] md:size-16 border-2 border-white border-dashed' />
              <p className='text-white font-title font-bold text-[3.5vw] md:text-lg mt-3'>{i18n.t("SQUARE")}</p>
            </div>
            </div>
            <div className='absolute left-1/2 -translate-x-1/2 top-3'>
            <div 
            ref={optionsHeight}
            id="landscape-option" 
            onClick={() => updateCropOptions(400, 300, 1344, 1024, 'landscape')} 
            className={`flex flex-col cursor-pointer justify-between items-center ease-in-out duration-300 transition-all bg-black
            ${userChoice === "landscape" ? "outline outline-1 outline-white p-2" : "outline outline-1 outline-transparent p-2"}`}>
              <div className='md:w-20 w-[18.75vw] md:h-16 h-[15vw] border-2 border-white border-dashed' />
              <p className='text-white font-title font-bold text-[3.5vw] md:text-lg mt-3'>{i18n.t("LANDSCAPE")}</p>
            </div>
            </div>
            <div className='absolute right-[5%] top-1'>
            <div 
            id="portrait-option" 
            onClick={() => updateCropOptions(300, 400, 1024, 1344, 'portrait')} 
            className={`flex flex-col cursor-pointer justify-between items-center px-2 pt-4 pb-2 outline 
              ease-in-out duration-300 transition-all outline-solid outline-1 bg-black
              ${userChoice === "portrait" ? "outline-white" : " outline-transparent"}`}>
              <div className='md:w-20 md:h-16 w-[18.75vw] h-[15vw] rotate-90 border-2 border-white border-dashed' />
              <p className='text-white font-title font-bold text-[3.5vw] md:text-lg mt-3'>{i18n.t("PORTRAIT")}</p>
            </div>
            </div>
          </div>
          <div className='flex flex-col h-full py-5 justify-center cursor-pointer items-center'>
            {image !== null && (
            (image && imgContainerRef !== null) ? 
            <div 
              id="croppieContainer"
              ref={imgContainerRef}
              className='relative flex flex-col justify-center 
              items-center animate-fade-down animate-duration-300'>
            </div>
            :
            <p/>
            )}
            <img
            className='hidden'
            ref={croppedImageRef}/>
            {waitingForImageUpload()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageCropper;
