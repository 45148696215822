import React, { useEffect, useRef, useState } from "react";
import RenderPointsImg from "../../assets/lightThumbnails/Exteriors/Style/Asian Fusion.webp";
import RenderImg from "../../assets/lightThumbnails/Exteriors/Style/Boho.webp";
import highQualityImg from "../../assets/high.webp";
import lowQualityImg from "../../assets/Low.webp";
import PrivateImg from "../../assets/VisibilityExample.webp";
import { FaArrowDown } from "react-icons/fa";
import ReactCompareImage from 'react-compare-image';
import { Link } from "react-scroll";
import { Trans, useTranslation } from "react-i18next";

const InfoContainer = () => {
  const buttonTitles = [
    "RENDERS PER MONTH*",     
    "PRECISION*¹",
    "REFINEMENTS*²",
    "PRIVATE IMAGES*³",     
    "ADVANCED RENDERER*⁴",
    "MULTIPLE LOGINS*⁵"
  ];

  const rightImageCss = {
    filter: "contrast(60%)",
    imageRendering: "pixelated"
  }

  const InfoContainerSlides = {
    0: {
      title: "render points",
      description: (
        <p>
          <Trans i18nKey="Each plan has a number of Render Points to be used during the month and each tool has a specific consumption of RPs, check the quantities used on the rendering page. These points can be bought or purchased.">
          Each plan has a number of Render Points to be used during the month and each tool has a specific consumption of RPs, check the quantities used on the rendering page. These points can be bought or purchased.
          </Trans>
        </p>
      ),
      img: RenderPointsImg,
      isVertical: false,
    },
    1: {
      title: "precision",
      description: (
        <div className="h-fit">
          <Trans i18nKey="There are two types of rendering: Standard and Professional, both tend to have high qualities but Professional optimizes the size and detail of the final image. Keep in mind that each tool uses a specific amount of RPs and that standard and professional rendering also have differences in RP consumption. Check the amount of RPs used in the precision selector just before the render button on the render page.">
            <p>
            There are two types of rendering: Standard and Professional, both tend to have high qualities but Professional optimizes the size and detail of the final image. Keep in mind that each tool uses a specific amount of RPs and that standard and professional rendering also have differences in RP consumption. Check the amount of RPs used in the precision selector just before the render button on the render page.
            </p>
          </Trans>
        </div>
      ),
      img: RenderImg,
      isVertical: false,
    },
    2: {
      title: "refinement",
      description: (
        <p>
          <Trans i18nKey="Refinement transforms the image generated in standard or pro rendering into an image with higher resolution and more detail, usually used for final presentations. Refinement, being a more time-consuming process, uses 5 render points.">
            Refinement transforms the image generated in standard or pro rendering into an image with higher resolution and more detail, usually used for final presentations. Refinement, being a more time-consuming process, uses 5 render points.
          </Trans>
        </p>
      ),
      isVertical: true,
      flexJustify: "justify-center",
      middleElement: (
        <div 
        className="relative flex flex-col justify-center items-center ">
          <div className="flex flex-col items-center h-fit w-full justify-self-start">
          <div className="h-[100%] w-[75%] lg:w-[50%] ">
            <ReactCompareImage
              leftImage={highQualityImg}
              rightImage={lowQualityImg}  
              rightImageCss={rightImageCss}
            />
          </div>
          </div>
          <div className="w-[95%] bg-black h-[1px] my-[4%]" />
          <p
            className="text-center ml-[4%] font-title tracking-wide 
                  leading-[150%] lg:text-[0.7vw] text-[85%] overflow-auto h-[20%] max-h-[85%] custom-scrollbar"
          >
            <Trans i18nKey="Refinement transforms the image generated in standard or pro rendering into an image with higher resolution and more detail, usually used for final presentations. Refinement, being a more time-consuming process, uses 5 render points.">
              Refinement transforms the image generated in standard or pro rendering into an image with higher resolution and more detail, usually used for final presentations. Refinement, being a more time-consuming process, uses 5 render points.
            </Trans>
          </p>
        </div>
      ),
    },
    3: {
      title: "private images",
      isVertical: true,
      flexJustify: "justify-center",
      middleElement: (
        <div>
          <div>
          <img src={PrivateImg} className="h-auto w-[100%]" />
          <p
            className="font-title tracking-wide 
                  leading-[150%] text-[85%] lg:text-[0.8vw] my-[4%] overflow-auto 
                  custom-scrollbar"
          >
            <Trans i18nKey="You can keep your images in your private gallery where other users will have access to your creations. To do this, simply choose the private option on the generation page.">
              You can keep your images in your private gallery where other users will have access to your creations. To do this, simply choose the private option on the generation page.
            </Trans>  
          </p>
          </div>
          <div className="bg-black w-[95%] h-[1px] absolute bottom-[4%] left-1/2 -translate-x-1/2" />
        </div>
      ),
    },  
    4: {
      title: "Advanced renderer",   
      isVertical: true,
      middleElement: (
        <div className="h-full flex flex-col justify-center items-center relative">
          <p
            className="font-title tracking-wide
            leading-[150%] text-[85%] lg:text-[0.8vw] overflow-auto 
            custom-scrollbar mb-[4%]"
          >
            <Trans i18nKey="A powerful advanced renderer will soon be available for users who want to fully customize image generations.">
              A powerful advanced renderer will soon be available for users who want to fully customize image generations.
            </Trans>
          </p>
          <div className="bg-black bottom-[4%] h-[1px] w-[95%] absolute " />
        </div>
      ),
    },
    5: {
      title: "Multiple logins",
      isVertical: true,
      middleElement: (
        <div className="h-full flex flex-col justify-center items-center relative">
          <p
            className="font-title tracking-wide mb-[4%]
             leading-[150%] text-[85%] lg:text-[0.8vw] overflow-auto 
            custom-scrollbar"
          >
            <Trans i18nKey="Additional logins allow you to log in to multiple computers with the same account for an additional fee of R$60.00 per login. This fee is charged for server maintenance and to reduce queues caused by multiple renders being made on the same account.">
              Additional logins allow you to log in to multiple computers with the same account for an additional fee of R$60.00 per login. This fee is charged for server maintenance and to reduce queues caused by multiple renders being made on the same account.
            </Trans>
          </p>
          <div className="bg-black h-[1px] w-[95%] absolute bottom-[4%]" />
        </div>
      ),
    },
  };
  

  const [actualSlide, setActualSlide] = useState(0);
  const [showText, setShowText] = useState(false);

  const { t } = useTranslation();

  const nextTopicRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if(nextTopicRef.current !== null) {
      setHeight(nextTopicRef.current.clientHeight);
    }
  }, [nextTopicRef.current])

  return (
    <div className="h-screen bg-black relative overflow-hidden">
      <div className="lg:h-[20%] h-[15%] w-full flex justify-center items-center flex-col">
        <h1 className="font-title text-white tracking-widest text-[145%] lg:text-[2vw]">
          entendendo
        </h1>
        <div className="lg:w-full hidden lg:flex lg:justify-center w-auto self-start lg:self-auto my-3 overflow-auto no-scrollbar">
          {buttonTitles.map((title, index) => (
            <button 
              key={index}
              onClick={() => setActualSlide(index)}
              className={`font-title lg:p-4 p-[2%] lg:mx-1 mx-[1%] text-[80%] lg:text-[0.9vw] font-bold cursor-pointer
                transition-colors ease-in-out duration-300
                ${actualSlide === index ? "bg-slate-400" : "bg-white"}`}
            >
              {t(title)}
            </button>
          ))}
        </div>
        <div className="h-[1px] bg-white w-[90%] my-[6%] lg:my-0"/>
      </div>
      <div className="h-[75%] w-full flex justify-center lg:items-start items-center">
        <div className="bg-white lg:h-[95%] lg:w-[45%] w-[95%] h-auto p-4 flex flex-col items-center relative">
          <h1 className={"py-4 px-[16%] bg-green-200 font-title font-bold w-fit text-[105%] text-nowrap lg:text-[1.3vw]"}>
            {t(InfoContainerSlides[actualSlide]?.title)}
          </h1>
          <div className="bg-black w-[95%] h-[1px] my-[4%]"/>
          {!InfoContainerSlides[actualSlide].isVertical ? (
          <div 
          style={{
            marginBottom: height
          }}
          className="flex lg:flex-row flex-col justify-start items-start w-full h-full">
            <div className="lg:w-[50%] w-[60%] h-auto">
              <img 
                src={InfoContainerSlides[actualSlide]?.img} 
                alt=""
                className="object-contain w-full"
              />
            </div>
            <div className="flex flex-col lg:mt-0 mt-2 h-[100%] lg:w-[50%] w-full justify-start lg:text-center relative">
                <p className="flex items-around lg:border-transparent border-solid border-[1px] border-black text-start lg:ml-[4%] font-title tracking-wide 
                leading-[150%] lg:text-[0.7vw] text-[85%] overflow-auto lg:h-[85%] lg:max-h-[85%] h-[40vw] custom-scrollbar lg:p-0 p-[2%]">
                  {InfoContainerSlides[actualSlide]?.description}
                </p>
            </div>
          </div>
          ) :
          (
            <div 
            style={{
              marginBottom: height
            }}
            className={`relative w-full h-full flex flex-col 
              ${InfoContainerSlides[actualSlide].flexJustify !== undefined ? InfoContainerSlides[actualSlide].flexJustify : `justify-between`}`}>
              {InfoContainerSlides[actualSlide].middleElement}
            </div>
          )}
          <div 
          ref={nextTopicRef}
          className="lg:absolute mt-2 lg:mt-0 bottom-4 right-4 flex w-full justify-end items-center">
                {(showText || window.innerWidth < 1024) && (
                  <p className="font-title font-bold lg:text-[1vw] text-[100%] mr-2 animate-fade animate-duration-300">
                    {t("next topic")}
                    </p>
                )}
                <FaArrowDown
                onMouseEnter={() => setShowText(true)}
                onMouseLeave={() => setShowText(false)}
                onClick={() => 
                  setActualSlide(actualSlide === 5 ? 
                    0 : 
                    actualSlide + 1
                  )}
                className="rotate -rotate-90 cursor-pointer size-[7%] lg:size-[2.5vw] z-10"/>
          </div>
        </div>
      </div>
      <Link 
        to="footer"
            smooth={true}
            offset={0}
            duration={750}>
      <FaArrowDown
          color="white"
          size={40}
        className="w-full flex justify-center absolute bottom-0
        cursor-pointer z-10 mb-1"/>
        </Link> 
      </div>
  );
}

export default InfoContainer;