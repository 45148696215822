import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import NavbarButtons from "../../components/NavbarButtons";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { add_Back } from "../../address.js";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export default function Thankspage() {
  const [nickName, setNickName] = useState("");
  const [formData, setFormData] = useState({
    nickame: "",
  });

  function verificarToken() {
    const token = Cookies.get("token");
    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  async function fetchSessionStatus(sessionId) {
    try {
      const response = await fetch(
        `${add_Back}/session-status?session_id=${sessionId}`
      );
      const data = await response.json();
      console.log(data);
      return {
        status: data.status,
        customerEmail: data.customer_email,
        payment_method_types: data.payment_method_types,
        amount_total: data.amount_total,
        dateStripe: data.dateStripe,
        customerId: data.customer,
      };
    } catch (error) {
      console.error("Erro:", error);
      throw error; // Rejete o erro para tratamento posterior, se necessário
    }
  }

  const token = Cookies.get("token");
  const decodedToken = jwtDecode(token);
  const cliente = decodedToken.clienteId;

  async function fetchAlterMoedasExtra(
    idPlano,
    metodoPag,
    valor,
    DataCriacao,
    customerId,
    dateStripe
  ) {
    try {
      const response = await fetch(`${add_Back}/alter-moedas`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idPlano: idPlano,
          metodoPag: metodoPag,
          valor: valor,
          DataCriacao: DataCriacao,
          idClient: cliente,
          customerId: customerId,
          dateStripe: dateStripe,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("There was an error with the fetch operation:", error);
      throw error;
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    console.log(sessionId);

    const fetchData = async () => {
      if (sessionId) {
        try {
          const {
            status,
            customerEmail,
            payment_method_types,
            amount_total,
            dateStripe,
            customerId,
          } = await fetchSessionStatus(sessionId);
          if (status === "open") {
            window.location.href = "/MoedasExtras"; // Redirecionamento para '/Return'
          } else if (status === "complete") {
            const storedPlanId = sessionStorage.getItem("planId");

            if (storedPlanId) {
              sessionStorage.removeItem("planId");
              const idPlano = storedPlanId; // Substitua pelo valor apropriado
              const metodoPag = payment_method_types; // Substitua pelo valor apropriado
              const valor = amount_total / 100;
              let DataCriacao = new Date(dateStripe * 1000); // Multiplica por 1000 porque Date espera milissegundos

              DataCriacao = DataCriacao.toISOString();

              fetchAlterMoedasExtra(
                idPlano,
                metodoPag,
                valor,
                DataCriacao,
                customerId,
                dateStripe
              )
                .then((data) => {
                  console.log("Success:", data);
                  if (data.moedas) {
                    localStorage.setItem("moedas_total", data.moedas);
                  }
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            }
          }
        } catch (error) {
          console.error("Erro ao buscar status da sessão:", error);
        }
      }
    };

    fetchData();

    const token = Cookies.get("token");
    if (!token) {
      redirectToLogin();
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      const { nick_name } = decodedToken;

      setFormData((prevData) => ({
        ...prevData,
        nick_name,
      }));
      setNickName(nick_name);
    } catch (error) {
      redirectToLogin();
    }

    // const fetchClientInfo = async () => {
    //   try {
    //     const response = await fetch(`${add_Back}/clientes/${cliente}`, {
    //       method: "GET",
    //       headers: {
    //         Authorization: `${token}`,
    //       },
    //     });

    //     if (!response.ok) {
    //       throw new Error(
    //         `Erro ao buscar informações do cliente: ${response.statusText}`
    //       );
    //     }
    //     const data = await response.json();

    //     setNickName(data[0].nick_name);
    //   } catch (error) {
    //     console.error(error.message);
    //   }
    // };
    // if (cliente && token) {
    //   fetchClientInfo();
    // }
  }, []);

  function redirectToLogin() {
    window.location.href = "/Login";
  }

  return (
    <div className="flex flex-col min-h-screen font-sans relative overflow-x-hidden">
      <header className="flex-none">
        <Link
          to="/"
          className="absolute top-0 z-50 left-2 text-white mt-5 ml-4 text-4xl"
        >
          <IoIosArrowBack />
        </Link>
        <div className="absolute top-0 right-2 z-50">
          <NavbarButtons />
        </div>
      </header>
      <main className="flex-grow">
        <div className="pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full max-w-screen left-0 h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto mt-20">
            {" "}
            {/* Margem superior adicionada aqui */}
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <h1 className="text-white font-bold font-title text-5xl">
                  THANK YOU!
                </h1>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4 ">
            <div className="flex flex-wrap justify-center ">
              <div className="w-full md:w-4/12 px-4 text-center mb-8">
                <div
                  className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg"
                  style={{
                    transition: "transform 0.3s ease",
                  }}
                >
                  <div className="p-6">
                    <h2 className={`text-xl p-2 font-title font-bold mb-4`}>
                      Hi {nickName},
                    </h2>
                    <p className="font-paragraph font-normal mb-2">
                      We wanted to take a moment to thank you for purchasing our
                      membership. We are thrilled to have you as part of our
                      community. Your trust and support mean a lot to us. With
                      your new membership, you'll have exclusive access to
                      [membership benefits, such as premium content, priority
                      support, etc.].
                      <br></br>We hope you enjoy all the features and advantages
                      we offer. If you need any assistance or have any
                      questions, please don't hesitate to reach out. <br></br>We
                      are here to help.<br></br>
                      Once again, thank you for becoming a member. We look
                      forward to sharing this journey with you.
                      <br></br>Best regards,
                    </p>
                    <Link to="/Dashboard/initial">
                      <button className="bg-blue-500 font-title text-white font-bold py-2 px-4 rounded mt-4">
                        ACCESS NOW!
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </main>
      <footer className="flex-none">
        <Footer />
      </footer>
    </div>
  );
}
