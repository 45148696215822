import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import MakeImg from "./assets/MakeImg.webp";
import NavbarButtons from "../NavbarButtons";
import DOMPurify from "dompurify";
import { add_Back } from "../../address";
import { Context } from "../../App";
import { useContext } from "react";
import NewPopUpComponent from "../Generic/NewPopUpComponent";

export default function InstallYourStudio() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [subjectErrorMessage, setSubjectErrorMessage] = useState("");
  
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({ ns: ["InstallInYourStudio"] })
  }, [i18n])
  
  const handleNameChange = (e) => {
    const value = e.target.value;
    if (!/^[a-zA-Zà-úÀ-Ú ]*$/.test(value)) {
      setNameErrorMessage("O nome só pode conter letras e espaços.");
    } else {
      setName(value.slice(0, 60));
      setNameErrorMessage("");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9@._-]*$/.test(value)) {
    setEmail(e.target.value);
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setPhone(value.slice(0, 15));
      setPhoneErrorMessage("");
    } else {
      setPhoneErrorMessage("O número de telefone só pode conter dígitos.");
    }
  };

  const handleSubjectChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9à-úÀ-Ú .,?!]*$/.test(value)) {
      setSubject(value.slice(0, 30));
    }
  };

  const [warningMessage, setWarningMessage] = useContext(Context);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const mensagemTemplate = `
      Nome: ${DOMPurify.sanitize(name)}
      Email: ${DOMPurify.sanitize(email)}
      Telefone: ${DOMPurify.sanitize(phone)}
      Mensagem: ${DOMPurify.sanitize(subject)}
      `;

      await axios.post(`${add_Back}/enviarEmail`, {
        assunto: `InstallYourStudio - ${subject}`,
        mensagem: mensagemTemplate,
      });
      setWarningMessage("E-mail sent succesfully!")
    } catch (error) {
      console.error("Erro ao enviar e-mail:", error);
      setWarningMessage("Error while sending e-mail")
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Suspense fallback={<p/>}>
      <NewPopUpComponent
      isClosable={true}/>
    <div className="flex font-title flex-col min-h-screen h-screen">
      <section className="flex flex-1 flex-col lg:flex-row bg-black relative min-h-screen justify-center">
        <div className="w-full lg:w-[55%] hidden lg:block">
          <img
            src={MakeImg}
            alt="Descrição da Imagem"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full lg:w-[45%] flex flex-col justify-center p-5 sm:mt[10%]">
          <div className="absolute top-0 right-0 m-4">
            <NavbarButtons />
          </div>
            <h2 className="font-bold text-3xl md:text-4xl text-white mb-4">
              {i18n.t("Install in your studio")}
            </h2>
            <h4 className="font-bold text-sm text-white mb-4">
              {i18n.t("Install an AI platform inside your studio")}
            </h4>
            <div className="w-full flex flex-col items-center justify-center p-3">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col mx-3 xl:mx-0 text-white text-left w-full lg:w-[80%]"
              >
                <div className="mb-3">
                  <label htmlFor="name" className="block font-paragraph text-xl">
                    {i18n.t("Name: ")}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                    className="w-full white-border-2 text-lg -mt-1 font-paragraph border-b-2 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-2 opacity-70 transition ease-in-out duration-300 text-white"
                    placeholder={i18n.t("Name")}
                    required
                  />
                  {nameErrorMessage && (
                    <p className="text-red-500">{nameErrorMessage}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="block font-paragraph text-lg">
                    {i18n.t("Email: ")}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    className="w-full white-border-2 text-xl -mt-1 font-paragraph border-b-2 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-2 opacity-70 transition ease-in-out duration-300 text-white"
                    placeholder="E-mail"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="subject"
                    className="block font-paragraph text-lg"
                  >
                    {i18n.t("Subject: ")}
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={subject}
                    onChange={handleSubjectChange}
                    className="w-full white-border-2 text-xl -mt-1 font-paragraph border-b-2 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-2 opacity-70 transition ease-in-out duration-300 text-white"
                    placeholder={i18n.t("Subject")}
                    required
                  />
                  {subjectErrorMessage && (
                    <p className="text-red-500">{subjectErrorMessage}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="block font-paragraph text-lg">
                    {i18n.t("Mobile Phone Number:")}
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    className="w-full white-border-2 text-xl -mt-1 font-paragraph border-b-2 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-2 opacity-70 transition ease-in-out duration-300 text-white"
                    placeholder="+"
                    required
                  />
                  {phoneErrorMessage && (
                    <p className="text-red-500">{phoneErrorMessage}</p>
                  )}
                </div>
                <h4 className="font-bold text-sm text-white text-center uppercase mt-4">
                  {i18n.t("The installation of the platform takes two days giving we have available open hours and once its yours to use for as long as you want without extra payment")}
                </h4>
                <div className="flex w-full justify-center">
                <button
                  type="submit"
                  className="bg-white font-title text-black hover:animate-pulse px-5 py-3 text-lg mt-5"
                disabled={isSubmitting}
                >
                  {i18n.t(isSubmitting ? "Sending..." : "SUBMIT")}
                </button>
                </div>
              </form>
            </div>
            <h4 className="font-bold text-sm text-white text-center">
              {i18n.t("Questions")}
            </h4>
        </div>
      </section>
      <Footer footerVersion="light-footer" className="mt-auto" />
    </div>
    </Suspense>
  );
}
