import { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import { useTranslation } from 'react-i18next';
import InfoBox from '../InfoBox';

function Precision({ updateUserChoices, standardColors, professionalColors }) {
  const [precision, setPrecision] = useState('STANDARD');
  const { t } = useTranslation();

  const handleUpdatePrecision = (precision) => {
    setPrecision(precision);
    updateUserChoices("precision", precision);
  };

  useEffect(() => {
    updateUserChoices("precision", precision)
  }, [])

  return (
    <div className='bg-gray-200 py-4'>
      <div className='flex justify-center items-center'>
        <h1 className='flex mb-4 justify-center items-center font-title font-bold text-2xl'>
          {t("PRECISION")}
          <div className='w-[1px] h-[1px] relative'>
            <InfoBox
              text={t("Precision represents how extra finetune an image will receive on the rendering, The Standard gives a high quality output while the Professional sharpen details and upscale slightly the standard output, pay attention on the usage of resources for each generation.")}
              customClass={"absolute top-1/2 -translate-y-1/2 mb-4"} />
          </div>
        </h1>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <button
          className={`lg:w-[33vw] w-full font-title font-bold lg:mx-2 py-2 my-2 
            sm:my-0 mr-0 sm:mr-3 font-large 
            ${precision === 'STANDARD'
              ? `${standardColors.active} border-2 border-black`
              : standardColors.inactive
            } ${standardColors.hover} transition-colors duration-300`}
          onClick={() => handleUpdatePrecision('STANDARD')}
        >
          {t(`STANDARD`) + ` (${standardColors.valor}  RP)`}
        </button>
        <button
          className={`lg:w-[33vw] w-full font-title font-bold lg:mx-2 py-2 
            my-2 sm:my-0 mr-0 sm:mr-3 font-large 
            ${precision === 'PROFESSIONAL'
              ? `${professionalColors.active} border-2 border-black`
              : professionalColors.inactive
            } ${professionalColors.hover} transition-colors duration-300`}
          onClick={() => handleUpdatePrecision('PROFESSIONAL')}
        >
          {t(`PROFESSIONAL`) + ` (${professionalColors.valor}  RP)`}
        </button>
      </div>
    </div>
  );
}

export default Precision;
