import React, { useState, useEffect } from 'react';
import Slider from 'react-slick/lib/slider';
import { Link } from 'react-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../index.css';
import RenderSlides from './RenderSlides.jsx';
import { FaArrowLeft, FaArrowRight, FaArrowDown } from "react-icons/fa";
import BrainstormGen from '../../../assets/BrainstormGen.webp';
import Decorate from '../../../assets/Decorate.webp';
import Sketch2Render from '../../../assets/Sketch2Render.webp';
import CanvasAlchemy from '../../../assets/CanvasAlchemy.webp';
import ReRender from '../../../assets/ReRender.webp';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const CarrosselSolutions = () => {
  const [currentSlide, setCurrentSlide] = useState("BrainstormGen"); // Defina o currentSlide como texto
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={` absolute bottom-2 left-2 z-10`} // Adicionei z-10 para garantir que a seta fique acima de outros elementos
        style={{ ...style, display: "block", left: "10px" }}
        onClick={onClick}
      >
        <FaArrowLeft size={isMobile ? 40 : 55} color="black" className="cursor-pointer" />
      </div>
    );
  }

  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <div className='absolute bottom-2 right-2 z-20' onClick={onClick}>
        <FaArrowRight
          size={isMobile ? 40 : 55}
          color="black"
          className="cursor-pointer"
        />
      </div>
    );
  }

  const slideNames = {
    0: "BrainstormGen",
    1: "Sketch2Render",
    2: "CanvasAlchemy",
    3: "ReRender",
    4: "Decorate",
    5: "FloorPlanner"
  };

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => setCurrentSlide(slideNames[next])
  };

  return (
    <div className='h-full w-full max-w-screen max-h-screen relative overflow-hidden'>
      <div className="slider-container h-full lg:max-h-screen">
        <Slider {...settings}>
          <RenderSlides
            id={currentSlide}
            imageUrl={BrainstormGen}
            title={<p className='text-[6vw] lg:text-[1.5vw]'>BRAINSTORM GEN</p>}
            paragraph1={
              <Trans 
              i18nKey={"GENERATE INFINITE VARIATIONS OF IMAGES CHOOSING ALL POSSIBLE ARCHITECTURE STYLES OR INTERIOR DESIGNS WITH AN EASY TO USE INTERFACE IN SECONDS."}>
                GENERATE 
                <b className='lg:text-[120%] font-title'>
                  {" INFINITE "}
                  </b> 
                  VARIATIONS OF IMAGES CHOOSING ALL POSSIBLE ARCHIETCTURE STYLES OR INTERIOR DESIGNS WITH AN EASY TO USE INTERFACE IN 
                <b className='lg:text-[120%] font-title'> SECONDS</b>.
              </Trans>
            }
            paragraph2={
              <Trans i18nKey={"USE IT TO PRESENT IDEAS OF PROJECTS AT THE BEGGINING OF THE CREATIVE PROCESS, AND ALL THAT CRAFTED BY YOUR IMAGINATION"}>
                USE IT TO PRESENT IDEAS OF PROJECTS AT THE BEGGINING OF THE PROCESS, AND ALL THAT CRAFTED BY 
                <b className='lg:text-[120%] font-title'> 
                  {" YOUR IMMAGINATION "}
                </b>
                  .
                </Trans>
            }
            buttonText={t("START NOW FOR FREE")}
            buttonColor="#ebffb3"
          />
          <RenderSlides
            id={currentSlide}
            imageUrl={Sketch2Render}
            title={<p className='text-[6vw] lg:text-[1.5vw]'>SKETCH 2 RENDER</p>}
            paragraph1={
              <Trans i18nKey={"WITH A SIMPLE PAPER SKETCH OF  AN IDEA OR SCREENSHOT OF YOUR FAVORITE 3D SOFTWARE CREATE PHOTOREALISTIC RENDERINGS WITHIN SECONDS."}>
                WITH A SIMPLE PAPER
                <b className='lg:text-[120%] font-title'>
                  {" SKETCH "}
                  </b> 
                  OF AN IDEA OR A
                  <b className='lg:text-[120%] font-title'> 
                    {" SCREENSHOT "}
                    </b>
                OF YOUR FAVORITE 3D SOFTWARE CREATE PHOTOREALISTIC RENDERINGS WITHIN SECONDS.
              </Trans>
            }
            paragraph2={
              <Trans i18nKey={"USE OUR TECHNOLOGY TO PRESENT EARLY IDEAS FOR YOUR CLIENTS, MAKE MOODBOARDS OR RENDER SEVERAL POSSIBILITIES OF THE SAME PROJECT AND GET TO THE FINAL VERSION OF THE PROJECT UP TO 70% FASTER."}>
                USE OUR TECHNOLOGY TO PRESENT EARLY IDEAS FOR YOUR CLIENTS, MAKE MOODBOARDS OR RENDER SEVERAL POSSIBILITIES OF THE SAME PROJECT AND GET TO THE FINAL VERSION OF THE PROJECT UP TO 
                <b className='lg:text-[120%] font-title'>
                  {" 70% FASTER "}
                  </b>
                  .
              </Trans>
            }
            buttonText={t("START NOW FOR FREE")}
            buttonColor="#ffad7ed7"
          />
          <RenderSlides
            id={currentSlide}
            imageUrl={CanvasAlchemy}
            title={<p className='text-[6vw] lg:text-[1.5vw]'>CANVAS ALCHEMY</p>}
            paragraph1={
              <Trans i18nKey={"CANVAS ALCHEMY ALOW YOU TO COMPLETELY CHANGE A RENDERING, PHOTOGRAPH OR SKETCH WHILE KEEPING ITS ESSENCE INTACT TO TURN IT INTO A MASTERPIECE BEAUTIFUL ENOUGH TO BE ON LOUVRE."}>
                CANVAS ALCHEMY ALOW YOU TO COMPLETELY CHANGE A RENDERING, PHOTOGRAPH OR SKETCH AND TURN IT INTO A 
                <b className='lg:text-[120%] font-title'>
                  {" MASTERPIECE "}
                </b>
                BEAUTIFUL ENOUGH TO BE ON LOUVRE.
              </Trans>
            }
            paragraph2={
              <Trans i18nKey={"THE PROCESS IS PERFECT IF YOU WANT TO CREATE AN UNIQUE AND COMPLETE NEW LOOK, EXPLORE DIFFERENT STYLES OR CREATE THE MOST AMAZING SOCIAL MEDIA MATERIAL, THE BEST IS THAT YOU CAN CREATE NEW DIFFERENT IMAGES WITHOUT SPENDING OURS IN RENDERINGS"}>
                THE PROCESS IS PERFECT IF YOU WANT TO CREATE A COMPLETE 
                <b className='lg:text-[120%] font-title'> 
                  {" NEW LOOK"}
                  </b>
                  , EXPLORE DIFFERENT STYLES OR CREATE THE MOST AMAZING
                <b className='lg:text-[120%] font-title'>
                  {" SOCIAL MEDIA "}
                  </b> 
                MATERIAL THE BEST IS THAT YOU CAN CREATE NEW DIFFERENT IMAGES WITHOUT SPENDING OURS IN RENDERINGS.
                </Trans>
            }
            buttonText={t("START NOW FOR FREE")}
            buttonColor="#f88cefa4"
          />
          <RenderSlides
            id={currentSlide}
            imageUrl={ReRender}
            title={<p className='text-[6vw] lg:text-[1.5vw]'>RE-RENDER</p>}
            paragraph1={
              <Trans i18nKey={"UTILIZE OUR MOST ADVANCED NEURAL NETWORKS TO DECORATE INTERIOR SPACES WITH A FEW CLICKS AND A SINGLE REFERENCE IMAGE."}>
                UTILIZE OUR MOST ADVANCED 
                <b className='lg:text-[120%] font-title'>
                  {" NEURAL NETWORKS "}
                </b>
                TO DECORATE INTERIOR SPACES WITH A FEW CLICKS WITH A SINGLE REFERENCE IMAGE.
              </Trans>
            }
            paragraph2={
              <Trans i18nKey={"IF YOU HAVE AMAZING PROJECTS BUT THE RENDERINGS WHERE NOT GREAT, YOU CAN GIVE THEM A NEW TOUCH WITH THIS TOOL, YOU COULD ALSO USE IT ON REAL PHOTOGRAPHS AND THE AI WILL MAKE IT LOOK INCREDIBLE."}>
                IF YOU HAVE AMAZING PROJECTS BUT THE RENDERINGS WHERE NOT GREAT, YOU CAN GIVE THEM A NEW TOUCH WITH THIS TOOL, YOU COULD ALSO USE IT ON REAL PHOTOGRAPHS AND THE AI WILL MAKE IT LOOK 
                <b className='lg:text-[120%] font-title'>
                  {" INCREDIBLE"}
                  </b>
                  .
              </Trans>
            }
            buttonText={t("START NOW FOR FREE")}
            buttonColor="#8b7ffa9f"
          />
          <RenderSlides
            id={currentSlide}
            imageUrl={Decorate}
            title={<p className='text-[6vw] lg:text-[1.5vw]'>DECORATE</p>}
            paragraph1={
              <Trans i18nKey={"UTILIZE OUR MOST ADVANCED NEURAL NETWORKS TO DECORATE INTERIOR SPACES WITH A FEW CLICKS AND A SINGLE REFERENCE IMAGE."}>
                UTILIZE OUR MOST ADVANCED 
                <b className='lg:text-[120%] font-title'>
                  {" NEURAL NETWORKS "}
                </b>
                TO DECORATE INTERIOR SPACES WITH A FEW CLICKS WITH A SINGLE REFERENCE IMAGE.
              </Trans>
            }
            paragraph2={
              <Trans i18nKey={"THERE ARE NO LIMITS TO THE POSSIBILITIES, CHANGE COLORS, STYLES AND LOOKS, WHAT WOULD TAKE HOURS NOW CAN TAKE LITERALLY SECONDS"}>
                THERE ARE 
                <b className='lg:text-[120%] font-title'>
                  {" NO LIMITS "}
                </b> 
                TO THE POSSIBILITIES, CHANGE COLORS, STYLES AND LOOKS, WHAT WOULD TAKE HOURS NOW CAN TAKE LITERALLY
                <b className='lg:text-[120%] font-title'>
                  {" SECONDS"}
                  </b>
                  .
                  </Trans>
            }
            buttonText={t("START NOW FOR FREE")}
            buttonColor="#ffb3b3"
          />
          {/* <RenderSlides
            id={currentSlide}
            imageUrl={FloorPlanner}
            title={<p className='text-[6vw] lg:text-[1.5vw]'>FLOOR PLANNER</p>}
            paragraph1={
              <p>{t("UTILIZE OUR MOST ADVANCED")} <b className='lg:text-[120%] font-title'>{t("NEURAL NETWORKS")} </b>
                {t("TO DECORATE INTERIOR SPACES WITH A FEW CLICKS WITH A SINGLE REFERENCE IMAGE.")}</p>
            }
            paragraph2={
              <p>{t("THERE ARE")} <b className='lg:text-[120%] font-title'>{t("NO LIMITS")} </b> {t("TO THE POSSIBILITIES, CHANGE COLORS, STYLES AND LOOKS, WHAT WOULD TAKE HOURS NOW CAN TAKE LITERALLY")} <b className='lg:text-[120%] font-title'>{t("SECONDS")}</b>.</p>
            }
            buttonText={t("START NOW FOR FREE")}
            buttonColor="#ffb3b3"
          /> */}
        </Slider>
        <div className='absolute left-0 xl:bottom-0 bottom-3 w-screen max-w-screen flex justify-center'>
          <Link to="default-footer" spy={true} smooth={true} offset={0} duration={750}>
            <FaArrowDown
              size={55}
              color="black"
              className="cursor-pointer min-w-[64px] min-h-[64px] size-[4vw]"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CarrosselSolutions;
