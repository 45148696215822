export function geraPrompt(userChoices, user, nomeImage1, nomeImage2 = null) {


  let prompt_default = `{
  "2": {
    "inputs": {
      "stop_at_clip_layer": -3,
      "clip": [
        "114",
        1
      ]
    },
    "class_type": "CLIPSetLastLayer",
    "_meta": {
      "title": "CLIP Set Last Layer"
    }
  },
  "6": {
    "inputs": {
      "width": 816,
      "height": 1224,
      "batch_size": 1
    },
    "class_type": "EmptyLatentImage",
    "_meta": {
      "title": "Empty Latent Image"
    }
  },
  "21": {
    "inputs": {
      "text": "blur, distortion, jiggly, low quality",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "25": {
    "inputs": {
      "text": "A pristine photograph of a modern bedroom",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "27": {
    "inputs": {
      "seed": [
        "47",
        0
      ],
      "steps": 10,
      "cfg": 1.6,
      "sampler_name": "dpmpp_3m_sde_gpu",
      "scheduler": "sgm_uniform",
      "denoise": [
        "38",
        0
      ],
      "model": [
        "75",
        0
      ],
      "positive": [
        "57",
        0
      ],
      "negative": [
        "21",
        0
      ],
      "latent_image": [
        "6",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "KSampler"
    }
  },
  "28": {
    "inputs": {
      "samples": [
        "27",
        0
      ],
      "vae": [
        "114",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "38": {
    "inputs": {
      "value": 1
    },
    "class_type": "easy float",
    "_meta": {
      "title": "Float"
    }
  },
  "47": {
    "inputs": {
      "seed": 41,
      "🎲 Manual Random Seed": null
    },
    "class_type": "easy seed",
    "_meta": {
      "title": "EasySeed"
    }
  },
  "56": {
    "inputs": {
      "text": "",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "57": {
    "inputs": {
      "conditioning_to": [
        "56",
        0
      ],
      "conditioning_from": [
        "25",
        0
      ]
    },
    "class_type": "ConditioningConcat",
    "_meta": {
      "title": "Conditioning (Concat)"
    }
  },
  "71": {
    "inputs": {
      "image": "p4.jpg",
      "upload": "image"
    },
    "class_type": "LoadImage",
    "_meta": {
      "title": "Load Image"
    }
  },
  "73": {
    "inputs": {
      "sharpen_radius": 1,
      "sigma": 0.35000000000000003,
      "alpha": 2,
      "image": [
        "76",
        0
      ]
    },
    "class_type": "ImageSharpen",
    "_meta": {
      "title": "ImageSharpen"
    }
  },
  "75": {
    "inputs": {
      "weight": 0.6,
      "weight_type": "linear",
      "combine_embeds": "concat",
      "start_at": 0.2,
      "end_at": 0.6,
      "embeds_scaling": "V only",
      "model": [
        "114",
        0
      ],
      "ipadapter": [
        "120",
        0
      ],
      "image": [
        "71",
        0
      ],
      "clip_vision": [
        "119",
        0
      ]
    },
    "class_type": "IPAdapterAdvanced",
    "_meta": {
      "title": "IPAdapter Advanced"
    }
  },
  "76": {
    "inputs": {
      "upscale_by": 1.5,
      "seed": 2,
      "steps": 8,
      "cfg": 1.5,
      "sampler_name": "dpmpp_3m_sde_gpu",
      "scheduler": "sgm_uniform",
      "denoise": 0.48,
      "mode_type": "Linear",
      "tile_width": 1224,
      "tile_height": 1840,
      "mask_blur": 8,
      "tile_padding": 32,
      "seam_fix_mode": "None",
      "seam_fix_denoise": 1,
      "seam_fix_width": 64,
      "seam_fix_mask_blur": 8,
      "seam_fix_padding": 16,
      "force_uniform_tiles": true,
      "tiled_decode": false,
      "image": [
        "28",
        0
      ],
      "model": [
        "114",
        0
      ],
      "positive": [
        "57",
        0
      ],
      "negative": [
        "21",
        0
      ],
      "vae": [
        "114",
        2
      ],
      "upscale_model": [
        "115",
        0
      ]
    },
    "class_type": "UltimateSDUpscale",
    "_meta": {
      "title": "Ultimate SD Upscale"
    }
  },
  "112": {
    "inputs": {
      "filename_prefix": "Canvas_text2img_pro_portrait_V0.2.3",
      "file_type": "JPEG",
      "images": [
        "73",
        0
      ]
    },
    "class_type": "SaveImageExtended",
    "_meta": {
      "title": "Save Image (Extended)"
    }
  },
  "114": {
    "inputs": {
      "ckpt_name": "Arcseed_V0.2.safetensors",
      "key_opt": "",
      "mode": "Auto"
    },
    "class_type": "CheckpointLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Checkpoint Loader (Inspire)"
    }
  },
  "115": {
    "inputs": {
      "model_name": "swift_srgan_2x.pth",
      "key_opt_u": "",
      "mode": "Auto"
    },
    "class_type": "UpscaleLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Upscale Loader (Inspire)"
    }
  },
  "116": {
    "inputs": {
      "control_net_name": "LoraModelCanny.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "117": {
    "inputs": {
      "control_net_name": "LoraModelDepth.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "118": {
    "inputs": {
      "control_net_name": "ttplanetSDXLControlnet_v20Fp16.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "119": {
    "inputs": {
      "clip_name": "clipvis_ViT-H_1.5_.safetensors",
      "key_opt_cv": "",
      "mode": "Auto"
    },
    "class_type": "CLIPVisionLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Clip Loader (Inspire)"
    }
  },
  "120": {
    "inputs": {
      "key": 1
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  },
  "124": {
    "inputs": {
      "seed": 127,
      "denoise_steps": 6,
      "ensemble_size": 3,
      "processing_resolution": 768,
      "scheduler": "LCMScheduler",
      "use_taesd_vae": false,
      "marigold_model": [
        "125",
        0
      ]
    },
    "class_type": "MarigoldDepthEstimation_v2",
    "_meta": {
      "title": "MarigoldDepthEstimation_v2"
    }
  },
  "125": {
    "inputs": {
      "key": 2
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  }
}`;

prompt_default = JSON.parse(prompt_default);
  
const modelos = userChoices["aiModels"] || '';
let modeloFrase = "";

switch (modelos) {
  case "DEFAULT":
    modeloFrase = " ";
    break;
  case "RENDER":
    modeloFrase = "A 3D render of";
    break;
  case "CINEMATIC":
    modeloFrase = "A Cinematic professional shot of";
    break;
  case "PHOTOGRAPHER":
    modeloFrase = "A Photograph of";
    break;
  case "ARCHVIZ":
    modeloFrase = "A Professional architectural rendering of";
    break;
  case "PRISTINE":
    modeloFrase = "A Pristine photograph of";
    break;
}

const anStyles = [
  "arabic", "art deco", "art nouveau", "asian fusion", "eclectic", "english",
  "industrial", "japandi", "oriental", "urban modern"
];
const style = userChoices["style"] || '';

// Se o estilo não for selecionado, styleFrase será uma string vazia.
let styleFrase = '';
if (style) {
  styleFrase = anStyles.includes(style) ? `an ${style}` : `a ${style}`;
}

let whereExterior = userChoices["whereExterior"] || '';
let time = userChoices["time"] || '';
let what = userChoices["what"] || '';
let Style = styleFrase;
let Modes = `${modeloFrase}` || '';
let weather = userChoices["weather"] || '';

if (what === "Square") {
  what = "a public plaza";
}

// Construa o prompt apenas com os parâmetros fornecidos
const promptParts = [
  Modes,
  Style,
  what ? `${what},` : '',  // Adiciona vírgula após what
  time ? `${time},` : '',    // Adiciona vírgula após time
  whereExterior ? `${whereExterior},` : '',
  weather
].filter(Boolean).map(part => part.trim()); // Remove valores falsos e trim

// Junta os elementos em uma única string
const promptText = promptParts.join(' ').replace(/\s{2,}/g, ' ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

// Atualiza o texto no nó 20
prompt_default["25"]["inputs"]["text"] = promptText.trim();

    const aiImaginationMap = {
      5: 2.1,
      4: 1.8,
      3: 1.5,
      2: 1.25,
      1: 1.0
    };

  // Recupera a escolha do usuário para AI Imagination Range
  const userAIImaginationChoice = userChoices["AIImmaginationRange"];

  // Define o valor de cfg de acordo com a escolha do usuário
  const selectedAIImaginationValue = aiImaginationMap[userAIImaginationChoice];

  // Aplica o valor ao prompt_default para cfg
  prompt_default["27"]["inputs"]["cfg"] = selectedAIImaginationValue;


  const inputIpWeightMap = {
    1: 0.3,
    2: 0.4,
    3: 0.5,
    4: 0.6,
    5: 0.7
  };

  // Recupera a escolha do usuário para Input Depth Range
  const userInputIpWeightChoice = userChoices["StyleTransferRange"];

  // Define o valor de CN1 de acordo com a escolha do usuário
  const selectedInputIpWeightValue = inputIpWeightMap[userInputIpWeightChoice];

  // Aplica o valor ao prompt_default para CN1 (assumindo que "88" corresponde a CN1 para Input Depth)
  prompt_default["75"]["inputs"]["weight"] = selectedInputIpWeightValue;


  // "weight": 0.6,
  //     "weight_type": "linear",
  //     "combine_embeds": "concat",
  //     "start_at": 0.2,
  //     "end_at": 0.6,

  const shapeStrengthMap = {
    1: { "start": 0.3, "end": 0.5 },
    2: { "start": 0.25, "end": 0.5 },
    3: { "start": 0.2, "end": 0.6 },
    4: { "start": 0.1, "end": 0.8 },
    5: { "start": 0.0, "end": 0.9 }
  };



  // Recupera as escolhas do usuário para ShapeStrength e ShapeExposure
  const userShapeStrengthChoice = userChoices["StyleExposureRange"];


  // Define os valores de acordo com a escolha do usuário
  const selectedShapeStrength = shapeStrengthMap[userShapeStrengthChoice];

  // Aplica os valores ao prompt_default para CN1 (88) e CN2 (102)
  prompt_default["75"]["inputs"]["start_at"] = selectedShapeStrength["start"];
  prompt_default["75"]["inputs"]["end_at"] = selectedShapeStrength["end"];





  prompt_default["27"]["inputs"]["seed"] = `${userChoices["seed"]}`;

  prompt_default["71"]["inputs"]["image"] = `${nomeImage1}`;

  if (nomeImage2) {
    prompt_default["369"]["inputs"]["image"] = `${nomeImage2}`;
  }

  prompt_default["112"]["inputs"]["filename_prefix"] = `${user}`;



  const prompt = prompt_default;
  //console.log(prompt);
  return prompt;
}
