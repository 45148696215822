import React, { useState, useEffect } from "react";
import { ReactComponent as ArcLogo } from '../../assets/transparentLogo.svg';
import Interior from '../../assets/indexTop1.webp';
import Room from '../../assets/indexTop2.webp';
import fancyBuilding from '../../assets/indexTop3.webp';
import artBuilding from '../../assets/indexTop4.webp';
import { useTranslation } from "react-i18next";
import { Link } from 'react-scroll';
import { FaArrowDown } from "react-icons/fa";
import NavbarButtons from "../NavbarButtons";
import { Link as LinkRouter } from "react-router-dom";
 
export default function FirstScreen() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest(".menu-container")) {
      setIsMenuOpen(false);
    }
  };

  const { t } = useTranslation();

  React.useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  // Vetor de imagens
  const imagens = [Interior, Room, fancyBuilding, artBuilding];

  // Estado para controlar a imagem atual
  const [imagemAtual, setImagemAtual] = useState(0);

  // Função para avançar para a próxima imagem
  const proximaImagem = () => {
    setImagemAtual((prevIndex) => (prevIndex + 1) % imagens.length);
  };

  // Efeito para trocar de imagem a cada 4 segundos
  useEffect(() => {
    const intervalId = setInterval(proximaImagem, 3000);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []); // O segundo parâmetro vazio assegura que o efeito só é executado uma vez

  const wallpaperEffects = (imagemAtual) => {
    switch (imagemAtual) {
      case 0: //superior esquerdo para superior direito
        return 'transform translate-x-[8%] translate-y-[8%] transition-transform duration-[3s] ease-in-out';

      case 1:  //superior direito para inferior direito
        return 'transform -translate-x-[8%] translate-y-[8%] transition-transform duration-[3s] ease-in-out';

      case 2:  //inferior direito para inferior esquerdo
        return 'transform -translate-x-[8%] -translate-y-[8%] transition-transform duration-[3s] ease-in-out';

      case 3:  //inferior esquerdo para superior esquerdo
        return 'transform translate-x-[8%] -translate-y-[8%] transition-transform duration-[3s] ease-in-out';

      default:
        return 'transform -translate-x-[8%] -translate-y-[8%] transition-transform duration-[3s] ease-in-out';
    }
  };


  return (
    <section className="h-screen overflow-hidden max-h-screen 
    min-h-screen mb-0 pb-0 max-w-[100vw] no-horizontal-scrollbar">
      <div className="flex absolute bottom-0 z-20 overflow-hidden max-w-screen w-full justify-center">
        <Link to="uncolored-images"
          smooth={true}
          offset={0}
          duration={750}>
          <FaArrowDown
            size={55}
            color="white"
            className="opacity-70 cursor-pointer mb-3"
          />
        </Link>
      </div>
      <div className="absolute w-full overflow-hidden top-0 flex justify-end items-right z-30">

        <div className="flex-col  ">

          <div
            id="buttons-flex"
            className="flex justify-center mr-4 items-center mt-4"
          >

            <NavbarButtons className="" />

          </div>

          <div className="flex justify-center mr-2">
            <Link 
            to="contact-forms" 
            spy={true} 
            smooth={true} 
            offset={0} 
            duration={750}
            className='vertical-text font-paragraph text-2xl 
            lg:text-3xl cursor-pointer
            group leading-6 flex
            transition duration-300 relative'>
              CNTCT
            <span className="h-0 group-hover:h-full block absolute
            max-h-fit transition-all 
            text-transparent duration-500 w-0.5 bg-white">
              CTNCT
            </span>
            </Link>
            <LinkRouter
            to="/CommunityGallery"
            className='vertical-text font-paragraph text-2xl 
            lg:text-3xl cursor-pointer
            group leading-6 flex
            transition duration-300 relative'>
              {t("GALLERY")}
              <span className="h-0 group-hover:h-full block absolute
            max-h-fit transition-all 
            text-transparent duration-500 w-0.5 bg-white">
              {t("GALLERY")}
            </span>
            </LinkRouter>
            <Link 
            to="solutions" 
            spy={true} 
            smooth={true} 
            offset={0} 
            duration={750}
            className='vertical-text font-paragraph text-2xl 
            lg:text-3xl cursor-pointer
            group leading-6 flex
            transition duration-300 relative'>
              {t("SOLUTIONS")}
            <span className="h-0 group-hover:h-full block absolute
            max-h-fit transition-all 
            text-transparent duration-500 w-0.5 bg-white">
              {t("SOLUTIONS")}
            </span>
            </Link>
          </div>

        </div>
      </div>
      <div className="absolute w-full overflow-hidden flex items-center justify-center h-full">
        <img
          src={imagens[imagemAtual]}
          className={`absolute w-full animate-fade animate-duration-500 animate-once scale-[2] lg:scale-[1.4] ${wallpaperEffects(imagemAtual)} overflow-hidden min-h-screen min-w-screen top-0 left-0 object-cover h-auto`} 
        />
        <div className="border-[100vh] border-black border-solid opacity-70">
        <ArcLogo fill="black" className="w-[47vw] min-w-[310px] z-10 inline" />
        </div>
      </div>

    </section >
  );
}
