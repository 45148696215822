import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Format({ updateUserChoices, customClass }) {
  const [cropOptions, setCropOptions] = useState({ width: 300, height: 300 });
  const [outputSize, setOutputSize] = useState({ width: 1024, height: 1024 });
  const [userChoice, setUserChoice] = useState((300, 300, 1024, 1024, 'square'));
  const { t } = useTranslation();

  const updateCropOptions = (width, height, outputWidth, outputHeight, userChoice) => {
    setCropOptions({ width, height });
    setOutputSize({ width: outputWidth, height: outputHeight });
    setUserChoice(userChoice);
    updateUserChoices("imageCropper", userChoice);
  };

  useEffect(() => {
    updateUserChoices("imageCropper", userChoice);
  }, [])

  return (
    <div className={`flex w-full max-w-screen justify-center`}>
      <div className='bg-black flex justify-center items-center h-full lg:w-[34rem] w-full'>
        <div className='w-[96%] h-[96%] flex flex-col'>
          <h2 className='text-white font-title font-bold text-3xl mt-2'>{t("FORMAT")}</h2>
          <div className='md:h-[10rem] h-[35vw] w-full px-4 relative'>
            <div className='absolute left-[5%] top-3'>
            <div
              id="square-option"
              onClick={() => updateCropOptions(300, 300, 1024, 1024, 'square')}
              className={`flex flex-col cursor-pointer justify-between items-center ease-in-out duration-300 transition-all bg-black
                ${userChoice === "square" ? "outline outline-1 outline-white p-2" : "outline outline-1 outline-transparent p-2"}`}
            >
              <div className='size-[15vw] md:size-16 border-2 border-white border-dashed' />
              <p className='text-white font-title font-bold text-[3.5vw] md:text-lg mt-3'>{t("SQUARE")}</p>
            </div>
            </div>
            <div className='absolute left-1/2 -translate-x-1/2 top-3'>
            <div id="landscape-option" 
            onClick={() => updateCropOptions(400, 300, 1344, 1024, 'landscape')} 
            className={`flex flex-col cursor-pointer justify-between items-center ease-in-out duration-300 transition-all bg-black
            ${userChoice === "landscape" ? "outline outline-1 outline-white p-2" : "outline outline-1 outline-transparent p-2"}`}>
              <div className='md:w-20 w-[18.75vw] md:h-16 h-[15vw] border-2 border-white border-dashed' />
              <p className='text-white font-title font-bold text-[3.5vw] md:text-lg mt-3'>{t("LANDSCAPE")}</p>
            </div>
            </div>
            <div className='absolute right-[5%] top-1'>
            <div id="portrait-option" 
            onClick={() => updateCropOptions(300, 400, 1024, 1344, 'portrait')} 
            className={`flex flex-col cursor-pointer justify-between items-center px-2 pt-4 pb-2 outline 
              ease-in-out duration-300 transition-all outline-solid outline-1 bg-black
              ${userChoice === "portrait" ? "outline-white" : " outline-transparent"}`}>
              <div className='md:w-20 md:h-16 w-[18.75vw] h-[15vw] rotate-90 border-2 border-white border-dashed' />
              <p className='text-white font-title font-bold text-[3.5vw] md:text-lg mt-3'>{t("PORTRAIT")}</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Format;