import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";

export default function PopUpComponent() {
  const [isVisible, setIsVisible] = useState(true);
  const [isRendering, setIsRendering] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setIsRendering(false);
      }, 400);
    }
  }, [isVisible])

  const handleButtonClick = (buttonId) => {
    if (buttonId === 0) {
      localStorage.setItem('cookiesAccepted', 'all');
    } else {
      localStorage.setItem('cookiesAccepted', 'essential');
    }
    setIsVisible(false);
  }
  
  return (
    (isRendering && (
      <div className={`lg:w-[65%] w-[95%] bg-white
    lg:max-h-[12%] max-h-[15%] opacity-70
     delay-300  text-center fixed bottom-2 z-30 left-1/2 -translate-x-1/2
    flex justify-between transition-opacity ease-in-out duration-300
    ${isVisible ? "opacity-100" : "opacity-0"}`}>
        <p className="font-title my-[1%] ml-[1%] leading-tight font-bold md:text-[80%] text-[60%] w-[70%] text-start leading-5 overflow-auto custom-scrollbar">
          {i18n.t("Utilizamos cookies para facilitar a sua navegação, analisar, personalizar conteúdos e exibir publicidade personalizada com base em sua atividade online. Ao navegar na Arcseed, você está ciente que podemos coletar e usar essas informações. Cookies essenciais representam cookies que auxiliam e agilizam sua navegação em nossa plataforma enquanto cookies não essenciais nos fornecem informações para melhoramento da plataforma e marketing.")}
        </p>
        <div className="w-[40%] flex flex-col border-l-[1px] 
      border-solid border-black ml-[5%] my-[4px] justify-center items-center">
          <button
            onClick={() => handleButtonClick(0)}
            className="bg-black w-[80%] h-[50%] text-white 
        transition-colors ease-in-out duration-300 border border-solid
        text-[50%] lg:text-[80%] font-title uppercase hover:bg-white hover:border-black
        hover:text-black">
          {i18n.t("Accept all cookies")}
          </button>
          <button
            onClick={() => handleButtonClick(1)}
            className="bg-black w-[80%] h-[50%] text-white 
        transition-colors ease-in-out duration-300 border border-solid
        text-[50%] lg:text-[80%] font-title uppercase hover:bg-white hover:border-black
        hover:text-black">
          {i18n.t("Accept only essential cookies")}
          </button>
        </div>
      </div>
    ))
  )
}