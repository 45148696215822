import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importa useNavigate ao invés de useHistory
import "./index.css";
import "./App.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io"; // Importando o ícone
import { add_Back } from "../../address";
import NewPopUpComponent from "../Generic/NewPopUpComponent";
import { Context } from "../../App";
import { useContext } from "react";
import DOMPurify from "dompurify";

export default function Login() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Utiliza useNavigate ao invés de useHistory
  const handleLinkClick = () => {
    window.location.href = "/Register";
  };

  const [warningMessage, setWarningMessage] = useContext(Context);

  const handleOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest(".menu-container")) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [credentialError, setCredentialError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleEmailChange = (e) => {
    const sanitizedEmail = DOMPurify.sanitize(e.target.value);
    setEmail(sanitizedEmail);
  };

  const handlePasswordChange = (e) => {
    const sanitizedPassword = DOMPurify.sanitize(e.target.value);
    setSenha(sanitizedPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    //localStorage.clear();
    try {
      const response = await fetch(`${add_Back}/usuarios/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, senha, rememberMe }),
      });

      if (!response.ok) {
        const errorData = await response.json();

        // Diferencia as mensagens com base no status da resposta
        if (response.status === 401) {
          throw new Error("Invalid credentials.");
        } else if (response.status >= 500) {
          throw new Error("Server error. Please try again later.");
        } else {
          throw new Error(errorData.message || "Erro ao fazer login.");
        }
      }

      const data = await response.json();
      const {
        token,
        clientId,
        imagem_perfil,
        telefone,
        plano,
        id_plan,
        moedas_total,
      } = data;

      // Verifique se os dados estão corretamente extraídos
      console.log("Token:", token);
      console.log("ClientId:", clientId);
      console.log("Imagem de perfil:", imagem_perfil);
      console.log("Telefone:", telefone);
      console.log("Plano:", plano);
      console.log("ID do Plano:", id_plan);
      console.log("Moedas Totais:", moedas_total);

      // Armazene o token e o clientId nos cookies
      Cookies.set("token", token, {
        secure: true,
        sameSite: "strict",
        path: "/",
      });
      // Cookies.set("clientId", clientId, {
      //   secure: true,
      //   sameSite: "strict",
      //   path: "/",
      // });

      // Limpe manualmente os itens específicos do localStorage
      localStorage.removeItem("imagem_perfil");
      localStorage.removeItem("telefone");
      localStorage.removeItem("plano");
      localStorage.removeItem("id_plan");
      localStorage.removeItem("moedas_total");

      // Armazene a imagem de perfil, telefone, plano, id_plan e moedas_total no localStorage
      localStorage.setItem("linguagem", navigator.language);
      localStorage.setItem("imagem_perfil", imagem_perfil);
      localStorage.setItem("telefone", telefone);
      localStorage.setItem("plano", plano);
      localStorage.setItem("id_plan", id_plan);
      localStorage.setItem("moedas_total", moedas_total);

      // Verifique se os cookies e o localStorage foram configurados corretamente
      console.log("Token armazenado no cookie:", Cookies.get("token"));
      console.log("ClientId armazenado no cookie:", Cookies.get("clientId"));
      console.log(
        "Imagem de perfil armazenada no localStorage:",
        localStorage.getItem("imagem_perfil")
      );
      console.log(
        "Telefone armazenado no localStorage:",
        localStorage.getItem("telefone")
      );
      console.log(
        "Plano armazenado no localStorage:",
        localStorage.getItem("plano")
      );
      console.log(
        "ID do Plano armazenado no localStorage:",
        localStorage.getItem("id_plan")
      );
      console.log(
        "Moedas Totais armazenadas no localStorage:",
        localStorage.getItem("moedas_total")
      );
      setWarningMessage("Login succesfull!");
      setLoggedIn(true);
      navigate("/Dashboard/initial");
    } catch (error) {
      setWarningMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    document.title = "Login - Arcseed AI";
    return () => {
      document.title = "Arcseed AI";
    };
  }, []);

  // Armazenar a última página visitada antes de redirecionar para o login
  useEffect(() => {
    if (document.referrer && document.referrer !== "") {
      // lastVisitedPageRef.current = document.referrer;
    }
  }, []);

  return (
    <section className="max-h-screen h-screen bg-black flex flex-col justify-center relative">
      <NewPopUpComponent isClosable={true} />
      <Link className="absolute top-[4%] left-[4%]" to="/">
        {/* Usando o ícone para voltar à página inicial */}
        <button className="rounded-full bg-gray-900 text-white p-2">
          <IoIosArrowBack size={24} />
        </button>
      </Link>

      <div className="flex items-center justify-center">
        <div className="relative flex flex-col md:w-[45%] lg:w-[30%] break-words shadow-lg  bg-zinc-100 border-0">
          {/* <div className="rounded-t mb-0 px-6 py-6">
              <div className="text-center mb-3">
                <h6 className="text-blueGray-500 font-title text-[111%] font-bold">
                  {t("LOGIN WITH")}
                </h6>
              </div>
              <div className="btn-wrapper text-center">
                <button
                  className="bg-white font-paragraph text-[100%] active:bg-blueGray-50 text-blueGray-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center ease-linear transition-all duration-150"
                  type="button"
                >
                  <img
                    alt="..."
                    className="w-5 mr-1"
                    src={require("../../assets/google.svg").default}
                  />
                  Google
                </button>
              </div>
              <hr className="mt-6 border-b-1 border-black" />
            </div> */}

          <div className="flex-auto text-left p-6">
            <div className="text-blueGray-400 text-center mb-3 font-bold">
              <small className="font-title text-[111%]">
                {t("LOG IN WITH CREDENTIALS")}
              </small>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="relative w-full mb-3">
                <label
                  className="relative w-full font-paragraph text-left text-xl mb-3"
                  htmlFor="grid-password"
                >
                  E-mail
                </label>
                <input
                  id="emailInput"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="border-0 p-2 placeholder-blueGray-300 
                        text-blueGray-600 bg-white rounded text-lg shadow 
                        focus:outline-none focus:ring w-full ease-linear 
                        transition-all duration-150 font-paragraph font-bold"
                  placeholder="E-mail"
                  required
                />
              </div>

              <div className="relative w-full mb-3">
                <label className="font-paragraph text-xl">
                  {t("Password")}
                </label>
                <input
                  id="passwordInput"
                  type="password"
                  value={senha}
                  onChange={handlePasswordChange}
                  className="border-0 p-2 placeholder-blueGray-300 font-bold font-paragraph text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={t("Password")}
                  required
                />
              </div>
              <div className="text-center">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span className="ml-2 font-paragraph text-[110%] font-semibold text-blueGray-600">
                    {t("Remember me")}
                  </span>
                </label>
              </div>

              <div className="text-center mt-6">
                <button
                  className="bg-slate-300 font-title bg-opacity-70 hover:bg-opacity-100 text-black active:bg-blueGray-600 text-[100%] font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? t("SIGNING IN...")
                    : t("Sign In".toUpperCase())}
                </button>
              </div>
              <span className="text-red-500">{credentialError}</span>
            </form>
          </div>

          <div className="flex justify-between pt-2 relative bg-black">
            <Link to="/ForgotPasswordEmail">
              <p className="font-paragraph hover:underline text-[130%] text-white mx-2">
                {t("Forgot password")}
              </p>
            </Link>

            <Link onClick={handleLinkClick}>
              <p className="font-paragraph hover:underline text-[130%] text-white mx-2">
                {t("Create new account")}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
