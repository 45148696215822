import { React, useState } from "react";
import { useEffect } from "react";
import ImageUploader from "../components/RenderComponents/ImageUploader";
import ImageCropper from "../components/RenderComponents/ImageCropper";
import AIModels from "../components/RenderComponents/AIModels";
import Seed from "../components/RenderComponents/Seed";
import Type from "../components/RenderComponents/Type";
// import Floors from "../compone             nts/RenderComponents/Floors";
// import Where from "../components/RenderComponents/WhereExterior";
// import What from "../components/RenderComponents/WhatExterior";
// import Weather from "../components/RenderComponents/Weather";
// import Time from "../components/RenderComponents/Time";
// import Style from "../components/RenderComponents/Style";
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent.jsx";
import weatherJSON from "../components/RenderComponents/renderParamJSONConfig/weather.json";
import whereExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whereExteriors.json";
import timeExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeExteriors.json";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import styleExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleExteriors.json";
import whatInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
import whatExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatExteriors.json";
import ColorPalette from "../components/RenderComponents/ColorPalette";
import Materials from "../components/RenderComponents/Materials";
import Visibility from "../components/RenderComponents/Visibility";
import RenderButton from "../components/RenderComponents/RenderButton";
import Navbar, {navbarHeight} from "../components/Navbar";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange";
import OriginalResemblance from "../components/RenderComponents/OriginalResemblance.jsx";
import Footer from "../components/Footer";
import Originality from "../components/RenderComponents/Originality.jsx";
import Cookies from "js-cookie";
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import { useTranslation } from "react-i18next";
// import WhatInteriors from "../components/RenderComponents/WhatInterior";
// import WhatExteriors from "../components/RenderComponents/WhatExterior";
import { jwtDecode } from "jwt-decode";
import Precision from "../components/RenderComponents/Precision.jsx";
import ShapeStrength from "../components/RenderComponents/ShapeStrength.jsx";
//import { geraPrompt } from '../apiComfy/Upscale/ReRender.js';
import { geraPrompt as geraPromptLandscapePro } from "../apiComfy/ReRender/ReRender_Pro_Landscape.js";
import { geraPrompt as geraPromptSquarePro } from "../apiComfy/ReRender/ReRender_Pro_Square.js";
import { geraPrompt as geraPromptPortraitPro } from "../apiComfy/ReRender/ReRender_Pro_Portrait.js";

import { geraPrompt as geraPromptLandscapeStandart } from "../apiComfy/ReRender/ReRender_Standart_Landscape.js";
import { geraPrompt as geraPromptSquareStandart } from "../apiComfy/ReRender/ReRender_Standart_Square.js";
import { geraPrompt as geraPromptPortraitStandart } from "../apiComfy/ReRender/ReRender_Standart_Portrait.js";
import ShapeGuidance from "../components/RenderComponents/ShapeGuidance.jsx";
import Accordion from "../components/RenderComponents/Accordion.jsx";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent.jsx";

export default function ReRenderPage() {
  const standardColors = {
    active: "bg-[#fffe80] text-black",
    inactive: "bg-[#ffffb3] text-black",
    hover: "hover:bg-[#fffe80] hover:text-black",
    valor: 2,
  };

  const professionalColors = {
    active: "bg-[#fcac7f] text-black",
    inactive: "bg-[#fdd7bc] text-black",
    hover: "hover:bg-[#fcac7f] hover:text-black",
    valor: 3,
  };

  // Função para verificar se o token está presente e válido
  const token = Cookies.get("token");
  function verificarToken() {

    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const [userChoices, setUserChoices] = useState({
    imageUploader: null,
    imageCropper: null,
    aiModels: null,
    seed: null,
    type: null,
    whereExterior: null,
    what: null,
    weather: null,
    time: null,
    style: "Modern",
    colorPalette: null,
    materials: null,
    effects: null,
    visibility: "PUBLIC",
    precision: "STANDARD",
    AIImmaginationRange: "2",
    OriginalityRange: "0.9",
    ShapeGuidanceRange: "2",
    ShapeStrengthRange: "1",
    OriginalResemblanceRange: "2",
  });

  const { t } = useTranslation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Verifica o tamanho da tela e define o estado isSmallScreen
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Defina a largura limite para telas pequenas
    };

    // Executa a verificação no carregamento e quando o tamanho da tela muda
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Remove o event listener ao desmontar o componente
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const updateUserChoices = (category, choice) => {
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };
  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId; 
  const convertToJson = () => {
   // const user = Cookies.get("clientId"); //pega o user da sessão

    const nomeImage = `${user}_${Date.now()}.png`;

    sessionStorage.setItem("nomeImage", nomeImage);

    let geraPrompt;

    if (userChoices.precision === "PROFESSIONAL") {
      if (userChoices.imageCropper === "landscape") {
        geraPrompt = geraPromptLandscapePro(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "portrait") {
        geraPrompt = geraPromptPortraitPro(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "square") {
        geraPrompt = geraPromptSquarePro(userChoices, user, nomeImage);
      }
    } else {
      if (userChoices.imageCropper === "landscape") {
        geraPrompt = geraPromptLandscapeStandart(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "portrait") {
        geraPrompt = geraPromptPortraitStandart(userChoices, user, nomeImage);
      } else if (userChoices.imageCropper === "square") {
        geraPrompt = geraPromptSquareStandart(userChoices, user, nomeImage);
      }
    }

    const saida = geraPrompt;
    console.log(userChoices);
    sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));
  };

  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };

  useEffect(() => {
    /* Função responsável pelo título das páginas */
    document.title = "Re-Render - Arcseed AI";
    return () => {
      document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
    };
  }, []);

  const [openAccordion, setOpenAccordion] = useState(null);
  const [openAccordion1, setOpenAccordion1] = useState(null);

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });
  }, [i18n]);

  useEffect(() => {
    setOpenCustomization(false);
    setopenFinetuning(false);
  }, [userChoices.type]);

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };

  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  const [isInterior, setIsInterior] = useState(null);

  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
    window.innerWidth < 1024 ? 
      (
        <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
          <div className="flex">{items}</div>
          <div className="flex">{items}</div>
        </div>
      )
      :
      ( 
        <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
      )
    )
  };

  useEffect(() => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
  }, [])

  const handleTypeChoose = () => {
    if (userChoices.type !== isInterior) {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        what: null,
        whatInterior: null,
        whereExterior: null,
        time: null,
        weather: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        effects: null,
        visibility: "PUBLIC",
        precision: "STANDARD",
        AIImmaginationRange: "2",
        OriginalityRange: "0.9",
        ShapeGuidanceRange: "2",
        ShapeStrengthRange: "1",
        OriginalResemblanceRange: "2",
      }));
      setIsInterior(userChoices.type);
    }

    if (userChoices.type === "INTERIORS") {
      return (
        <div
          className={`ease-in-out transition-all duration-300
        ${userChoices.type === "INTERIORS" ? "opacity-100" : "opacity-0"}`}
        >
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatInteriorsJSON}
          isOpen={true}
          />
          {/* <WhatInteriors
            isOpen={openAccordion1 === "style"}
            onToggle={() => handleAccordionToggle1("style")}
            updateUserChoices={updateUserChoices}
          /> */}
          <div className="w-full justify-center items-center flex">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleInteriorsJSON}
                isOpen={(openAccordion === "styleInteriors")}
                onToggle={() => handleAccordionToggle("styleInteriors")}
                />
                </div>
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeInteriorsJSON}
                isOpen={(openAccordion === "timeInteriors")}
                onToggle={() => handleAccordionToggle("timeInteriors")}
                />
            </div>
            )
            :
            (
            <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleInteriorsJSON}
                isOpen={(openAccordion === "styleInteriors")}
                onToggle={() => handleAccordionToggle("styleInteriors")}
                />
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeInteriorsJSON}
                isOpen={(openAccordion1 === "timeInteriors")}
                onToggle={() => handleAccordionToggle1("timeInteriors")}
                />
                {/* <Time
                  isOpen={openAccordion === "time"}
                  onToggle={() => handleAccordionToggle("time")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="w-full flex justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion
          isOpen={openFinetuning}>
      <div className="w-screen lg:px-[10vw] flex flex-col lg:flex-row justify-center">
        <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
        <div className="my-4">
          <AIImmaginationRange
          initialValue={userChoices.AIImmaginationRange}
            updateUserChoices={updateUserChoices} />
        </div>
        <ShapeGuidance
          updateUserChoices={updateUserChoices} />
        </div>
      <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
        <div className="my-4">
        <Originality 
        initialValue={"0.9"}
        updateUserChoices={updateUserChoices}/>
        </div>
        <ShapeStrength updateUserChoices={updateUserChoices}/>
      </div>
      </div>
      </Accordion>
      <Visibility updateUserChoices={updateUserChoices} />
      <Precision updateUserChoices={updateUserChoices} 
        standardColors={standardColors}
        professionalColors={professionalColors}/>
      <RenderButton 
      onRenderButtonClick={convertToJson} 
      userChoices={userChoices} 
      onMoedasUpdate={handleMoedasUpdate}/>
        </div>
      );
    } else if (userChoices.type === "EXTERIORS") {
      return (
        <div
          className={`ease-in-out transition-opacity duration-300
        ${userChoices.type === "EXTERIORS" ? "opacity-100" : "opacity-0"}`}
        >
          {/* <Floors updateUserChoices={updateUserChoices} /> */}
          {/* <WhatExteriors updateUserChoices={updateUserChoices} /> */}
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatExteriorsJSON}
          isOpen={true}
          />
          <div className="flex w-full justify-center items-center">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="mb-2">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={styleExteriorsJSON}
              isOpen={openAccordion === "styleExteriors"}
              onToggle={() => handleAccordionToggle("styleExteriors")}
              />
              </div>
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={openAccordion === "whereExteriors"}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                </div>
                {/* <Where
                  isOpen={openAccordion1 === "where"}
                  onToggle={() => handleAccordionToggle1("where")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={openAccordion === "timeExteriors"}
                onToggle={() => handleAccordionToggle("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExteriors"}
                  onToggle={() => handleAccordionToggle("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherJSON}
                isOpen={openAccordion === "weatherExteriors"}
                onToggle={() => handleAccordionToggle("weatherExteriors")}
                />
                {/* <Weather
                  isOpen={openAccordion === "weatherExteriors"}
                  onToggle={() => handleAccordionToggle("weatherExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
            </div>
            )
            :
            (
            <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
              <div className="mb-2">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={styleExteriorsJSON}
              isOpen={openAccordion === "styleExteriors"}
              onToggle={() => handleAccordionToggle("styleExteriors")}
              />
              </div>
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={openAccordion === "whereExteriors"}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                {/* <Where
                  isOpen={openAccordion1 === "where"}
                  onToggle={() => handleAccordionToggle1("where")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={openAccordion1 === "timeExteriors"}
                onToggle={() => handleAccordionToggle1("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExteriors"}
                  onToggle={() => handleAccordionToggle("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherJSON}
                isOpen={openAccordion1 === "weatherExteriors"}
                onToggle={() => handleAccordionToggle1("weatherExteriors")}
                />
                {/* <Weather
                  isOpen={openAccordion === "weatherExteriors"}
                  onToggle={() => handleAccordionToggle("weatherExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex w-full justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion
          isOpen={openFinetuning}>
      <div className="w-screen lg:px-[10vw] flex flex-col lg:flex-row justify-center">
        <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
        <div className="my-4">
          <AIImmaginationRange
          initialValue={userChoices.AIImmaginationRange}
            updateUserChoices={updateUserChoices} />
        </div>
          <ShapeGuidance
            updateUserChoices={updateUserChoices} />
        </div>
      <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
        <div className="my-4">
        <Originality 
        initialValue={"0.9"}
        updateUserChoices={updateUserChoices}/>
        </div>
        <ShapeStrength updateUserChoices={updateUserChoices}/>
      </div>
      </div>
      <div className="lg:flex lg:justify-center lg:w-full mt-4">
        <OriginalResemblance 
        initialValue={"3"}
        updateUserChoices={updateUserChoices}/>
      </div>
      </Accordion>
      <Visibility updateUserChoices={updateUserChoices} />
      <Precision updateUserChoices={updateUserChoices}
        standardColors={standardColors}
        professionalColors={professionalColors} />
      <RenderButton 
      onRenderButtonClick={convertToJson} 
      userChoices={userChoices} />
        </div>
      );
    } else if (userChoices.type === null) {
      return (
        <div className="flex w-full max-w-screen my-2 justify-center">
          <p className="font-paragraph px-6 lg:px-0 text-2xl">
            {t(
              "Tip: select the ambient type to access the other inputs!"
            )}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="overflow-hidden">
      <NewPopUpComponent
      isClosable={true}/>
      <Navbar
        title="RE-RENDER"
        userChoices={userChoices}
        description="Upload a render you did or any image to make it look better, this is perfect for old renders with bad quality, they will look stunning now! if you are an advanced user click here for the advanced toolset or turn the advanced toolset on your settings page here ,there all the generative pages will be in one place for fast editing and access."
        showPoints={true}
        moedas={moedas}
      />
      <div 
      style={{
        marginTop: navbarHeight
      }}>
        <ImageUploader
          updateUserChoices={updateUserChoices}
          subtitle={
            "You can take a screenshot (PrtScr no teclado) and paste it here with Ctrl+v, to crop it below."
          }
          title={"Drop the image or screenshot here"}
        />
      </div>
      <ImageCropper
        image={sessionStorage.getItem("imageUploader")}
        updateUserChoices={updateUserChoices}
      />
      <div className="flex lg:flex-row flex-col w-full py-8 items-center justify-center max-w-screen">
        <div className="w-1/3">
          <AIModels
            verticalSet={isSmallScreen}
            updateUserChoices={updateUserChoices}
          />
        </div>
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
        <div className="w-1/3">
          <Seed updateUserChoices={updateUserChoices} />
        </div>
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
        <div className="w-1/3">
          <Type
            userChoice={userChoices.type}
            verticalSet={isSmallScreen}
            className="mt-8"
            updateUserChoices={updateUserChoices}
          />
        </div>
      </div>
      {handleTypeChoose()}
      <Footer />
    </div>
  );
}
