import React, { useEffect } from "react";
import {Link} from 'react-router-dom';
import {
  FaLinkedin,
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaDiscord
} from 'react-icons/fa';
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { i18n } = useTranslation();
  return (
      <footer className="relative bg-blueGray-200 pt-12 bg-slate-200">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-title font-bold">{i18n.t("Contact Us")}!</h4>
              <h5 className="text-2xl font-paragraph mt-0 mb-2 text-blueGray-600">
               
              </h5>
              <div className="mt-6 flex justify-center lg:justify-normal lg:mb-0 mb-6">
              <Link   
              to="https://www.facebook.com/profile.php?id=61552599852965&mibextid=ZbWKwL"
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={20} color='black' />
              </Link>
              <Link   
              to="https://www.instagram.com/arcseedai?igsh=MnY4eGlrdjYycW8x"
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={20} color='black' />
              </Link>
              <Link  
              to="https://discord.gg/9fbu4Mgw" 
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaDiscord size={20} color='black' />
              </Link> 
              <Link  
              to="https://x.com/Arcseedai?t=ZHkrUzfHVmidMFDEGFhMfw&s=08"
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={20} color='black' />
              </Link>
              {/* <Link   className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaGithub size={20} color='black' />
              </Link> */}
              <Link   
              to="https://br.linkedin.com/in/arcseed-ai-6335a3294"
              className="rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={20} color='black' />
              </Link>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block font-title text-lg uppercase text-blueGray-500 font-semibold mb-1">
                    {i18n.t("Useful Links")}
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                      target="_isblank"
                      className="text-blueGray-600 hover:underline underline-offset-2 font-paragraph text-xl hover:text-blueGray-800 leading-4 block pb-2"
                      href="https://doc.arcseedai.com/">
                        {i18n.t("Documentation")}
                      </a>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:underline underline-offset-2 font-paragraph text-xl hover:text-blueGray-800 leading-4 block pb-2"
                        to="/"
                      >
                        HOME
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/Dashboard/FAQ"
                          className="text-blueGray-600 hover:underline underline-offset-2 font-paragraph text-xl hover:text-blueGray-800 leading-4 block pb-2"
                        >
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full mt-7 lg:mt-0 lg:w-4/12 px-4">
                  <span className="block text-nowrap font-title text-lg uppercase text-blueGray-500 font-semibold mb-1">
                    {i18n.t("Other Resources")}
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className="text-blueGray-600 hover:underline underline-offset-2 font-paragraph text-xl hover:text-blueGray-800 leading-4 block pb-2"
                        to="/CommunityGallery"
                      >
                        {i18n.t("Community Gallery")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:underline underline-offset-2 font-paragraph text-xl hover:text-blueGray-800 leading-4 block pb-2"
                        to="/Terms"
                      >
                        {i18n.t("Terms & Conditions")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:underline underline-offset-2 font-paragraph text-xl hover:text-blueGray-800 leading-4 block pb-2"
                        to="/Privacy"
                      >
                        {i18n.t("Privacy Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:underline underline-offset-2 font-paragraph text-xl hover:text-blueGray-800 leading-4 block pb-2"
                        to={`/${"footer"}`}
                      >
                        {i18n.t("Contact")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                Copyright © {new Date().getFullYear()} ArcseedAI
              </div>
            </div>
          </div> */}
        </div>
      </footer>
  );
}
