import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./locales/i18n";
import MetaPixel from "./components/PlanPageComponents/MetaPixel";
import PopUpComponent from "./components/IndexComponents/PopUpComponent";
const RootComponent = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [isRendering, setIsRendering] = useState(false);

  useEffect(() => {
    const storedPreference = localStorage.getItem("cookiesAccepted");
    setCookiesAccepted(storedPreference);
    const timer = setTimeout(() => {
      setIsRendering((cookiesAccepted !== "all" && cookiesAccepted !== "essential"))
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <React.StrictMode>
        {cookiesAccepted === "all" ?
        <MetaPixel />
        :
        (cookiesAccepted === "essential" ? 
          null
          :
          (isRendering && (
            <PopUpComponent/>
          ))
        )
      }
      <App />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);

reportWebVitals();
