import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function GenericRangeComponent({ updateUserChoices, className , rangeParamJSON }) {
  const [customValue, setCustomValue] = useState(rangeParamJSON.param.startsAt);
  const {t} = useTranslation();

  const handleCustomValueChange = (event) => {
    const value = event.target.value;
    setCustomValue(value);
    updateUserChoices(rangeParamJSON.param.variableName, value);
  };

  return (
    <div className={"items-center flex flex-col " + className }>
      <h1 className='font-title lg:justify-center 
      font-bold py-8 lg:py-2 lg:mb-2 flex items-center'>
        {t(rangeParamJSON.param.title)}
      <div className="w-[0.01px] h-[0.01px] relative">
        <InfoBox
        customClass={"absolute left-1 top-1/2 -translate-y-1/2"}
        text={t(rangeParamJSON.param.infoBoxDescription)}/>
      </div>
        </h1>
      <div className="flex justify-between max-w-screen items-center w-[90vw] mb-4 lg:w-[25.5rem]">
        {Object.entries(rangeParamJSON.param.options).map(([id]) => (
          <p className={`text-white font-title bg-black rounded-full
          ${(isNaN(parseInt(rangeParamJSON.param.options[id])) ? 'px-4 py-1' 
          : 
          'w-7 h-7 flex items-center justify-center')}
          `}>
          {t(rangeParamJSON.param.options[id].toUpperCase())}
          </p>
        ))}
      </div>
      <input
        type="range"
        min="1"
        max={rangeParamJSON.param.maxRange}
        value={customValue}
        onChange={handleCustomValueChange}
        className="lg:w-[24.8rem] w-[70vw] appearance-none h-1 rounded-full bg-black opacity-85 outline-none transition-all duration-300"
      />
      <p className='font-paragraph text-xl mt-3 lg:text-justify'>
        {t(rangeParamJSON.param.description)}</p>
    </div>
  );

}