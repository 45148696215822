export function geraPrompt(userChoices,user,nomeImage) {


    let prompt_default = `{
  "2": {
    "inputs": {
      "stop_at_clip_layer": -2,
      "clip": [
        "336",
        1
      ]
    },
    "class_type": "CLIPSetLastLayer",
    "_meta": {
      "title": "CLIP Set Last Layer"
    }
  },
  "6": {
    "inputs": {
      "width": 1024,
      "height": 1024,
      "batch_size": 1
    },
    "class_type": "EmptyLatentImage",
    "_meta": {
      "title": "Empty Latent Image"
    }
  },
  "20": {
    "inputs": {
      "text": "A pristine photograph of a modern living room",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "22": {
    "inputs": {
      "seed": [
        "47",
        0
      ],
      "steps": 10,
      "cfg": 1.5,
      "sampler_name": "dpmpp_3m_sde_gpu",
      "scheduler": "sgm_uniform",
      "denoise": [
        "38",
        0
      ],
      "model": [
        "317",
        0
      ],
      "positive": [
        "304",
        0
      ],
      "negative": [
        "306",
        0
      ],
      "latent_image": [
        "6",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "KSampler"
    }
  },
  "23": {
    "inputs": {
      "samples": [
        "22",
        0
      ],
      "vae": [
        "336",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "38": {
    "inputs": {
      "value": 1
    },
    "class_type": "easy float",
    "_meta": {
      "title": "Denoise"
    }
  },
  "47": {
    "inputs": {
      "seed": 171,
      "🎲 Manual Random Seed": null
    },
    "class_type": "easy seed",
    "_meta": {
      "title": "EasySeed"
    }
  },
  "52": {
    "inputs": {
      "conditioning_to": [
        "53",
        0
      ],
      "conditioning_from": [
        "20",
        0
      ]
    },
    "class_type": "ConditioningConcat",
    "_meta": {
      "title": "Conditioning (Concat)"
    }
  },
  "53": {
    "inputs": {
      "text": "",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "68": {
    "inputs": {
      "image": "1024x1024.jpg",
      "upload": "image"
    },
    "class_type": "LoadImage",
    "_meta": {
      "title": "Load Image"
    }
  },
  "74": {
    "inputs": {
      "text": "blur, distortion, jiggly, low quality",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "88": {
    "inputs": {
      "strength": 0.24,
      "start_percent": 0,
      "end_percent": 0.8,
      "positive": [
        "52",
        0
      ],
      "negative": [
        "74",
        0
      ],
      "control_net": [
        "339",
        0
      ],
      "image": [
        "343",
        0
      ]
    },
    "class_type": "ControlNetApplyAdvanced",
    "_meta": {
      "title": "Apply ControlNet (Advanced)"
    }
  },
  "102": {
    "inputs": {
      "strength": 0.14,
      "start_percent": 0,
      "end_percent": 0.26,
      "positive": [
        "88",
        0
      ],
      "negative": [
        "88",
        1
      ],
      "control_net": [
        "338",
        0
      ],
      "image": [
        "104",
        0
      ]
    },
    "class_type": "ControlNetApplyAdvanced",
    "_meta": {
      "title": "Apply ControlNet (Advanced)"
    }
  },
  "104": {
    "inputs": {
      "low_threshold": 70,
      "high_threshold": 40,
      "resolution": 1024,
      "image": [
        "68",
        0
      ]
    },
    "class_type": "CannyEdgePreprocessor",
    "_meta": {
      "title": "Canny Edge"
    }
  },
  "113": {
    "inputs": {
      "pixels": [
        "68",
        0
      ],
      "vae": [
        "336",
        2
      ]
    },
    "class_type": "VAEEncode",
    "_meta": {
      "title": "VAE Encode"
    }
  },
  "304": {
    "inputs": {
      "conditioning_to": [
        "305",
        0
      ],
      "conditioning_from": [
        "102",
        0
      ]
    },
    "class_type": "ConditioningConcat",
    "_meta": {
      "title": "Conditioning (Concat)"
    }
  },
  "305": {
    "inputs": {
      "strength": 0.6900000000000001,
      "start_percent": 0,
      "end_percent": 0.1,
      "positive": [
        "102",
        0
      ],
      "negative": [
        "102",
        1
      ],
      "control_net": [
        "338",
        0
      ],
      "image": [
        "104",
        0
      ]
    },
    "class_type": "ControlNetApplyAdvanced",
    "_meta": {
      "title": "Apply ControlNet (Advanced)"
    }
  },
  "306": {
    "inputs": {
      "conditioning_to": [
        "305",
        1
      ],
      "conditioning_from": [
        "102",
        1
      ]
    },
    "class_type": "ConditioningConcat",
    "_meta": {
      "title": "Conditioning (Concat)"
    }
  },
  "317": {
    "inputs": {
      "weight": 0.1,
      "weight_type": "linear",
      "combine_embeds": "concat",
      "start_at": 0,
      "end_at": 1,
      "embeds_scaling": "V only",
      "model": [
        "336",
        0
      ],
      "ipadapter": [
        "342",
        0
      ],
      "image": [
        "68",
        0
      ],
      "clip_vision": [
        "341",
        0
      ]
    },
    "class_type": "IPAdapterAdvanced",
    "_meta": {
      "title": "IPAdapter Advanced"
    }
  },
  "335": {
    "inputs": {
      "filename_prefix": "redecorate_standard_square_V0.2.3",
      "file_type": "JPEG",
      "images": [
        "23",
        0
      ]
    },
    "class_type": "SaveImageExtended",
    "_meta": {
      "title": "Save Image (Extended)"
    }
  },
  "336": {
    "inputs": {
      "ckpt_name": "Arcseed_V0.2.safetensors",
      "key_opt": "",
      "mode": "Auto"
    },
    "class_type": "CheckpointLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Checkpoint Loader (Inspire)"
    }
  },
  "337": {
    "inputs": {
      "model_name": "swift_srgan_2x.pth",
      "key_opt_u": "",
      "mode": "Auto"
    },
    "class_type": "UpscaleLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Upscale Loader (Inspire)"
    }
  },
  "338": {
    "inputs": {
      "control_net_name": "LoraModelCanny.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "339": {
    "inputs": {
      "control_net_name": "LoraModelDepth.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "340": {
    "inputs": {
      "control_net_name": "ttplanetSDXLControlnet_v20Fp16.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "341": {
    "inputs": {
      "clip_name": "clipvis_ViT-H_1.5_.safetensors",
      "key_opt_cv": "",
      "mode": "Auto"
    },
    "class_type": "CLIPVisionLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Clip Loader (Inspire)"
    }
  },
  "342": {
    "inputs": {
      "key": 1
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  },
  "343": {
    "inputs": {
      "seed": 127,
      "denoise_steps": 6,
      "ensemble_size": 3,
      "processing_resolution": 768,
      "scheduler": "LCMScheduler",
      "use_taesd_vae": false,
      "marigold_model": [
        "344",
        0
      ],
      "image": [
        "68",
        0
      ]
    },
    "class_type": "MarigoldDepthEstimation_v2",
    "_meta": {
      "title": "MarigoldDepthEstimation_v2"
    }
  },
  "344": {
    "inputs": {
      "key": 2
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  }
}`;

    prompt_default = JSON.parse(prompt_default);

    const { floor, walls, ceiling } = userChoices["materials"] || {};
  
  
    const modelos = userChoices["aiModels"] || '';
  let modeloFrase = "";

  switch (modelos) {
    case "DEFAULT":
      modeloFrase = " ";
      break;
    case "RENDER":
      modeloFrase = "A 3D render of";
      break;
    case "CINEMATIC":
      modeloFrase = "A Cinematic professional shot of";
      break;
    case "PHOTOGRAPHER":
      modeloFrase = "A Photograph of";
      break;
    case "ARCHVIZ":
      modeloFrase = "A Professional architectural rendering of";
      break;
    case "PRISTINE":
      modeloFrase = "A Pristine photograph of";
      break;
  }

  const anStyles = [
    "arabic", "art deco", "art nouveau", "asian fusion", "eclectic", "english",
    "industrial", "japandi", "oriental", "urban modern"
  ];
  const style = userChoices["style"] || '';
  
  // Se o estilo não for selecionado, styleFrase será uma string vazia.
  let styleFrase = '';
  if (style) {
    styleFrase = anStyles.includes(style) ? `an ${style}` : `a ${style}`;
  }


  let whereExterior = userChoices["whereExterior"] || '';
  let time = userChoices["time"] || '';
  let colorPalette = userChoices["colorPalette"] || [];
  let what = userChoices["what"] || '';
  let Style = styleFrase;
  let Modes = `${modeloFrase}` || '';

  if (what === "Square") {
    what = "a public plaza";
  }

  // Remove duplicatas da paleta de cores
  colorPalette = [...new Set(colorPalette)];

  // Construir a frase do chão
  let floorFrase = floor ? `${floor} floor` : '';

  // Construir a frase das paredes
  let wallsFrase = '';
if (Array.isArray(walls) && walls.length > 0) {
  if (walls.length === 1) {
    wallsFrase = `${walls[0]} walls`;
  } else if (walls.length === 2) {
    wallsFrase = `${walls[0]} and ${walls[1]} walls`;
  } else {
    wallsFrase = `${walls.slice(0, -1).join(', ')} and ${walls[walls.length - 1]} walls`;
  }
}

  // Construir a frase do teto
  let ceilingFrase = ceiling ? `${ceiling} ceiling` : '';

  // Construa o prompt apenas com os parâmetros fornecidos
  const promptParts = [
    Modes,
    Style,
    what ? `${what},` : '',  // Adiciona vírgula após what
    time ? `${time},` : '',    // Adiciona vírgula após time
    whereExterior
  ].filter(Boolean).map(part => part.trim()); // Remove valores falsos e trim

  // Junta os elementos em uma única string
  const promptText = promptParts.join(' ').replace(/\s{2,}/g, ' ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

  // Atualiza o texto no nó 20
  prompt_default["20"]["inputs"]["text"] = promptText.trim();
    prompt_default["53"]["inputs"]["text"] = [...colorPalette, floorFrase, wallsFrase, ceilingFrase].filter(Boolean).join(', ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

    const shapeStrengthMap = {
      1: { "CN1": 0.1, "CN2": 0.1 },
      2: { "CN1": 0.24, "CN2": 0.14 },
      3: { "CN1": 0.5, "CN2": 0.25 },
      4: { "CN1": 0.6, "CN2": 0.4 },
      5: { "CN1": 0.5, "CN2": 0.5 }
    };
  
    const shapeExposureMap = {
      1: { "CN1": 0.1, "CN2": 0.1 },
      2: { "CN1": 0.3, "CN2": 0.2 },
      3: { "CN1": 0.8, "CN2": 0.26 },
      4: { "CN1": 0.9, "CN2": 0.4 },
      5: { "CN1": 0.6, "CN2": 0.6 }
    };
  
    // Recupera as escolhas do usuário para ShapeStrength e ShapeExposure
    const userShapeStrengthChoice = userChoices["ShapeStrengthRange"];
    const userShapeExposureChoice = userChoices["ShapeExposureRange"];
  
    // Define os valores de acordo com a escolha do usuário
    const selectedShapeStrength = shapeStrengthMap[userShapeStrengthChoice];
    const selectedShapeExposure = shapeExposureMap[userShapeExposureChoice];
  
    // Aplica os valores ao prompt_default para CN1 (88) e CN2 (102)
    prompt_default["88"]["inputs"]["strength"] = selectedShapeStrength["CN1"];
    prompt_default["102"]["inputs"]["strength"] = selectedShapeStrength["CN2"];
  
    prompt_default["88"]["inputs"]["end_percent"] = selectedShapeExposure["CN1"];
    prompt_default["102"]["inputs"]["end_percent"] = selectedShapeExposure["CN2"];
  
    prompt_default["88"]["inputs"]["start_percent"] = 0;
    prompt_default["102"]["inputs"]["start_percent"] = 0;
  
    const aiImaginationMap = {
      5: 2.1,
      4: 1.8,
      3: 1.5,
      2: 1.25,
      1: 1.0
    };
  
    // Recupera a escolha do usuário para AI Imagination Range
    const userAIImaginationChoice = userChoices["AIImmaginationRange"];
  
    // Define o valor de cfg de acordo com a escolha do usuário
    const selectedAIImaginationValue = aiImaginationMap[userAIImaginationChoice];
  
    // Aplica o valor ao prompt_default para cfg
    prompt_default["22"]["inputs"]["cfg"] = selectedAIImaginationValue;
  
  
    //------------------------------------ip weight-----------------------------------
    const inputIpWeightMap = {
      1: 0.0,
      2: 0.1,
      3: 0.25,
      4: 0.5,
      5: 0.8
    };
  
    // Recupera a escolha do usuário para Input Depth Range
    const userInputIpWeightChoice = userChoices["RoomWeightRange"];
  
    // Define o valor de CN1 de acordo com a escolha do usuário
    const selectedInputIpWeightValue = inputIpWeightMap[userInputIpWeightChoice];
  
    // Aplica o valor ao prompt_default para CN1 (assumindo que "88" corresponde a CN1 para Input Depth)
    prompt_default["317"]["inputs"]["weight"] = selectedInputIpWeightValue;
  
    const inputIpWeightFinalMap = {
      1: 0.0,
      2: 0.2,
      3: 0.5,
      4: 0.7,
      5: 1
    };
  
    // Recupera a escolha do usuário para Input Depth Range
    const userInputIpWeightFinalChoice = userChoices["RoomWeightStyleRange"];
  
    // Define o valor de CN1 de acordo com a escolha do usuário
    const selectedInputIpWeightFinalValue = inputIpWeightFinalMap[userInputIpWeightFinalChoice];
  
    // Aplica o valor ao prompt_default para CN1 (assumindo que "88" corresponde a CN1 para Input Depth)
    prompt_default["317"]["inputs"]["end_at"] = selectedInputIpWeightFinalValue;
  
  
    //------------------------------------Boost-----------------------------------
  
    const ShapeBoostRangeMap = {
      1: 0.2,
      2: 0.69,
      3: 1,
      4: 2,
      5: 3
    };
  
    // Recupera a escolha do usuário para Input Depth Range
    const userShapeBoostRangeChoice = userChoices["ShapeBoostRange"];
  
    // Define o valor de CN1 de acordo com a escolha do usuário
    const selectedShapeBoostRangeValue = ShapeBoostRangeMap[userShapeBoostRangeChoice];
  
    // Aplica o valor ao prompt_default para CN1 (assumindo que "88" corresponde a CN1 para Input Depth)
    prompt_default["305"]["inputs"]["strength"] = selectedShapeBoostRangeValue;
  
    const BoostExposureRangeMap = {
      1: 0.1,
      2: 0.2,
      3: 0.3,
      4: 0.4,
      5: 0.5
    };
  
    // Recupera a escolha do usuário para Input Depth Range
    const userBoostExposureRangeChoice = userChoices["BoostExposureRange"];
  
    // Define o valor de CN1 de acordo com a escolha do usuário
    const selectedBoostExposureRangeValue = BoostExposureRangeMap[userBoostExposureRangeChoice];
  
    // Aplica o valor ao prompt_default para CN1 (assumindo que "88" corresponde a CN1 para Input Depth)
    prompt_default["305"]["inputs"]["end_percent"] = selectedBoostExposureRangeValue;




    prompt_default["22"]["inputs"]["seed"] = `${userChoices["seed"]}`;

    prompt_default["335"]["inputs"]["filename_prefix"] = `${user}`;

    prompt_default["68"]["inputs"]["image"] = `${nomeImage}`;
    

    const prompt = prompt_default;
    //console.log(prompt);
    return prompt;
}
