import React, { useState } from "react";
import { IoMdMenu, IoMdPerson } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

export default function NavbarButtons({ showPoints, pontos }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [pontos, setPontos] = useState('');

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const verificarToken = () => {
    const token = Cookies.get('token');
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp >= currentTime;
    } catch (error) {
      return false;
    }
  };

  const handleLinkClick = (path) => {
    if (!verificarToken()) {
      navigate('/Login');
    } else {
      navigate(path);
    }
  };

  const styleOpacity = {
    opacity: 0.7,
  };

  const removeToken = () => {
    Cookies.remove("clientId");
    Cookies.remove("token");
    localStorage.removeItem("moeda_total");
    localStorage.removeItem("plano");
    localStorage.removeItem("telefone");
  }

  const isIphone = () => {
    return /iPhone/i.test(navigator.userAgent);
  }

  const plansSection = {
    "Free": {
      link: "/PlansPage/BEGINNER"
    },
    "Enthusiast": {
      link: "/PlansPage/ENTHUSIAST"
    },
    "Professional": {
      link: "/PlansPage/PROFESSIONAL"
    },
    "Enterprise": {
      link: "/PlansPage/ENTERPRISE"
    }
  }

  const inStudioSection = {
    "Install in your studio": {
      link: "/InstallYourStudio"
    },
    "Make your AI model": {
      link: "/MakeYourAi"
    }
  }

  const infoSection = {
    "Documentation": {
      link: "http://doc.arcseedai.com/"
    },
    "Blog": {
      link: ""
    },
    "FAQ": {
      link: "/Dashboard/FAQ"
    },
    "Community Gallery": {
      link: "/CommunityGallery"
    },
    "Contact": {
      link: "/footer"
    },
    "Dashboard": {
      link: "/Dashboard/initial"
    }
  }

  const renderSectionLinks = {
      "Brainstorm Gen ": {
        link: "/BrainstormGen"
      },
      "Sketch 2 Render ": {
        link: "/Sketch2Render"
      },
      "Canvas Alchemy ": {
        link: "/CanvasAlchemy"
      },
      "Re-render ": {
        link: "/ReRender"
      },
      "Re-decorate (beta) ": {
        link: "/Decorate"
      }
  }

  return (
    <div
      id="buttons-flex"
      className={`flex justify-end w-fit items-right overflow-hidden relative`}
    >

      {showPoints && (
      <div 
        className="border-transparent animate-once 
        animate-fade-down animate-duration-700 min-h-[50px] min-w-[50px] 
        h-[3vw] w-[3vw] max-h-[90px] max-w-[90px]
        rounded-[100%] ml-2 opacity-65 flex flex-col justify-center 
        items-center text-sm bg-[#444444]">
          <p
          className="font-title font-bold text-white text-[100%]">RP</p>
          <div 
          className="w-[90%] h-[1px] bg-white"/>
          <p
          className="font-title text-sm font-bold text-white text-[100%]">{pontos === null ? ("0") : pontos}</p>
      </div>
      )}
      <Link 
      className="z-50"
      style={styleOpacity} 
      to={`${!verificarToken() ? "/Login" : "/Dashboard/initial"}`}>
          <IoMdPerson 
          className="border-transparent animate-once 
          animate-fade-down animate-duration-700 p-2 bg-black 
          rounded-[100%] ml-2 opacity-70 min-h-[50px] min-w-[50px] 
        h-[3vw] w-[3vw] max-h-[90px] max-w-[90px]" 
          color="white" />
        </Link>
      <div style={styleOpacity}>
        <IoMdMenu
          className="border-transparent -translate-y-12 animate-once animate-fade-down animate-duration-700 p-2 bg-black rounded-[100%] ml-2 opacity-70 cursor-pointer
          min-h-[50px] min-w-[50px] 
        h-[3vw] w-[3vw] max-h-[90px] max-w-[90px]"
          color="white"
          onClick={handleMenuToggle}
          style={styleOpacity}
        />
      </div>


      <div className={`menu-container relative`}>
        <div id="main-flex-col" className={`menu z-50 fixed lg:w-[50%] flex-col text-sm md:text-lg w-full lg:my-0 lg:mx-0 h-full lg:p-7 bg-white top-0 shadow-md transition-transform duration-300 ease-in-out transform ${isMenuOpen ? 'translate-x-0 right-0' : 'translate-x-full right-[-5%]'}`}>
          <div id="slide-buttons" className="">
          <div className="absolute items-center font-title z-50 left-[4vw] top-[5%]  lg:top-[4vh] flex lg:left-[2vw]">
            <LanguageSelector />
          </div>

          <div className="absolute items-center w-auto  z-50
          right-[4vw] flex lg:right-[2vw] top-[5%]  lg:top-[4vh]">
            <Link 
            className="z-50"
            to="/">
              <button className="rounded-[2rem] lg:text-base 
                hover:animate-pulse mr-4 text-[3vw] font-bold font-title 
                text-white lg:py-2 lg:px-3 py-[2vw] 
                px-[2vw] bg-black">
                {t("HOME".toLowerCase())}
              </button>
            </Link>
            {!verificarToken() ? 
            <div>
              <Link 
              className="z-50"
              to="/Login">
                <button className="rounded-[2rem] lg:text-base 
                hover:animate-pulse text-[3vw] font-bold font-title 
                text-white lg:py-2 lg:px-3 py-[2vw] z-50 
                px-[2vw] bg-black">
                  {t("LOGIN")}
              </button>
              </Link>
              <Link 
              className="z-50"
              to="/Register">
                <button className="rounded-[2rem] lg:text-base 
                hover:animate-pulse ml-4 text-[3vw] 
                font-bold font-title text-white z-50
                lg:py-2 lg:px-3 py-[2vw] px-[2vw] bg-black">
                  {t("REGISTER".toLowerCase())}
                  </button>
              </Link>
            </div>
            :
            <Link 
            to="/"
            className="z-50">
            <button 
            onClick={removeToken}
            className="rounded-[2rem] lg:text-base 
            hover:animate-pulse text-[3vw] font-bold font-title 
            text-white lg:py-2 lg:px-3 py-[2vw] 
            px-[2vw] bg-black">
              {t("LOGOUT".toLowerCase())}
            </button>
            </Link>
            }
            <Link onClick={handleMenuToggle} className="cursor-pointer ml-4 hover:animate-pulse z-50">
              <AiOutlineClose className="bg-black p-[0.7vw] rounded-[100%] lg:w-10 lg:h-10 min-w-[40px] min-h-[40px] w-[7.5vw] h-[7.5vw] lg:p-2"
                size={10}
                color="white" />
            </Link>
          </div>
          </div>

            {/* MENU LATERAL REESCRITO E SIMPLIFICADO */}
            <div className={`absolute w-full flex ${isIphone() ? "top-1/4" : "top-1/4" } left-1/2 -translate-x-1/2 lg:px-0 px-[6%] lg:mb-0 mb-[5%] justify-center`}>
              <div className="w-auto grid grid-cols-2 justify-between gap-[10%] xl:gap-[25%]">
                <div className="text-left">
                <h1 className="font-bold font-title sm:text-xl md:text-4xl text-2xl leading-[4.3vh] text-nowrap lg:text-2xl">{t("render")}</h1>
                <ul className="text-nowrap leading-[4.3vh] sm:text-lg md:text-2xl text-xl">
                  {Object.entries(renderSectionLinks).map(([index]) => (
                  <li className='text-[110%] lg:text-2xl font-paragraph -mt-1'>
                    <div className="group relative">
                    <span 
                    onClick={() => handleLinkClick(renderSectionLinks[index].link)} 
                    className="text-black relative
                    cursor-pointer">
                    {t(index)}
                    <span className="ease-in-out duration-500 
                    absolute left-0 -bottom-[4px]
                    bg-black h-0.5 w-0 group-hover:w-full"/>
                  </span>
                  </div>
                  </li>
                  ))}
                </ul>
                </div>
              <div className="text-left h-fit">
              <h1 className="font-bold font-title sm:text-lg md:text-4xl leading-[4.3vh] text-2xl text-nowrap lg:text-2xl">{t("IN STUDIO")}</h1>
                <ul className="text-nowrap leading-[4.3vh] sm:text-lg md:text-2xl text-xl">
                    {Object.entries(inStudioSection).map(([index]) => (
                    <li className='text-[110%] lg:text-2xl font-paragraph -mt-1'>
                      <Link to={inStudioSection[index].link} 
                      className="group text-black transition duration-300 relative">
                        {t(index)}
                        <span className="ease-in-out duration-500 
                    absolute left-0 -bottom-[4px]
                    bg-black h-0.5 w-0 group-hover:w-full"/>
                      </Link>
                    </li>
                    ))}
                </ul>
              </div>
              <div className="text-left">
              <h1 className="font-bold font-title sm:text-lg md:text-4xl text-2xl leading-[4.3vh] text-nowrap lg:text-2xl">{t("PLANS")}</h1>
                <ul className="text-nowrap leading-[4.3vh] sm:text-lg md:text-2xl text-xl">
                    {Object.entries(plansSection).map(([index]) => (
                    <li className='text-[110%] lg:text-2xl font-paragraph -mt-1'>
                      <Link to={plansSection[index].link} 
                      className="group text-black transition duration-300 relative">
                        {t(index)}
                        <span className="ease-in-out duration-500 
                        absolute left-0 -bottom-[4px]
                        bg-black h-0.5 w-0 group-hover:w-full"/>
                      </Link>
                    </li>
                    ))}
                </ul>
              </div>
              <div className="text-left">
              <h1 className="font-bold leading-[4.3vh] font-title sm:text-lg md:text-4xl text-2xl text-nowrap lg:text-2xl">INFO</h1>
                <ul className="text-nowrap leading-[4.3vh] sm:text-lg md:text-2xl text-xl">
                  {Object.entries(infoSection).map(([index]) => (
                  <li className='text-[110%] lg:text-2xl font-paragraph -mt-1'>
                    <Link 
                    target={index === t("Documentation" ? "_isblank" : "")}
                    to={infoSection[index].link} 
                    className="group text-black transition duration-300 relative">
                      {t(index)}
                      <span className="ease-in-out duration-500 
                    absolute left-0 -bottom-[4px]
                    bg-black h-0.5 w-0 group-hover:w-full"/>
                    </Link>
                  </li>
                  ))}
                  
                </ul>
              </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}