import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Footer from '../../components/Footer';
import MakeGif from "./assets/createModelVideo.mp4"; // Substitua pelo seu arquivo GIF
import NavbarButtons from "../NavbarButtons";
import DOMPurify from "dompurify"; // comentario
import { add_Back } from "../../address";
import { useTranslation } from "react-i18next";
import { Context } from "../../App";
import NewPopUpComponent from "../Generic/NewPopUpComponent";
import { useContext } from "react";

export default function InstallYourStudio() {
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState(!false);
  const [isDragging, setIsDragging] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  // const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  // const [subjectErrorMessage, setSubjectErrorMessage] = useState("");
  const { i18n } = useTranslation();
  const [warningMessage, setWarningMessage] = useContext(Context)

  useEffect(() => {
    i18n.init({ ns: "makeYourAi" })
  }, [i18n])

  const onDrop = useCallback((acceptedFiles) => {
    let newImages = [];
    let errorMessage = '';

    acceptedFiles.forEach(file => {
      if (file.size > 5 * 1024 * 1024) {
        setPopUpTitle('The image file cannot exceed 5MB');
        setError(true);
        return
      } else if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        setPopUpTitle('Only .jpg, .jpeg and .png are supported')
        setError(true)
        return
      } else {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width > 3000 || img.height > 3000) {
            setPopUpTitle('The image resolution cannot exceed 3000x3000')
            setError(true)
            return
          }
        };
        newImages.push(file);
      }
    });

    if (images.length + newImages.length > 30) {
      setPopUpTitle('You can upload up to 30 files')
      setError(true)
      return
    }
    
    if (errorMessage) {
      setError(true);
    } else {
      setError(false);
      setImages([...images, ...newImages]);
    }
  }, [images]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/jpg",
    maxFiles: 30,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    onDropAccepted: () => setIsDragging(false),
  });

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (!/^[a-zA-Zà-úÀ-Ú ]*$/.test(value)) {
      setNameErrorMessage("O nome só pode conter letras e espaços.");
    } else {
      setName(value.slice(0, 60));
      setNameErrorMessage("");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9@._-]*$/.test(value)) {
    setEmail(e.target.value);
    }
  };


  const handleSubjectChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9à-úÀ-Ú .,?!]*$/.test(value)) {
      setSubject(value.slice(0, 30));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const mensagemTemplate = `
        Nome: ${DOMPurify.sanitize(name)}
        Email: ${DOMPurify.sanitize(email)}
        Mensagem: ${DOMPurify.sanitize(subject)}
      `;

      await axios.post(`${add_Back}/enviarEmail`, {
        assunto: `MakeYourAi - ${subject}`,
        mensagem: mensagemTemplate,
      });
      setWarningMessage("E-mail sent successfully!")
    } catch (error) {
      console.error("Erro ao enviar e-mail:", error);
      setWarningMessage("Error while sending e-mail")
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex font-title flex-col h-screen min-h-screen">
      {/*
      <PopupComponent
        title={popUpTitle}
        state={error}
        setState={setError}/>
      */}
      <NewPopUpComponent
      isClosable={true}/>
      <section className="flex flex-1 flex-col lg:flex-row bg-black relative h-screen min-h-screen lg:justify-start justify-center">
        <div className="w-full lg:w-[55%] hidden lg:block">
          <div className="h-screen p-2">
            <video
              width={"100%"}
              height={"100%"}
              autoPlay
              muted
              loop
            >
              <source 
              src={MakeGif}
              type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="w-full lg:w-[45%] flex flex-col justify-center p-5">
          <div className="absolute top-0 right-0 m-4">
            <NavbarButtons />
          </div>
            <h2 className="font-bold text-3xl md:text-4xl text-white mb-4">
              {i18n.t("CREATE YOUR MODEL")}
            </h2>
            <h4 className="font-bold text-sm text-white mb-4">
              {i18n.t("BUILD A MODEL OF YOUR STYLE TO SIMPLIFY YOUR WORK")}
            </h4>
            <div className="w-full flex flex-col items-center justify-center p-3">
              <form onSubmit={handleSubmit}
              className="flex flex-col mx-3 xl:mx-0 text-white text-left w-full lg:w-[80%]">
                <div className="mb-3">
                  <label htmlFor="name" className="block font-paragraph text-xl">
                    {i18n.t("Name")}:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                    className="w-full white-border-2 text-lg -mt-1 font-paragraph border-b-2 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-2 opacity-70 transition ease-in-out duration-300 text-white"
                    placeholder={i18n.t("Name")}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="block font-paragraph text-lg">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    className="w-full white-border-2 text-xl -mt-1 font-paragraph border-b-2 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-2 opacity-70 transition ease-in-out duration-300 text-white"
                    placeholder="E-mail"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="subject" className="block font-paragraph text-lg">
                    {i18n.t("Subject")}:
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={subject}
                    onChange={handleSubjectChange}
                    className="w-full white-border-2 text-xl -mt-1 font-paragraph border-b-2 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-2 opacity-70 transition ease-in-out duration-300 text-white"
                    placeholder={i18n.t("Subject")}
                    required
                  />
                </div>
                <h4 className="font-bold text-sm text-white text-center uppercase mt-4">
                  {i18n.t("Upload images that describe your style below, a good model requires at least 15 different images representing your style ")}
                </h4>
                <div {...getRootProps({ className: 'dropzone' })} 
                className="border-2 border-dashed border-white p-4 w-full 
                text-center cursor-pointer bg-white text-black font-bold mt-2">
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="animate-fade animate-duration-300 animate-once">Drag your files here</p>
                  ) : (
                    <p
                    className="animate-fade animate-duration-300 animate-once">{images.length === 0 ? (i18n.t('CLICK OR DRAG YOUR IMAGES HERE')) : `${images.length} ${i18n.t("IMAGES UPLOADED")}`}</p>
                  )}
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div className="flex w-full justify-center">
                  <button
                    type="submit"
                    className="bg-white font-title text-black 
                    hover:animate-pulse px-8 py-3 text-lg w-fit mt-3
                    "
                  >
                    {i18n.t("SUBMIT")}
                  </button>
                </div>
              </form>
            </div>
            <h4 className="font-bold text-sm text-white text-center">
              {i18n.t("QUESTIONS")}
            </h4>
        </div>
      </section>
      <Footer footerVersion="light-footer" className="mt-auto" />
    </div>
  );
}
