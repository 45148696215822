import { useState, Suspense, lazy, useEffect } from 'react';
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.jsx";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import { IoMdMenu } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import PopupComponent from '../components/Generic/PopupComponent.jsx';
import { useTranslation } from 'react-i18next';
import NewPopUpComponent from '../components/Generic/NewPopUpComponent.jsx';
import { useParams } from 'react-router-dom';

const ProfSettings = lazy(() => import('./pages/profSettings.jsx'));
const SubSettings = lazy(() => import('./pages/subSettings.jsx'));
const FAQ = lazy(() => import('./pages/FAQ.jsx'));

const Dashboard = () => {
    const [choosePage, setChoosePage] = useState(
      "initial"
    );
    const navigate = useNavigate();
    const [showNavbar, setShowNavbar] = useState(true);
    const [title, setTitle] = useState("");
    const [state, setState] = useState(true);
    const { paramLink } = useParams();
    const [moedas, setMoedas] = useState(() => {
        const storedMoedas = localStorage.getItem("moedas_total");
        return storedMoedas ? parseInt(storedMoedas, 10) : 0;
      });

    const { i18n } = useTranslation();

    useEffect(() => {
      i18n.init({ ns: "Dashboard" })
    }, [i18n])

    const options = {
        "Profile Settings": {
            title: "Profile Settings",
            page: <ProfSettings
            showNavbar={showNavbar}
            setShowNavbar={setShowNavbar}
            title={title}
            state={state}
            setState={setState}
            setTitle={setTitle}/>
        },
        "Subscription Settings": {
            title: "Subscription Settings",
            page: <SubSettings
            showNavbar={showNavbar}
            setShowNavbar={setShowNavbar}
            title={title}
            state={state}
            setState={setState}
            setTitle={setTitle}/>
        },
        "Personal Gallery": {
            title: "Personal Gallery",
            // page: require("./pages/")
        },
        "FAQ": {
            title: "FAQ",
            page: <FAQ/>
        },
        "Documentation": {
            title: "Documentation",
            // page: require("./pages/")
        }
    }

    const verificarToken = () => {
        const token = Cookies.get('token');
        const telefone = localStorage.getItem('telefone');
        const plano = localStorage.getItem('plano');
        const idPlan = localStorage.getItem('id_plan');
        const moedasTotal = localStorage.getItem('moedas_total');
        
        if (!token || !telefone || !plano || !idPlan || !moedasTotal) {
          return false;
        }
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          return decodedToken.exp >= currentTime;
        } catch (error) {
          return false;
        }
      };

        // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

      useEffect(() => {
        document.title = "Dashboard - Arcseed AI";
        return () => {
          document.title = "Arcseed AI"
        }
      }, [])

      useEffect(() => {
        setChoosePage(
          paramLink !== "initial" ? paramLink : "initial"
        )
      }, [])

    const HandlePageShow = () => {
        switch(choosePage) {
            case "initial":
              return (
                  <div className='flex w-full h-full justify-center 
                  items-center font-paragraph text-xl'>
                      {i18n.t("Welcome to your Dashboard! Here you can manage and see your personal info, such as password, Phone, subscription, etc... Besides that acess your Personal Galerry! Also, to understand better how our AI works, click on FAQ or Documentation!")}
                  </div>
              )
            case "Profile Settings":
                return options["Profile Settings"].page
            case "Subscription Settings":
                return options["Subscription Settings"].page
            case "Personal Gallery":
                return navigate('/PersonalGallery');
            case "FAQ":
                return options["FAQ"].page
            case "Documentation":
                return window.location.href='http://doc.arcseedai.com/'
        }
    }

    const isIphone = () => {
      return /iPhone/i.test(navigator.userAgent);
    }

    const [renderMenuState, setRenderMenuState] = useState(false);
    const [titleVisible, setTitleVisible] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setTitleVisible(false)
      }, 7000);

      return () => clearTimeout(timer);
    }, [])

    const { t } = useTranslation();

    return (
        <div>
          <NewPopUpComponent
          isClosable={true}/>
          <PopupComponent
          title={title}
          state={state}
          setState={setState}
          isClosable={true}/>
          {showNavbar && (
            <Navbar 
            title="Dashboard"
            description={i18n.t("Edit your personal info")}
            subtitle={i18n.t("Edit your personal info")}
            showPoints={true}
            moedas={moedas}/>
          )}
        <div className="pt-[100px] min-h-screen">
            {isIphone() ? 
            <div className="
            flex flex-col lg:flex-row justify-start lg:items-start items-center">
                {renderMenuState && (
                <div className={`fixed top-0 left-0 flex-col justify-center items-center w-screen
                h-screen bg-white z-50 transition-opacity ease-in-out duration-300 inline-flex`}>
                    <h1
                    className='font-title font-bold mb-[4%] text-[6vw] animate-fade animate-duration-500'>
                      {t("DASHBOARD MENU")}
                    </h1>
                    <IoMdMenu
                    onClick={() => setRenderMenuState(false)}
                    size={40}
                    color='black'
                    className='p-[2%] border border-solid border-gray-500
                    rounded-lg'/>
                    <ul 
                    className="flex-col text-left inline-flex z-40
                    bg-slate-200 my-[5%] lg:m-[5%] h-fit w-[95%] lg:w-auto">
                        {Object.entries(options).map(([key, { title }]) => (
                          <li 
                          key={key} 
                          className={`font-paragraph text-xl 
                              py-[3vh] px-[2vw] 
                              border-slate-300 border border-solid
                              bg-slate-200 lg:text-[130%] text-[150%] cursor-pointer 
                              ease-in-out duration-300 transition-colors ${
                              choosePage === key ? "border-r-2 border-r-blue-400 border-e-2" : ""
                          }`}
                          onClick={() => setChoosePage(key) & setRenderMenuState(false)}
                          >
                              {i18n.t(title)}
                          </li>
                      ))}
                    </ul>
                </div>
                )}
                <div 
                style={{minWidth: "95%"}}
                className='flex items-center flex-col'>
                  <div className='justify-start items-center self-start pl-[4%]
                  inline-flex my-2'>
                  <IoMdMenu
                  onClick={() => setRenderMenuState(true)}
                  size={40}
                  color='black'
                  className='bg-white inline-block self-start ml-[4%]
                  px-[4%] py-[2%] border border-solid border-gray-400 animate-fade-right animate-duration-300'/>
                  <h1 className={`font-title text-[100%] ml-4 opacity-0 ease-in-out duration-300
                  transition-opacity
                    ${titleVisible ? "opacity-100" : "opacity-0"}`}>Menu</h1>
                  </div>
                  <section
                  style={{
                    width: "95%",
                    minWidth: "95%"
                  }}
                  className="lg:w-[90%] flex flex-col
                  bg-slate-200 mb-[4%] lg:m-[5%] z-30 p-[4%] lg:p-16 border-slate-400 h-auto">
                  <Suspense 
                  fallback={<div>{t("Loading")}...</div>}>
                      {HandlePageShow()}
                  </Suspense>
                  </section>
                  </div>
            </div>
              :
            <div className="
            flex flex-col lg:flex-row justify-center lg:items-start items-center">
                <section 
                className=" flex flex-col text-left
                bg-slate-200 my-[5%] lg:m-[5%] h-fit box-content w-[95%] lg:w-auto">
                    {Object.entries(options).map(([key, { title }]) => (
                      <option 
                      key={key} 
                      className={`font-paragraph text-xl 
                          py-[3vh] px-[2vw] 
                          border-slate-300 border 
                          bg-slate-200 lg:text-[130%] text-[150%] cursor-pointer 
                          ease-in-out duration-300 transition-colors ${
                          choosePage === key ? "border-r-2 border-r-blue-400 border-e-2" : ""
                      }`}
                      onClick={() => setChoosePage(key)}
                      >
                          {i18n.t(title)}
                      </option>
                  ))}
                </section>
                <section
                className="w-[95%] lg:w-[90%]
                bg-slate-200 mb-[4%] lg:m-[5%] p-[4%] lg:p-16 border-slate-400 h-auto">
                <Suspense 
                fallback={<div>Loading...</div>}>
                    {HandlePageShow()}
                </Suspense>
                </section>
            </div>
              }
        </div>
        <div
        className={`${choosePage === null && ("w-full bottom-0")}`}>
        {showNavbar && (
          <Footer/>
        )}
        </div>
        </div>
    )
}

export default Dashboard;