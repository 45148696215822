import React, { useRef, useState, useEffect } from "react";
import "../index.css";
import NavbarButtons from "./NavbarButtons";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import Accordion from "./RenderComponents/Accordion";

export let navbarHeight;

function Navbar(props) {
  const {t} = useTranslation();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [showList, setShowList] = useState(false);
  const [string, setString] = useState("");
  const navbarRef = useRef(null);

  const handleInfoClick = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  useEffect(() => {
    if(navbarRef.current !== null) {
      navbarHeight = navbarRef.current.clientHeight;
    }
  }, [navbarRef])

  const { updateUserChoices } = props;

  const handleOptionClick = (string, option) => {
    updateUserChoices(string, option);
  };

  const blackTriangle = {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 15px 15px 15px',
    borderColor: 'transparent transparent black transparent',
  };

  return (
    <navbar
    ref={navbarRef} 
    className={`fixed max-w-screen w-full z-40 left-0 bg-white ` + props.customClass}>
      <div 
      className="flex w-[100vw] max-w-screen flex-col bg-white">
        <div className="flex w-full max-w-screen px-2 bg-white  mt-[4%] md:mt-[2%] mb-[2%] md:mb-[0px] justify-between">
          <div className="flex bg-white ml-[2%]">
            <div className="flex flex-col h-full bg-white justify-center">
              <h1 className="text-[80%] md:text-[150%] font-title animate-once animate-fade-down animate-duration-700 text-nowrap mb-0 lg:-mb-3 lg:text-6xl font-bold lg:leading-10">{t(props.title)}</h1>
              <p className="text-left hidden lg:inline text-xl font-paragraph animate-once animate-delay-700 animate-fade animate-duration-700 mt-4 lg:-mb-4">{t(props.subtitle)}</p>
            </div>
          </div>
          <div className="mr-[2%] justify-end">
            <NavbarButtons
            pontos ={props.moedas}
            showPoints={props.showPoints}
            />
            </div>
        </div>
      </div>
      <div id="data-accordion" className="flex flex-col items-center relative">
        <div id="info-button" onClick={handleInfoClick} className="cursor-pointer z-10 relative">
          <p className="font-bold gray-border letter-spaced font-paragraph text-lg px-5 py-0.2 bg-white
          border-[1px] border-solid border-gray-400">INFO</p>
        </div>
        <div id="gray-line" className="absolute w-[97%] h-[0.06rem] bg-gray-600 opacity-70 mt-3.5 -z-10"></div>
          <div className="">
          <Accordion 
          customClass={"mb-0"}
          isOpen={isAccordionOpen}>
            <div className="p-4 w-[95vw] text-center font-paragraph text-lg bg-cyan-100">
              <p>{t(props.description)}</p>
            </div>
          </Accordion>
          </div>
      </div>
      <div
    className="w-1/2 absolute left-6 text-left uppercase bottom-0 font-paragraph 
    font-bold overflow-hidden text-nowrap cursor-grab -mb-1">
        {(props.userChoices !== null && !isAccordionOpen) && (
        <Draggable
          axis="x" // Apenas movimento horizontal
          bounds={{left: -300, right: 100}}
        >
          <div>
        {(props.userChoices.imageCropper !== null && props.userChoices.imageCropper !== "") && (
        " | " + props.userChoices.imageCropper 
        )}
        {props.userChoices.aiModels !== undefined && (
            (props.userChoices.aiModels !== null && props.userChoices.aiModels !== "") && (
              " | " + props.userChoices.aiModels 
            )
        )}
        {props.userChoices.seed != undefined &&
        
        (props.userChoices.seed !== "" && props.userChoices.seed !== null) && (
          " | " + props.userChoices.seed
        )}
        {props.userChoices.type !== undefined &&
        (props.userChoices.type !== null) && (
          " | " + props.userChoices.type
        )}
        {(props.userChoices.whatInterior !== null && 
          props.userChoices.whatInterior !== undefined
        ) && 
        <div
          onClick={() => {setString("whatInteriors")
                         setShowList(true)}
          }
          className="hover:bg-black ease-in-out transition-colors 
          duration-500 hover:text-white">
        {(
          " | " + props.userChoices.whatInterior
        )}
        </div>
        }
        {(props.userChoices.what !== null) && (
         " | " +  props.userChoices.what
        )}
        {props.userChoices.size !== undefined && 
        props.userChoices.size !== null && (
          " | " +  props.userChoices.size
        )}
        {props.userChoices.time !== undefined &&
        props.userChoices.time !== null && (
          " | " + props.userChoices.time
        )}
        {props.userChoices.style !== undefined && (
          (props.userChoices.style !== null && (
            " | " + props.userChoices.style
          )
        ))}
        {props.userChoices.colorPalette !== undefined && (
          (props.userChoices.colorPalette !== null &&
            props.userChoices.colorPalette.length !== 0
          )&& (
            " | " + props.userChoices.colorPalette
          )
        )}
        {props.userChoices.whereExterior !== undefined &&
        props.userChoices.whereExterior !== null && (
          " | " + props.userChoices.whereExterior
        )}
        {props.userChoices.weather !== undefined && (
          (props.userChoices.weather !== null && (
            " | " + props.userChoices.weather
          )
        )
        )}
        {props.userChoices.visibility !== null && (
          " | " + props.userChoices.visibility
        )}
        {props.userChoices.precision !== undefined && props.userChoices.precision !== null && (
          " | " + props.userChoices.precision
        )}
        {props.userChoices !== null && (" |")}
        </div>
        </Draggable>
        )}
        </div>
    </navbar>
  );
}

Navbar.defaultProps = {
  userChoices: null,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque et gravida eros, sit amet ornare magna. Cras non diam a tortor placerat dapibus quis at urna. Donec accumsan convallis ante, at imperdiet lorem ultrices vitae. Aenean turpis nulla, sodales non tincidunt at, imperdiet eu lectus. Nam eget orci eget ex ornare gravida vitae ut odio. Duis sed consectetur justo, eu fermentum velit. Phasellus a augue accumsan, consequat velit at, elementum velit. Etiam vestibulum gravida sollicitudin. Ut eget gravida tellus. Suspendisse non ornare odio. Morbi sed arcu pulvinar risus rhoncus dictum ut ut nunc. Suspendisse aliquet semper sodales.',
  subtitle: 'Create amazing renderings fast',
  showPoints: false,
};

export default Navbar;