import { React, useState, useEffect } from "react";
import ImageUploader from "../components/RenderComponents/ImageUploader";
import ImageCropper from "../components/RenderComponents/ImageCropper";
import AIModels from "../components/RenderComponents/AIModels";
import Seed from "../components/RenderComponents/Seed";
import Type from "../components/RenderComponents/Type";
import CustomRange from "../components/RenderComponents/CustomRange.jsx";
// import WhatInterior from "../components/RenderComponents/WhatInterior";
import whatExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatExteriors.json";
import whatInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
import timeExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeExteriors.json";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import styleExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleExteriors.json";
import whereExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whereExteriors.json";
import weatherExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/weather.json";
// import Time from "../components/RenderComponents/Time";
// import Style from "../components/RenderComponents/Style";
import GenericRangeComponent from "../components/RenderComponents/GenericRangeComponent.jsx";
import shapeExposureJSON from "../components/RenderComponents/slidersParamJSONConfig/shapeExposure.json";
import inputDepthJSON from "../components/RenderComponents/slidersParamJSONConfig/inputDepth.json";
import ColorPalette from "../components/RenderComponents/ColorPalette";
import MaterialWalls from "../components/RenderComponents/Materials";
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import Visibility from "../components/RenderComponents/Visibility";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange.jsx";
import ShapeStrength from "../components/RenderComponents/ShapeStrength.jsx";
import RenderButton from "../components/RenderComponents/RenderButton";
import Navbar, { navbarHeight } from "../components/Navbar";
import Footer from "../components/Footer";
// import WhatExterior from "../components/RenderComponents/WhatExterior";
// import Floors from "../components/RenderComponents/Floors";
// import Where from "../components/RenderComponents/WhereExterior";
// import Weather from "../components/RenderComponents/Weather";
import Precision from "../components/RenderComponents/Precision.jsx";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { geraPrompt as geraPromptExteriorLandscapePro } from "../apiComfy/Sketch/Sketch_Exterior_Pro_Landscape.js";
import { geraPrompt as geraPromptExteriorSquarePro } from "../apiComfy/Sketch/Sketch_Exterior_Pro_Square.js";
import { geraPrompt as geraPromptExteriorPortraitPro } from "../apiComfy/Sketch/Sketch_Exterior_Pro_Portrait.js";

import { geraPrompt as geraPromptExteriorLandscapeStandart } from "../apiComfy/Sketch/Sketch_Exterior_Standart_Landscape.js";
import { geraPrompt as geraPromptExteriorSquareStandart } from "../apiComfy/Sketch/Sketch_Exterior_Standart_Square.js";
import { geraPrompt as geraPromptExteriorPortraitStandart } from "../apiComfy/Sketch/Sketch_Exterior_Standart_Portrait.js";

import { geraPrompt as geraPromptInteriorLandscapePro } from "../apiComfy/Sketch/Sketch_Interior_Pro_Landscape.js";
import { geraPrompt as geraPromptInteriorSquarePro } from "../apiComfy/Sketch/Sketch_Interior_Pro_Square.js";
import { geraPrompt as geraPromptInteriorPortraitPro } from "../apiComfy/Sketch/Sketch_Interior_Pro_Portrait.js";

import { geraPrompt as geraPromptInteriorLandscapeStandart } from "../apiComfy/Sketch/Sketch_Interior_Standart_Landscape.js";
import { geraPrompt as geraPromptInteriorSquareStandart } from "../apiComfy/Sketch/Sketch_Interior_Standart_Square.js";
import { geraPrompt as geraPromptInteriorPortraitStandart } from "../apiComfy/Sketch/Sketch_Interior_Standart_Portrait.js";
import ShapeExposure from "../components/RenderComponents/ShapeExposure.jsx";
import Accordion from "../components/RenderComponents/Accordion.jsx";
import { useTranslation } from "react-i18next";
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent.jsx";
import { t } from "i18next";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent.jsx";

export default function Sketch2RenderInteriorPage() {
  const standardColors = {
    active: "bg-[#fffe80] text-black",
    inactive: "bg-[#ffffb3] text-black",
    hover: "hover:bg-[#fffe80] hover:text-black",
    valor: 2,
  };

  const professionalColors = {
    active: "bg-[#fcac7f] text-black",
    inactive: "bg-[#fdd7bc] text-black",
    hover: "hover:bg-[#fcac7f] hover:text-black",
    valor: 3,
  };

  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };

  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });
  }, [i18n]);

  // Função para verificar se o token está presente e válido
  const token = Cookies.get("token");
  function verificarToken() {

    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const [userChoices, setUserChoices] = useState({
    imageUploader: null,
    imageCropper: null,
    aiModels: null,
    seed: null,
    type: null,
    what: null,
    whereExterior: null,
    time: null,
    weather: null,
    style: "Modern",
    colorPalette: null,
    materials: null,
    effects: null,
    visibility: "PUBLIC",
    precision: "STANDARD",
  });
  const [isInterior, setIsInterior] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [openAccordion1, setOpenAccordion1] = useState(null);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1040);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const updateUserChoices = (category, choice) => {
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };

  const { i18n } = useTranslation();
            
  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId; 
  
  const convertToJson = () => {

    
    //const user = Cookies.get("clientId");
    const nomeImage = `${user}_${Date.now()}.png`;
    sessionStorage.setItem("nomeImage", nomeImage);

    let geraPrompt;

    if (userChoices.type === "EXTERIORS") {
      if (userChoices.precision === "PROFESSIONAL") {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptExteriorLandscapePro(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptExteriorPortraitPro(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptExteriorSquarePro(
            userChoices,
            user,
            nomeImage
          );
        }
      } else {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptExteriorLandscapeStandart(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptExteriorPortraitStandart(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptExteriorSquareStandart(
            userChoices,
            user,
            nomeImage
          );
        }
      }
    } else {
      if (userChoices.precision === "PROFESSIONAL") {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptInteriorLandscapePro(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptInteriorPortraitPro(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptInteriorSquarePro(
            userChoices,
            user,
            nomeImage
          );
        }
      } else {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptInteriorLandscapeStandart(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptInteriorPortraitStandart(
            userChoices,
            user,
            nomeImage
          );
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptInteriorSquareStandart(
            userChoices,
            user,
            nomeImage
          );
        }
      }
    }

    const saida = geraPrompt;
    console.log(userChoices);
    sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));
  };

  useEffect(() => {
    document.title = "Sketch 2 Render - Arcseed AI";
    return () => {
      document.title = "Arcseed AI";
    };
  }, []);

  useEffect(() => {
    setOpenCustomization(false);
    setopenFinetuning(false);
  }, [userChoices.type]);

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };

  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
      window.innerWidth < 1024 ? 
        (
          <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
            <div className="flex">{items}</div>
            <div className="flex">{items}</div>
          </div>
        )
        :
        ( 
          <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
        )
      )
  };

  useEffect(() => {
    console.log(userChoices)
  }, [userChoices.precision])

  useEffect(() => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
  }, [])

  useEffect(() => {
    if(userChoices.type === "EXTERIORS") {
        setUserChoices((prevChoices) => ({
          ...prevChoices,
          InputDepthRange: userChoices.precision === "STANDARD" ? 
          "1" : "2"
        }))
    }
    else {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        InputDepthRange: null
      }))
    }
    console.log(userChoices.InputDepthRange)
  }, [userChoices.precision])

  const handleTypeChoose = () => {
    if (userChoices.type !== isInterior) {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        what: null,
        whereExterior: null,
        time: null,
        weather: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        effects: null,
        visibility: "PUBLIC",
        precision: "STANDARD",
      }));
      switch(userChoices.type) {
        case "INTERIORS": 
          setUserChoices(prevChoices => ({
            ...prevChoices,
            AIImmaginationRange: "2",
            ShapeStrengthRange: "2",
            ShapeExposureRange: "2",
            InputDepthRange: null  
          }));
          console.log(userChoices)
          console.log("interiors")
          break;
        case "EXTERIORS": 
          setUserChoices((prevChoices) => ({
            ...prevChoices,
            AIImmaginationRange: "2",
            InputDepthRange: userChoices.precision === "STANDARD" ? 
            "1" : "2",
            ShapeStrengthRange: "2",
            ShapeExposureRange: "2"
          }))
          console.log("exteriors")
          break;
        }
        setIsInterior(userChoices.type);
        setOpenAccordion(null);
        setOpenAccordion1(null);
    }
    if (userChoices.type === "INTERIORS") {
      return (
        <div>
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatInteriorsJSON}
          isOpen={true}/>
          {/* <WhatInterior updateUserChoices={updateUserChoices} /> */}
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={timeInteriorsJSON}
              isOpen={(openAccordion === "timeInteriors")}
              onToggle={() => handleAccordionToggle("timeInteriors")}/>
                {/* <Time
                  isOpen={openAccordion === "timeInterior"}
                  onToggle={() => handleAccordionToggle("timeInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mt-2">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={styleInteriorsJSON}
              isOpen={(openAccordion === "styleInteriors")}
              onToggle={() => handleAccordionToggle("styleInteriors")}/>
                {/* <Style
                  isOpen={openAccordion1 === "styleInterior"}
                  onToggle={() => handleAccordionToggle1("styleInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                </div>
            </div>
            )
            :
            (
            <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={timeInteriorsJSON}
              isOpen={(openAccordion === "timeInteriors")}
              onToggle={() => handleAccordionToggle("timeInteriors")}/>
                {/* <Time
                  isOpen={openAccordion === "timeInterior"}
                  onToggle={() => handleAccordionToggle("timeInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={styleInteriorsJSON}
              isOpen={(openAccordion1 === "styleInteriors")}
              onToggle={() => handleAccordionToggle1("styleInteriors")}/>
                {/* <Style
                  isOpen={openAccordion1 === "styleInterior"}
                  onToggle={() => handleAccordionToggle1("styleInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <MaterialWalls updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("Fine-tuning")}
            </h1>
          </div>
          <Accordion isOpen={openFinetuning}>
            <div className="w-full -mb-20">
              <ShapeExposure updateUserChoices={updateUserChoices} />
            </div>
            <div className="flex lg:flex-row flex-col mt-28 w-full">
              <div className="lg:w-1/2 w-full">
                <ShapeStrength updateUserChoices={updateUserChoices} />
              </div>
              <div className="lg:w-1/2 w-full">
                <AIImmaginationRange 
                initialValue={userChoices.AIImmaginationRange}
                updateUserChoices={updateUserChoices} />
              </div>  
            </div>
          </Accordion>
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={standardColors}
            professionalColors={professionalColors}
          />
          <RenderButton
            onRenderButtonClick={convertToJson}
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
          />
        </div>
      );
    } else if (userChoices.type === "EXTERIORS") {
      return (
        <div className="overflow-hidden">
          {/* <Floors updateUserChoices={updateUserChoices} /> */}
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatExteriorsJSON}
          isOpen={true}/>
          {/* <WhatExterior updateUserChoices={updateUserChoices} /> */}
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div
              id="horizontal-div"
              className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center"
            >
              {renderWallpaperImages()}
              {/* <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={(openAccordion === "timeExteriors")}
                onToggle={() => handleAccordionToggle("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExterior"}
                  onToggle={() => handleAccordionToggle("timeExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={(openAccordion === "whereExteriors")}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                </div>
                {/* <Where
                  isOpen={openAccordion === "whereExterior"}
                  onToggle={() => handleAccordionToggle("whereExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
              <div className="mb-2">
              <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherExteriorsJSON}
                isOpen={(openAccordion === "weatherExteriors")}
                onToggle={() => handleAccordionToggle("weatherExteriors")}
                />
                </div>
                {/* <Weather
                  isOpen={openAccordion1 === "weatherExterior"}
                  onToggle={() => handleAccordionToggle1("weatherExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleExteriorsJSON}
                isOpen={(openAccordion === "styleExteriors")}
                onToggle={() => handleAccordionToggle("styleExteriors")}
                />
                </div>
                {/* <Style
                  isOpen={openAccordion1 === "styleExterior"}
                  onToggle={() => handleAccordionToggle1("styleExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
            </div>
            )
            :
            (
            <div
              id="horizontal-div"
              className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center"
            >
              {renderWallpaperImages()}
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={(openAccordion === "timeExteriors")}
                onToggle={() => handleAccordionToggle("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExterior"}
                  onToggle={() => handleAccordionToggle("timeExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={(openAccordion === "whereExteriors")}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                {/* <Where
                  isOpen={openAccordion === "whereExterior"}
                  onToggle={() => handleAccordionToggle("whereExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="flex flex-col justify-center w-1/2">
              <div className="mb-2">
              <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherExteriorsJSON}
                isOpen={(openAccordion1 === "weatherExteriors")}
                onToggle={() => handleAccordionToggle1("weatherExteriors")}
                />
                </div>
                {/* <Weather
                  isOpen={openAccordion1 === "weatherExterior"}
                  onToggle={() => handleAccordionToggle1("weatherExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleExteriorsJSON}
                isOpen={(openAccordion1 === "styleExteriors")}
                onToggle={() => handleAccordionToggle1("styleExteriors")}
                />
                {/* <Style
                  isOpen={openAccordion1 === "styleExterior"}
                  onToggle={() => handleAccordionToggle1("styleExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <MaterialWalls updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("Fine-tuning")}
            </h1>
          </div>
          <Accordion isOpen={openFinetuning}>
            <div className="flex lg:flex-row flex-col justify-center mt-2 w-full">
              <div className="lg:w-1/2 w-full">
                <CustomRange
                  updateUserChoices={updateUserChoices}
                  title={"CONTOURS STRENGTH"}
                  startsAt={"2"}
                  maxRange={"3"}
                  infoBoxDescription={
                    "The countour strenght traces lines around the corners of the objects on the image, these lines then are taken as guides to create the render, the stronguer this is, more it will follow."
                  }
                  description={
                    "How much the AI will follow the contours of the input"
                  }
                  variableName={"ShapeStrengthRange"}
                  options={["Soft", "Moderate", "Strong"]}
                />

                {/* <ShapeStrength updateUserChoices={updateUserChoices} /> */}
              </div>
              <div className="lg:w-1/2 w-full">
              <GenericRangeComponent
              updateUserChoices={updateUserChoices}
              rangeParamJSON={shapeExposureJSON}/>
              {/* <ShapeExposure updateUserChoices={updateUserChoices} /> */}
              </div>
            </div>
            <div className="flex lg:flex-row flex-col mt-6 w-full">
              <div className="lg:w-1/2 w-full">
                 <CustomRange
                  updateUserChoices={updateUserChoices}
                  title={"INPUT DEPTH"}
                  startsAt={userChoices.precision === "STANDARD" ? 
                    "1" : "2"}
                  maxRange={"4"}
                  infoBoxDescription={
                    "How much the AI will try to keep the shape over trying to be creative."
                  }
                  description={
                    "How much should the AI extract the depth data of the input image"
                  }
                  variableName={"InputDepthRange"}
                  options={["1", "2", "3", "4"]}
                />
                {/* <InputDepth updateUserChoices={updateUserChoices} /> */}
              </div>
              <div className="lg:w-1/2 w-full">
                <AIImmaginationRange 
                initialValue={userChoices.AIImmaginationRange}
                updateUserChoices={updateUserChoices} />
              </div>
            </div>
          </Accordion>
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={standardColors}
            professionalColors={professionalColors}
          />
          <RenderButton
            onRenderButtonClick={convertToJson}
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
          />
        </div>
      );
    } else {
      return (
        <div className="flex w-full max-w-screen my-2 justify-center">
          <p className="font-paragraph px-6 lg:px-0 text-2xl">
            {i18n.t("Tip: select the ambient type to access the other inputs!")}
          </p>
        </div>
      );
    }
    
  };

  return (
    <div className="overflow-hidden relative">
      <NewPopUpComponent/>
      <Navbar
        userChoices={userChoices}
        title={t("Sketch 2 Render ")}
        description={i18n.t(
          "Create amazing renderings without any input image, just select the options and create mind blowing possibilities! if you are an advanced user click here for the advanced toolset, there all the generative pages will be in one place for fast editing and access."
        )}
        showPoints={true}
        moedas={moedas}
      />
      <div
      style={{
        marginTop: navbarHeight + 20
      }}>
        <ImageUploader
          updateUserChoices={updateUserChoices}
          subtitle={
            "You can take a screenshot (PrtScr no teclado) and paste it here with Ctrl+v, to crop it below."
          }
          title={"Drop the sketch or screenshot here"}
        />
      </div>
      <ImageCropper
        image={sessionStorage.getItem("imageUploader")}
        updateUserChoices={updateUserChoices}
      />
      <div className="flex lg:flex-row flex-col w-full py-8 items-center justify-center max-w-screen bg-transparent">
        <div className="w-1/3">
          <AIModels verticalSet={false} updateUserChoices={updateUserChoices} />
        </div>
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
        <div className="w-1/3 bg-transparent">
          <Seed updateUserChoices={updateUserChoices} />
        </div>
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
        <div className="w-1/3">
          <Type
            userChoice={userChoices.type}
            verticalSet={isSmallScreen}
            className="mt-8"
            updateUserChoices={updateUserChoices}
          />
        </div>
      </div>
      {handleTypeChoose()}
      <Footer footerVersion="light-footer" />
    </div>
  );
}
