import React, { useRef, useState } from "react";
import { useEffect } from "react";
import "../index.css";
import ImageCropper from "../components/RenderComponents/Format.jsx";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent.jsx";
// import Floors from "../components/RenderComponents/Floors";
// import WhatExterior from "../components/RenderComponents/WhatExterior";
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent.jsx";
import whatInteriorJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
import timeExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeExteriors.json";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import styleExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleExteriors.json";
import whatExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatExteriors.json"
import whereExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whereExteriors.json"
import weatherExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/weather.json"
// import Where from "../components/RenderComponents/WhereExterior";
// import Time from "../components/RenderComponents/Time";
// import Style from "../components/RenderComponents/Style";
import ColorPalette from "../components/RenderComponents/ColorPalette";
import Materials from "../components/RenderComponents/Materials";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange";
import RenderButton from "../components/RenderComponents/RenderButton";
import Navbar, { navbarHeight } from "../components/Navbar";
import Footer from "../components/Footer.jsx";
import AIModels from "../components/RenderComponents/AIModels";
import Seed from "../components/RenderComponents/Seed";
import Type from "../components/RenderComponents/Type";
//import { geraPrompt } from '../apiComfy/Brain.js';
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import Visibility from "../components/RenderComponents/Visibility";
import Precision from "../components/RenderComponents/Precision.jsx";
// import Weather from "../components/RenderComponents/Weather.jsx";
// Importar apenas os arquivos "Pro"
import { geraPrompt as geraPromptLandscapePro } from "../apiComfy/Brain/Brain_Landscape_Pro.js";
import { geraPrompt as geraPromptPortraitPro } from "../apiComfy/Brain/Brain_Portrait_Pro.js";
import { geraPrompt as geraPromptSquarePro } from "../apiComfy/Brain/Brain_Square_Pro.js";

import { geraPrompt as geraPromptSquareStandart } from "../apiComfy/Brain/Brain_Square_Standart.js";
import { geraPrompt as geraPromptLandscapeStandart } from "../apiComfy/Brain/Brain_Landscape_Standart.js";
import { geraPrompt as geraPromptPortraitStandart } from "../apiComfy/Brain/Brain_Portrait_Standart.js";
import Accordion from "../components/RenderComponents/Accordion.jsx";

export default function BrainstormRenderPage() {
  const standardColors = {
    active: "bg-[#a1ff80] text-black",
    inactive: "bg-[#d4ffb3] text-black",
    hover: "bg-[#a1ff80] text-black",
    valor: 1,
  };

  const professionalColors = {
    active: "bg-[#fffe80] text-black",
    inactive: "bg-[#ffffb3] text-black",
    hover: "bg-[#fffe80] text-black",
    valor: 2,
  };

  // Função para verificar se o token está presente e válido
  const token = Cookies.get("token");
  function verificarToken() {

    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
    window.innerWidth < 1024 ? 
      (
        <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
          <div className="flex">{items}</div>
          <div className="flex">{items}</div>
        </div>
      )
      :
      ( 
        <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
      )
    )
  };

  const { t } = useTranslation();
  const [userChoices, setUserChoices] = useState({
    imageCropper: null,
    aiModels: null,
    seed: null,
    type: null,
    what: null,
    whereExterior: null,
    time: null,
    style: "Modern",
    colorPalette: null,
    materials: null,
    visibility: "PUBLIC",
    precision: "STANDARD",
    AIImmaginationRange: "2",
  });

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };
  useEffect(() => {
    // Verifica o tamanho da tela e define o estado isSmallScreen
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Defina a largura limite para telas pequenas
    };

    // Executa a verificação no carregamento e quando o tamanho da tela muda
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Remove o event listener ao desmontar o componente
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const updateUserChoices = (category, choice) => {
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };

  const [openAccordion, setOpenAccordion] = useState("");
  const [openAccordion1, setOpenAccordion1] = useState("");

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };
  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId; 
  const convertToJson = () => {
    //const user = Cookies.get("clientId"); // Pega o user da sessão
    let geraPrompt;

    if (userChoices.precision === "PROFESSIONAL") {
      if (userChoices.imageCropper === "landscape") {
        geraPrompt = geraPromptLandscapePro(userChoices, user);
      } else if (userChoices.imageCropper === "portrait") {
        geraPrompt = geraPromptPortraitPro(userChoices, user);
      } else if (userChoices.imageCropper === "square") {
        geraPrompt = geraPromptSquarePro(userChoices, user);
      }
    } else {
      if (userChoices.imageCropper === "landscape") {
        geraPrompt = geraPromptLandscapeStandart(userChoices, user);
      } else if (userChoices.imageCropper === "portrait") {
        geraPrompt = geraPromptPortraitStandart(userChoices, user);
      } else if (userChoices.imageCropper === "square") {
        geraPrompt = geraPromptSquareStandart(userChoices, user);
      }
    }
    // Determina o prompt apropriado com base no imageCropper

    const saida = geraPrompt;
    console.log(userChoices);
    sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));
  };

  useEffect(() => {
    /* Função responsável pelo título das páginas */
    document.title = "Brainstorm Render - Arcseed AI";
    return () => {
      document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
    };
  }, []);

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });
    console.log(i18n.language)
  }, [i18n]);

  const [isInterior, setIsInterior] = useState(null);
  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  useEffect(() => {
    setopenFinetuning(false);
    setOpenCustomization(false);
    console.log(userChoices)
  }, [userChoices.type]);

  useEffect(() => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
  }, [])

  const handleTypeChoose = () => {
    //console.log("Valores antes do reset:", userChoices);
    /* verificação para resetar os parâmetros caso troque o type*/
    if (userChoices.type !== isInterior) {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        what: null,
        whatInterior: null,
        whereExterior: null,
        time: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        visibility: "PUBLIC",
        precision: "STANDARD",
        AIImmaginationRange: "2",
      }));
      setIsInterior(userChoices.type);
      //console.log("Valores após o reset:", userChoices);
    } /*vem até aqui, se estiver dando problema na saída do json, tem que tirar*/

    if (userChoices.type === "INTERIORS") {
      return (
        <div>
          {/* <WhatInterior updateUserChoices={updateUserChoices} /> */}
          <GenericRenderComponent
          isOpen={true}
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatInteriorJSON}/>
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
          {window.innerWidth < 1024 ? 
          (
          <div className="flex w-full h-[700px] bg-[#f3f4f6] 
          flex-col max-w-screen justify-center relative items-center">
          {renderWallpaperImages()}
                <div
                className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeInteriorsJSON}
                isOpen={openAccordion === "time"}
                onToggle={() => handleAccordionToggle("time")}
                />
                </div>
                <div>
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleInteriorsJSON}
                isOpen={openAccordion === "style"}
                onToggle={() => handleAccordionToggle("style")}
                />
                </div>
          </div>
          )
        :
        (
          <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
            {renderWallpaperImages()}
            <div className="w-1/2">
              {/* <Time
                isOpen={openAccordion === "timeInteriors"}
                onToggle={() => handleAccordionToggle("timeInteriors")}
                updateUserChoices={updateUserChoices}
              /> */}
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={timeInteriorsJSON}
              isOpen={(openAccordion === "time")}
              onToggle={() => handleAccordionToggle("time")}
              />
            </div>
            <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
            <div className="w-1/2">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={styleInteriorsJSON}
              isOpen={(openAccordion1 === "style")}
              onToggle={() => handleAccordionToggle1("style")}
              />
            </div>
          </div>
        )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion isOpen={openFinetuning}>
            <AIImmaginationRange 
            initialValue={userChoices.AIImmaginationRange}
            updateUserChoices={updateUserChoices} />
          </Accordion>
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={standardColors}
            professionalColors={professionalColors}
          />
          <RenderButton
            onRenderButtonClick={convertToJson}
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
          />
        </div>
      );
    } else if (userChoices.type === "EXTERIORS") {
      return (
        <div>
          {/* <Floors updateUserChoices={updateUserChoices} /> */}
          {/* <WhatExterior updateUserChoices={updateUserChoices} /> */}
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatExteriorsJSON}
          isOpen={true}/>
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
            <div className="flex w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center flex-col">
            {/* VERSÃO DE CELULAR */}
            {renderWallpaperImages()}
            {/* <Where
              isOpen={openAccordion === "whereExteriors"}
              onToggle={() => handleAccordionToggle("whereExteriors")}
              updateUserChoices={updateUserChoices}
            /> */}
                <div 
                className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={(openAccordion === "whereExteriors")}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                </div>
                <div 
                className="mb-2">
                {/* <Weather
                  isOpen={openAccordion === "weather"}
                  onToggle={() => handleAccordionToggle("weather")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherExteriorsJSON}
                isOpen={(openAccordion === "weather")}
                onToggle={() => handleAccordionToggle("weather")}
                />
                </div>
                 <div 
                 className="mb-2">
                 {/* <Time
                  isOpen={openAccordion1 === "timeExteriors"}
                  onToggle={() => handleAccordionToggle1("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={(openAccordion === "time")}
                onToggle={() => handleAccordionToggle("time")}
                />
                </div>
                {/* <Style
                  isOpen={openAccordion1 === "styleExteriors"}
                  onToggle={() => handleAccordionToggle1("styleExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleExteriorsJSON}
                isOpen={openAccordion === "style"}
                onToggle={() => handleAccordionToggle("style")}
                />
            </div>
            )
            :
            (
              <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {/* VERSÃO DE PC */}
              {renderWallpaperImages()}
              <div className="w-1/2">
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={(openAccordion === "whereExteriors")}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                </div>
                {/* <Where
                  isOpen={openAccordion === "whereExteriors"}
                  onToggle={() => handleAccordionToggle("whereExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherExteriorsJSON}
                isOpen={(openAccordion === "weather")}
                onToggle={() => handleAccordionToggle("weather")}
                />
                {/* <Weather
                  isOpen={openAccordion === "weather"}
                  onToggle={() => handleAccordionToggle("weather")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="w-1/2">
              <div className="mb-2">
              <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={(openAccordion1 === "time")}
                onToggle={() => handleAccordionToggle1("time")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion1 === "timeExteriors"}
                  onToggle={() => handleAccordionToggle1("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleExteriorsJSON}
                isOpen={openAccordion1 === "style"}
                onToggle={() => handleAccordionToggle1("style")}
                />
                {/* <Style
                  isOpen={openAccordion1 === "styleExteriors"}
                  onToggle={() => handleAccordionToggle1("styleExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion isOpen={openFinetuning}>
            <AIImmaginationRange 
            initialValue={userChoices.AIImmaginationRange}
            updateUserChoices={updateUserChoices} />
          </Accordion>
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={standardColors}
            professionalColors={professionalColors}
          />
          <RenderButton
            onRenderButtonClick={convertToJson}
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
          />
        </div>
      );
    } else if (userChoices.type === null) {
      return (
        <div className="flex w-full max-w-screen my-2 justify-center">
          <p className="font-paragraph px-6 lg:px-0 text-2xl">
            {t(
              "Tip: select the ambient type to access the other inputs!"
            )}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="overflow-hidden relative">
      <NewPopUpComponent/>
        <Navbar
          title={t("Brainstorm Gen ")}
          userChoices={userChoices}
          description={t("Create amazing renderings without any input image, just select the options and create mind blowing possibilities! if you are an advanced user click here for the advanced toolset, there all the generative pages will be in one place for fast editing and access.")}
          showPoints={true}
          moedas={moedas}
        />
      <div
      style={{
        marginTop: navbarHeight + 25
      }}
      >
        <ImageCropper 
        updateUserChoices={updateUserChoices}/>
      </div>
      <div className={`mt-[${navbarHeight}px]`}>
      </div>
      <div className="flex lg:flex-row flex-col w-full py-8 items-center justify-center max-w-screen">
        <div className="w-1/3">
          <AIModels
            verticalSet={isSmallScreen}
            updateUserChoices={updateUserChoices}
          />
        </div>
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
        <div className="w-1/3">
          <Seed updateUserChoices={updateUserChoices} />
        </div>
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
        <div className="w-1/3">
          <Type
          userChoice={userChoices.type}
            verticalSet={isSmallScreen}
            className="mt-8"
            updateUserChoices={updateUserChoices}
          />
        </div>
      </div>
      {handleTypeChoose()}
      <Footer footerVersion="light-footer" renderTitle="BRAINSTORM GEN" />
    </div>
  );
}
