import React from "react";
import Footer from "../Footer";
import NavbarButtons from "../NavbarButtons";
import { useTranslation } from "react-i18next";

export default function Privacy() {
  const { t } = useTranslation();

  return (
    <>
      <main>
        <div className="relative pt-16 pb-32 min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://www.studiomies.com.br/galeria/news/G/121_01_vienna_garden_vila_formosa_projeto_arquitetura_residencial_apartamento_design_interiores.jpg",
            }}
          >
            <div
              id="blackOverlay"
              className="w-full h-full absolute top-0 left-0 opacity-75 bg-black"
              style={{ zIndex: 10 }}
            ></div>
          </div>
          <div id="buttons-flex" className="absolute top-0 right-0 m-4 z-50">
            <NavbarButtons />
          </div>
          <div className="container relative mx-auto z-20">
            <div className="items-center text-centerflex justify-center flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                
                  <h1 className="text-white font-title font-semibold text-5xl">
                    {t("Política de Privacidade")}
                  </h1>
                  <p className="font-paragraph mt-4 text-lg text-white">
                    {t(
                      "Estes termos regem nossa política de privacidade e seu uso de nossos serviços e plataformas. Ao acessar ou utilizar nossos serviços, você concorda em cumprir esses termos. Aqui, detalhamos suas responsabilidades, direitos e as regras que regem nossa relação em relação à privacidade e proteção de dados. Recomendamos que leia atentamente antes de prosseguir. Se tiver dúvidas sobre como tratamos seus dados ou sobre nossa política de privacidade, entre em contato conosco"
                    )}
                    .
                  </p>
                
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <div className="container mx-auto mt-40">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full lg:w-8/12">
              <h3 className="font-semibold font-title text-3xl text-center mb-8">
                {t("Política de Privacidade")}
              </h3>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                {t(
                  "Esta Política de Privacidade tem como finalidade descrever as regras de utilização dos dados coletados na plataforma"
                )}
                <strong className="font-semibold">{t(" ARCSEED AI ")}</strong>
                {t(
                  "pertencentes ao USUÁRIOS, por medida de transparência e respeito aos dados pessoais de cada USUÁRIO."
                )}
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                {t(
                  "Tais regras não se aplicam aos produtos e serviços anunciados por terceiros dentro da plataforma, não havendo responsabilização da"
                )}
                <strong className="font-semibold">{t(" ARCSEED AI ")}</strong>
                {t(
                  "pela eventual coleta de dados realizada por terceiros independentes."
                )}
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                {t(
                  "Por fim, as regras encontram-se embasadas e respaldadas conforme a Lei Geral de Proteção de Dados (Lei n°. 13.709, de 14 de agosto de 2018), devendo o consentimento do USUÁRIO ser livre e espontâneo."
                )}
              </p>
              <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4 font-paragraph">
                A <strong className="font-semibold">{t(" ARCSEED AI ")}</strong>
                coletará dados pessoais e dados anonimizados, tendo você,
                USUÁRIO, direito ao acesso às informações sobre como tais dados
                serão tratados, incluindo a finalidade específica, a forma e
                duração do tratamento, a identificação e as informações do
                controlador, sendo este quem toma as decisões referentes ao
                tratamento, as informações quanto ao uso compartilhado dos seus
                dados e as responsabilidades dos agentes que realizarão o
                tratamento bem como informações sobre seus direitos, como
                titular de tais dados.
              </p>
              <h4 className=" font-title font-semibold font-title text-2xl">
                Sumário
              </h4>
              <ul className="font-paragraph">
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">I. </strong>Acesso e Escolha
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">II. </strong>Definições
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">III. </strong>Informações
                  Pessoais que Coletamos
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">IV. </strong>Como é
                  Realizada a Coleta das Informações Pessoais do Usuário?
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">V. </strong>Finalidade da
                  Coleta de Dados
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">VI. </strong>O Tratamento
                  das Informações Pessoais Coletadas
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">VII. </strong>Como
                  Garantimos a Segurança das Informações Pessoais Coletadas?
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">VIII. </strong>Quando
                  Compartilhamos as Informações Pessoais Coletadas?
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">IX. </strong>Localização das
                  Informações Pessoais Coletadas
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">X. </strong>Consentimento do
                  Usuário
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">XI. </strong>Direitos do
                  Usuário
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">XII. </strong>Retenção das
                  Informações Pessoais Coletadas
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">XIII. </strong>Cookies
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">XIV. </strong>Publicidade na
                  Internet e Terceiros
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">XV. </strong>Cuidados de
                  Segurança para Usuários
                </li>
                <li className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  <strong className="font-semibold">XVI. </strong>Contatos,
                  Notificações e Revisões
                </li>
              </ul>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                I. Acesso e Escolha
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Ao acessar a plataforma{" "}
                <strong className="font-semibold">ARCSEED AI</strong>, você,
                USUÁRIO, está concordando com o que está disposto nesta Política
                e nos Termos e Condições de Uso, entre outras políticas, que
                regem este domínio. Portanto, leia tais documentos com atenção.
                É de sua responsabilidade acompanhar as eventuais alterações que
                possam ser feitas em tais documentos, disponíveis neste mesmo
                local.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Caso você, USUÁRIO, sinta a necessidade de visualizar, atualizar
                e até mesmo excluir definitivamente informações que tenham sido
                fornecidas por você à{" "}
                <strong className="font-semibold">ARCSEED AI</strong>, poderá
                exercer seu direito entrando em contato com o Serviço de
                Atendimento ao Usuário (SAC), por meio do e-mail contato@arcseedai.com.br e pelo
                chat do WhatsApp no número +55 34 9842-8688. A{" "}
                <strong className="font-semibold">ARCSEED AI</strong> se
                compromete a atender sua demanda em até 30 (trinta) dias.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Entretanto, a{" "}
                <strong className="font-semibold">ARCSEED AI</strong> ressalta
                que o não fornecimento das informações não afetará de forma
                injustificada a utilização dos serviços básicos do Site, mas
                poderá impactar o funcionamento e até mesmo a disponibilidade de
                funcionalidades mais complexas e que dependem exclusivamente da
                cedência do USUÁRIO de tais dados.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                II. Definições
              </h4>
              <ul className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li>
                  <strong className="font-semibold">Usuário:</strong> qualquer
                  pessoa natural, sendo cliente, consumidor ou parceiro
                  comercial, que fornece dados para utilização da plataforma
                  online da{" "}
                  <strong className="font-semibold">ARCSEED AI</strong>, sendo
                  esta, Titular dos dados fornecidos;
                </li>
                <li>
                  <strong className="font-semibold">Controlador:</strong> é a
                  quem compete as decisões referentes ao tratamento de dados
                  pessoais;
                </li>
                <li>
                  <strong className="font-semibold">Operador:</strong> é quem
                  possui contato direto com os dados coletados;
                </li>
                <li>
                  <strong className="font-semibold">Dados pessoais:</strong> são
                  os dados identificadores de um determinado usuário, tais como,
                  mas não se limitando a: nome, endereço eletrônico, telefone;
                </li>
                <li>
                  <strong className="font-semibold">Dados de navegação:</strong>{" "}
                  tais como, mas não se limitando a: login e senha de acesso,
                  páginas de produtos visualizados, tempo de permanência em
                  determinado site;
                </li>
                <li>
                  <strong className="font-semibold">Cookies:</strong> são
                  pequenos arquivos de dados enviados pelo site ao navegador
                  quando você, o usuário, visita. É necessário destacar que o
                  objetivo é apenas identificar preferências de utilização que
                  nos ajudam a oferecer ao usuário, recomendações e experiências
                  personalizadas. Para maiores esclarecimentos sobre o uso de
                  COOKIES, leia atentamente o ANEXO 01 desta Política.
                </li>
              </ul>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                III. Informações Pessoais que Coletamos
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Visando a melhor experiência e a garantia de segurança jurídica
                dos envolvidos no uso do site para a criação de imagens de forma
                gratuita ou paga e para garantir a segurança do USUÁRIO ao
                assinar o plano pago ofertado, informamos que são coletadas
                alguns dados pessoais dos USUÁRIOS, sendo eles:
              </p>
              <ul className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li>
                  <strong className="font-semibold">
                    a. Informações Fornecidas Pelos Usuários:
                  </strong>{" "}
                  ao realizar o cadastro na plataforma
                  <strong className="font-semibold">ARCSEED AI</strong>,
                  coletamos seu nome, endereço de e-mail e telefone para
                  contato.
                </li>
                <li>
                  <strong className="font-semibold">
                    b. Informações automáticas:
                  </strong>{" "}
                  além das informações descritas acima, ainda coletamos de forma
                  automática outras informações como: endereço de IP (com data e
                  hora), origem do IP, características do dispositivo de acesso,
                  informações sobre cliques, páginas acessadas em nossa
                  plataforma e após saída do site, buscas realizadas, entre
                  outras.
                </li>
                <li>
                  <strong className="font-semibold">
                    c. Informações de pagamento:
                  </strong>{" "}
                  ao efetuar o pagamento do plano pago à “Stripe” (plataforma
                  que permite os pagamentos pela internet), são coletados seus
                  dados de transação.
                </li>
              </ul>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                IV. Como é Realizada a Coleta das Informações Pessoais do
                Usuário?
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A coleta dos dados pessoais discriminados na Terceira Cláusula
                desta Política, chamada ‘Informações Pessoais que Coletamos’ se
                dá da seguinte forma:
              </p>
              <ul className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li>
                  <strong className="font-semibold">
                    a. Informações fornecidas pelos usuários:
                  </strong>{" "}
                  são coletadas no momento de cadastro na plataforma, sendo
                  inseridas pelo{" "}
                  <strong className="font-semibold">USUÁRIO</strong>. Tais
                  informações garantem o acesso do{" "}
                  <strong className="font-semibold">USUÁRIO</strong> a todas as
                  funcionalidades e serviços gratuitos do Site.
                </li>
                <li>
                  <strong className="font-semibold">
                    b. Informações automáticas:
                  </strong>{" "}
                  sua coleta se dá de forma automática. No Décimo Terceiro
                  Capítulo desta Política, chamado ‘Cookies’, consta com qual
                  finalidade esses dados são coletados.
                </li>
                <li>
                  <strong className="font-semibold">
                    c. Informações de outros meios:
                  </strong>{" "}
                  utilizamos o software “Stripe”, o qual permite que as
                  transações financeiras sejam realizadas dentro do Site.
                </li>
              </ul>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                V. Finalidade da Coleta de Dados
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Entende-se por finalidades do tratamento dos dados pessoais os
                objetivos ou propósitos para os quais os dados são processados,
                que devem ser legítimos, específicos, explícitos e informados ao
                Titular, sendo vedado o tratamento para qualquer outra
                finalidade que não couber nessas.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A <strong className="font-semibold">ARCSEED AI</strong> coletará
                dados pessoais e demais dados, com intuito único e exclusivo de{" "}
                <span style={{ textDecoration: "underline" }}>
                  utilizá-los em campanhas e promoções, além de otimizar sua
                  experiência em nosso site, proporcionando a melhoria na
                  criação de serviços e produtos
                </span>
                .
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                O USUÁRIO terá o direito ao acesso às informações sobre como
                tais dados serão tratados, incluindo a finalidade do tratamento,
                a forma e duração do tratamento, a identificação e as
                informações do Controlador, as informações quanto ao uso
                compartilhado dos seus dados e as responsabilidades dos agentes
                que realizarão o tratamento bem como informações sobre seus
                direitos como Titular de tais dados.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                VI. O Uso e Tratamento das Informações Pessoais Coletadas
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Os Dados de Usuário são utilizados pela{" "}
                <strong className="font-semibold">ARCSEED AI</strong> e
                compartilhado por essa com parceiros (nacionais ou
                internacionais) nas possíveis seguintes situações:
              </p>
              <ul className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li>
                  <strong className="font-semibold">a.</strong> De forma
                  criptografada, quando os dados devem ser transmitidos para um
                  parceiro de forma segura e de acordo com padrões de
                  criptografia (ex: captura e transmissão de dados de
                  pagamento);
                </li>
                <li>
                  <strong className="font-semibold">b.</strong> O USUÁRIO será
                  notificado caso ocorram situações que requeiram informações
                  pessoais a seu respeito diversas das mencionadas no item
                  anterior e, então, terá a oportunidade de escolher sobre
                  compartilhar ou não os dados;
                </li>
                <li>
                  <strong className="font-semibold">c.</strong> Operações de
                  Marketing e Anúncios: a{" "}
                  <strong className="font-semibold">ARCSEED AI</strong> poderá
                  utilizar os Dados Pessoais do USUÁRIO, por si ou por seus
                  subcontratados, para fornecer a você materiais promocionais
                  relativos aos nossos serviços, bem como, utilizar tecnologias
                  de publicidade de terceiros para veicular anúncios quando você
                  acessar o Site. Tal tecnologia utiliza suas informações com
                  relação ao seu uso dos serviços para atendê-lo com anúncios
                  direcionados às suas preferências.
                </li>
              </ul>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                As informações coletadas são manuseadas pelo operador e
                controlador. Sendo o proprietário e a equipe de marketing
                responsáveis pelas funções do operador. Ademais, a{" "}
                <strong className="font-semibold">ARCSEED AI</strong> será a
                controladora dos dados pessoais do USUÁRIO no âmbito da relação
                estabelecida com você.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                VII. Como Garantimos a Segurança das Informações Pessoais
                Coletadas?
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Para a proteção de dados, a{" "}
                <strong className="font-semibold">ARCSEED AI</strong> conta com
                os sistemas de segurança seguintes:
              </p>
              <ul className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li>
                  <strong className="font-semibold">a.</strong> Criptografia de
                  senhas;
                </li>
                <li>
                  <strong className="font-semibold">b.</strong> Verificação
                  auto-hospedada;
                </li>
                <li>
                  <strong className="font-semibold">c.</strong> Outros tipos de
                  certificações de segurança.
                </li>
              </ul>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Os dados fornecidos por você, USUÁRIO, são armazenados em um
                banco de dados, o MySQL, o qual conta com os sistema de
                segurança citados, garantindo que não seja violada a sua
                intimidade e privacidade. Porém, considerando que sistemas de
                segurança também estão suscetíveis a falhas, a plataforma
                <strong className="font-semibold">ARCSEED AI</strong> não se
                responsabiliza por danos e/ou prejuízos eventuais decorrente de
                invasões, falhas ou vírus sobre o banco de dados, excetos casos
                em que a falha advir de culpa e dolo desta.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                VIII. Quando Compartilhamos as Informações Pessoais Coletadas?
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Consideramos a segurança das informações sobre nossos clientes
                importante parte do negócio. Não condiz com nossas atividades a
                venda de informações pessoais de nossos USUÁRIOS a terceiros,
                sendo essa prática declaradamente proibida. É incondizente
                também com o nosso negócio, a utilização dos dados do USUÁRIO em
                situações que se desviem da finalidade com a qual o titular
                deles concedeu-os.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Sendo assim, a{" "}
                <strong className="font-semibold">ARCSEED AI</strong>{" "}
                compartilha em casos específicos, seguindo a responsabilidade e
                as diretrizes dessa Política de Privacidade, os dados coletados,
                nas seguintes situações:
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <strong className="font-semibold">
                  Proteção da ARCSEED AI e outros:
                </strong>{" "}
                a divulgação de informações pessoais e da conta do USUÁRIO é
                feita quando for conveniente para:
              </p>
              <ul className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li>
                  <strong className="font-semibold">a.</strong> O cumprimento da
                  legislação brasileira vigente;
                </li>
                <li>
                  <strong className="font-semibold">b.</strong> Fazer valer e/ou
                  aplicar nossos Termos e acordos;
                </li>
                <li>
                  <strong className="font-semibold">c.</strong> Proteger os
                  direitos, bens e a segurança da{" "}
                  <strong className="font-semibold">ARCSEED AI</strong>, dos
                  nossos clientes ou terceiros;
                </li>
                <li>
                  <strong className="font-semibold">d.</strong> A prevenção e
                  detecção de fraude e redução de risco de crédito, através da
                  troca de informações com outras empresas e organizações;
                </li>
                <li>
                  <strong className="font-semibold">e.</strong> Transações e
                  alterações Societárias.
                </li>
              </ul>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <strong className="font-semibold">Sua opção:</strong> Você será
                notificado caso ocorram situações que requeiram informações
                pessoais a seu respeito diversas das mencionadas no item
                anterior e então terá a oportunidade de escolha sobre
                compartilhar ou não os dados.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                IX. Localização de Informações Pessoais
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A <strong className="font-semibold"> ARCSEED AI</strong>{" "}
                situa-se na Rua Dom Pedro II, nº. 763, Bairro Jardim Ponta
                Grossa, CEP 86805260, Apucarana, Paraná, Brasil. Entretanto, se
                houver transferência das informações para outras localidades,
                garantimos que as informações sejam transferidas de acordo com
                esta Política de Privacidade e conforme permitido pela Lei Geral
                de Proteção de Dados (Lei n°. 13.709, de 14 de agosto de 2018).
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                X. Retenção de Informações Pessoais
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                O período de armazenamento de informações pessoais respeitará
                sua autorização, como USUÁRIO e titular do dado, e a
                concretização da finalidade da coleta. Portanto, poderemos
                guardar suas informações legais pelo período de tempo que for
                necessário para possibilitar a melhor experiência ao uso
                contínuo dos serviços da{" "}
                <strong className="font-semibold"> ARCSEED AI</strong> e para
                cumprir os objetivos relevantes descritos nesta Política de
                Privacidade, conforme venha a ser exigido por Lei (incluindo
                para fins fiscais e contábeis), ou conforme lhe for comunicado
                de outra maneira. Também resguardamos o direito de armazenar
                tais dados por conta de aspectos judiciais que possam ocorrer.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Caso USUÁRIO deseje solicitar a exclusão de seus dados, isso
                será possível mediante o envio de e-mail para contato@arcseedai.com.br.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XI. Consentimento do Usuário
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Ao se cadastrar como USUÁRIO pelo site da{" "}
                <strong className="font-semibold"> ARCSEED AI</strong>, este
                expressamente concorda que seus Dados:
              </p>
              <ul className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li>
                  <strong class="font-semibold">a.</strong> Sejam coletados nas
                  formas previstas nesta Política;
                </li>
                <li>
                  <strong class="font-semibold">b.</strong> Sejam utilizados
                  para personalização de sua experiência no domínio da{" "}
                  <strong class="font-semibold">ARCSEED AI</strong>;
                </li>
                <li>
                  <strong class="font-semibold">c.</strong> Sejam incluídos em
                  lista de recebimento de comunicações, incluindo ofertas e
                  promoções;
                </li>
                <li>
                  <strong class="font-semibold">d.</strong> Sejam utilizados de
                  forma anônima para estudos ou pesquisas estatísticas;
                </li>
                <li>
                  <strong class="font-semibold">e.</strong> Sejam armazenados em
                  Banco de Dados seguro para otimização de acessos futuros;
                </li>
              </ul>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XII. Direitos do Usuário
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                São direitos facultados ao USUÁRIO:
              </p>
              <ul class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4 font-paragraph">
                <li>
                  <strong class="font-semibold">a.</strong> Receber confirmação
                  de que as informações pessoais sobre você, USUÁRIO, estão ou
                  não estão sendo processadas;
                </li>
                <li>
                  <strong class="font-semibold">b.</strong> Acessar os seus
                  Dados Pessoais armazenados, juntamente com informações
                  complementares;
                </li>
                <li>
                  <strong class="font-semibold">c.</strong> Receber uma cópia
                  dos Dados Pessoais e informações não pessoais, que você, de
                  forma voluntária e direta, nos forneceu em um formato legível
                  por máquina, usado comumente e estruturado;
                </li>
                <li>
                  <strong class="font-semibold">d.</strong> Solicitar correção
                  de seus Dados Pessoais que estão sob nosso controle;
                </li>
                <li>
                  <strong class="font-semibold">e.</strong> Solicitar a
                  eliminação, anonimização, ou bloqueio de seus Dados Pessoais;
                </li>
                <li>
                  <strong class="font-semibold">f.</strong> Revogar o
                  consentimento da realização do tratamento de seus Dados
                  pessoais feito por nós;
                </li>
                <li>
                  <strong class="font-semibold">g.</strong> Solicitar restrição
                  ao processamento de seus Dados pessoais e informações não
                  pessoais;
                </li>
                <li>
                  <strong class="font-semibold">h.</strong> Realizar reclamação
                  junto a uma autoridade de supervisão.
                </li>
              </ul>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XIII. Cookies
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Com a finalidade de reconhecer um visitante constante e melhorar
                sua experiência, a{" "}
                <strong className="font-semibold"> ARCSEED AI</strong> utiliza a
                administração de cookies. Os cookies são pequenos arquivos de
                dados enviados pelo site ao navegador na primeira vez que você o
                visita e armazenados no disco no computador. É necessário
                destacar que não há o armazenamento de dados pessoais, apenas de
                preferências de utilização que nos ajudam a oferecer a você,
                USUÁRIO, recomendações e experiências personalizadas.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Os dados coletados através dos cookies constam no (Terceiro)
                Capítulo dessa Política nomeado ‘Informações Pessoais que
                Coletamos - Informações Automáticas’. Eles são utilizados para a
                autenticação do USUÁRIO, para a segurança e integridade do site,
                para análise e pesquisa de preferências entre outras
                funcionalidades. Nenhuma das funcionalidades, no entanto, tem
                como consequência prejudicar o USUÁRIO.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                É possível gerenciar a utilização dos cookies através dos
                principais navegadores de internet. Caso queira restringir,
                desabilitar ou remover a utilização dos cookies ou cache, siga
                os procedimentos indicados pelos fabricantes para cada
                navegador, nos links conforme indicado no{" "}
                <strong className="font-semibold">ANEXO I</strong> desta
                Política.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XIV. Publicidade na Internet e Terceiros
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A plataforma da{" "}
                <strong className="font-semibold"> ARCSEED AI</strong> poderá
                conter publicidade, links ou frames de outros sites e
                aplicativos que podem ou não ser nossos parceiros e poderão
                coletar informações sobre interações com o conteúdo, com a
                publicidade e com os serviços. A{" "}
                <strong className="font-semibold"> ARCSEED AI</strong> se isenta
                da responsabilidade por qualquer informação, materiais,
                produtos, serviços, Políticas de Privacidade ou práticas
                comerciais adotadas por terceiros.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XV. Cuidado de Segurança para Usuários e Anunciantes
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <strong className="font-semibold">
                  A. No uso de computadores:
                </strong>{" "}
                é importante que o USUÁRIO proteja suas informações contra
                acesso não autorizado ao seu computador, conta ou senha. O
                USUÁRIO deve se certificar de sempre clicar em “sair” ao
                encerrar sua navegação em um computador compartilhado. Além
                disso, recomenda-se que não sejam utilizadas senhas óbvias, como
                sequência de números e letras (exemplo: abc123, 123456, abcd
                etc.), que a senha seja trocada regularmente e nunca
                compartilhada com terceiros. Para sua segurança digital, também
                recomenda-se o uso somente de programas e sistemas originais,
                mantidos sempre atualizados quanto às correções de segurança do
                sistema operacional dos dispositivos e o uso de programas de
                antivírus e anti-malware.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <strong className="font-semibold">
                  B. Mensagens Eletrônicas:
                </strong>{" "}
                em relação a mensagens eletrônicas (e-mails), é importante
                verificar o seu conteúdo antes de adicionar qualquer endereço na
                web. Desconfie de e-mails que contenham um cabeçalho e campo de
                remetente suspeitos ou com conteúdo estranho, mensagens que
                possuam erros de português ou até mesmo fotografias e logos com
                falha.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XVI. Contatos, Notificações e Revisões
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Em relação às eventuais dúvidas que surgirem quanto a Política
                de Privacidade e ao uso de dados da plataforma{" "}
                <strong className="font-semibold"> ARCSEED AI</strong>, pedimos
                que entre em contato com a Central de Atendimento da{" "}
                <strong className="font-semibold"> ARCSEED AI</strong> com uma
                descrição minuciosa para solução, por meio do e-mail contato@arcseedai.com.br e
                pelo chat do WhatsApp no número +55 34 9842-8688.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Por fim, resta informar que a presente Política de Privacidade
                poderá passar por mudanças a medida que se fizer necessário,
                visando acompanhar fielmente as necessidades da plataforma,
                frente a legislação que diz respeito ao direito digital, ao
                direito da Internet e à proteção de dados do USUÁRIO, e que suas
                eventuais atualizações ficarão disponíveis na plataforma para
                consulta do USUÁRIO e um e-mail será enviado aos USUÁRIOS de
                forma que todos se mantenham informados a respeito de tais
                atualizações.
              </p>

              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Última atualização em : 12 de março de 2024
              </h4>
              <br />
              <br />
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                ANEXO I
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Apresenta os links respectivos à desativação, restrição ou
                exclusão dos cookies utilizados pela{" "}
                <strong className="font-semibold"> ARCSEED AI</strong>, como
                indicado no capítulo “Cookies”, nesta Política de Privacidade.
                Salienta, ainda, que não se responsabiliza pela perdas de
                informações, pelo uso deste procedimento, visto que são
                fornecidos pelos serviços oficiais dos navegadores.
              </p>
              <ul class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4 font-paragraph">
                <li>
                  <strong class="font-semibold">
                    a. Caso você esteja utilizando o Google Chrome:
                  </strong>
                  <a href="https://support.google.com/chrome/answer/95647?hl=pt-BR">
                    (https://support.google.com/chrome/answer/95647?hl=pt-BR)
                  </a>
                </li>
                <li>
                  <strong class="font-semibold">
                    b. Caso você esteja utilizando o Firefox:
                  </strong>
                  <a href="https://support.mozilla.org/pt-BR/kb/ativando-e-desativando-cookies">
                    (https://support.mozilla.org/pt-BR/kb/ativando-e-desativando-cookies)
                  </a>
                </li>
                <li>
                  <strong class="font-semibold">
                    c. Caso você esteja utilizando o Safari:
                  </strong>
                  <a href="http://safari.helpmax.net/ps/privacidade-e-seguranca/como-remover-cookies/">
                    (http://safari.helpmax.net/ps/privacidade-e-seguranca/como-remover-cookies/)
                  </a>
                </li>
                <li>
                  <strong class="font-semibold">
                    d. Caso você esteja utilizando o Opera:
                  </strong>
                  <a href="http://help.opera.com/Windows/10.20/pt/history.html">
                    (http://help.opera.com/Windows/10.20/pt/history.html)
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
