import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function ShapeExposure({ updateUserChoices }) {
  const [ShapeExposure, setShapeExposure] = useState(2);
  const {t} = useTranslation();

  const handleShapeExposureChange = (event) => {
    const value = event.target.value;
    setShapeExposure(value);
    updateUserChoices("ShapeExposureRange", value);
  };

  return (
    <div className="items-center flex flex-col">
      <div className="flex">
      <h1 className='font-title lg:justify-center 
      font-bold py-8 lg:py-2 lg:mb-2 flex items-center'>
        {t("SHAPE EXPOSURE")}
      <div className="w-[0.01px] h-[0.01px] relative">
        <InfoBox
        customClass={"absolute left-1 top-1/2 -translate-y-1/2"}
        text={t("Stronger the exposure more the AI will let the shape participate on the final render.")}/>
      </div>
        </h1>
      </div>
      <div className="flex justify-between max-w-screen items-center w-[90vw] mb-4 lg:w-[25.5rem]">
        <p className="text-white font-title font-bold px-4 py-1 bg-black rounded-full">
        {t("SOFT")}
        </p>
        <p className="text-white font-title font-bold px-4 py-1 bg-black rounded-full">
        {t("MODERATE")}
        </p>
        <p className="text-white font-title font-bold px-4 py-1 bg-black rounded-full">
        {t("STRONG")}
        </p>
      </div>
      <input
        type="range"
        min="1"
        max="3"
        value={ShapeExposure}
        onChange={handleShapeExposureChange}
        className="lg:w-[24.8rem] w-[70vw] appearance-none h-1 rounded-full bg-black opacity-85 outline-none transition-all duration-300"
      />
      <p className='font-paragraph text-xl mt-3 mb-1 lg:text-justify'>{t("How long the AI should hold to the original image's form")}</p>
    </div>
  );

}