import React, { useState, useEffect, useCallback } from "react";
import "tailwindcss/tailwind.css";
import "../Materials.css";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import InfoBox from "../InfoBox";
import Accordion from "./Accordion";

const MaterialTypes = [
  "STUCCO",
  "WOOD",
  "TILE",
  "MARBLE",
  "GREEN",
  "STONE",
  "GLASS",
  "CONCRETE",
];

const MaterialImages = {
  STUCCO: require("./MaterialImages/stucco.png"),
  WOOD: require("./MaterialImages/wood.png"),
  TILE: require("./MaterialImages/tile.png"),
  MARBLE: require("./MaterialImages/marble.png"),
  GREEN: require("./MaterialImages/green.png"),
  STONE: require("./MaterialImages/stone.png"),
  GLASS: require("./MaterialImages/glass.png"),
  CONCRETE: require("./MaterialImages/concrete.png"),
};

function Materials({ updateUserChoices }) {
  const [selectedWalls, setSelectedWalls] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedCeiling, setSelectedCeiling] = useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const {t} = useTranslation();

  const handleWallChange = (event) => {
    const material = event.target.value;
    if (selectedWalls.includes(material)) {
      setSelectedWalls(selectedWalls.filter((m) => m !== material));
    } else if (selectedWalls.length < 2) {
      setSelectedWalls([...selectedWalls, material]);
    }
  };

  const handleOptionsRemove = () => {
    setSelectedWalls([])
    setSelectedFloor("")
    setSelectedCeiling("")
  }

  const updateChoicesCallback = useCallback(() => {
    updateUserChoices("materials", {
      floor: selectedFloor,
      walls: selectedWalls,
      ceiling: selectedCeiling,
    });
  }, [selectedFloor, selectedWalls, selectedCeiling, updateUserChoices]);

  useEffect(() => {
    updateChoicesCallback();
  }, [selectedFloor, selectedWalls, selectedCeiling]); // Dependências que controlam quando updateChoicesCallback deve ser chamado

  const renderMaterialImages = (
    materialArray,
    selectedMaterial,
    onChangeHandler
  ) => {

    return (
      <>
        {materialArray.map((material) => (
          <label
            key={material}
            className="mt-1 font-title text-md text-wrap font-bold"
            style={{ position: "relative" }}
          >
            {selectedMaterial.includes(material) && (
              <div
                className="transition-all duration-500"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: window.innerWidth <= 600 ? "12px" : "inherit",
                }}
              >
                {t(material)}
              </div>
            )}
            <input
              type="checkbox"
              value={material}
              checked={selectedMaterial.includes(material)}
              onChange={onChangeHandler}
              style={{ display: "none" }} // hide the default checkbox
            />
            <img
              src={MaterialImages[material.toUpperCase()]}
              alt={material}
              className={`material-image ${selectedMaterial.includes(material)
                ? "selected transition-all duration-500"
                : ""
                }`}
              style={
                selectedMaterial.includes(material)
                  ? { boxShadow: "0 0 2px 3px rgba(0,0,0,0.5)" }
                  : {}
              }
            />
          </label>
        ))}
      </>
    );
  };


  return (
    <div className="w-full max-w-screen pt-2 bg-[#f3f4f6] -mt-[10px]">
      <div>
            <div className="flex flex-col w-full max-w-screen items-center justify-center">
              <div className='flex flex-col relative justify-center 
              items-center font-bold text-2xl w-full'>
                <div className="flex items-center relative">
                <h1
                  onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                  className='group font-title cursor-pointer transition duration-300'>
                  {t("MATERIALS")}
                  <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-[0.06rem] -mt-1 mb-1 bg-black"></span>
                </h1>
                <div className="w-[1px] h-[1px] relative">
                {isAccordionOpen && (
                  <div className="z-50 absolute top-1/2 -translate-y-1/2">
                  <InfoBox
                  text={t("Choose the materials of your scene here, the model will try its best to follow it, remember you don't need to chose the materials to all surfaces, it may be better to choose only to one or two in order to achieve better results.")} 
                  customClass={"animate-fade-right animate-duration-300"}/>
                  </div>
                )}
                </div>
              </div>
                <span
                  className="h-[0.06rem] w-[75%] 
                  bottom-0 text-transparent duration-500
                bg-black">
                </span>
                <div
                  id="black-triangle"
                  className="w-0 h-0
              border-l-[15px] border-l-transparent
              border-t-[15px] border-t-black
              border-r-[15px] border-r-transparent
            "/>
              </div>
            </div>
        <Accordion
          isOpen={isAccordionOpen}>
          <div className="flex sm:flex-row lg:flex-col justify-center">
            <div className="flex lg:flex-row flex-wrap justify-center items-center lg:space-x-3.5 lg:py-1">
              {renderMaterialImages(MaterialTypes, selectedFloor, (e) =>
                setSelectedFloor(e.target.value)
              )}
              <div className="w-[1px] h-[1px] relative lg:flex-row hidden lg:flex lg:items-center">
              <h2 className="text-sm font-title font-bold lg:mx-0 mx-[100%] lg:text-2xl relative">{t("FLOOR")}</h2>
              </div>
              <h2 className="text-sm font-title font-bold lg:mx-0 mx-[100%] lg:text-2xl relative lg:hidden">{t("FLOOR")}</h2>
            </div>
            <div className="flex flex-row flex-wrap justify-center items-center lg:space-x-3.5 py-1">
              {renderMaterialImages(
                MaterialTypes,
                selectedWalls,
                handleWallChange
              )}
              <div className="w-[1px] h-[1px] relative lg:flex-row hidden lg:flex lg:items-center">
              <h2 className="text-sm font-title lg:mx-0 mx-[100%] font-bold lg:text-2xl">{t("WALLS")}</h2>
              </div>
              <h2 className="text-sm font-title lg:mx-0 mx-[100%] font-bold lg:text-2xl lg:hidden">{t("WALLS")}</h2>
            </div>
            <div className="flex flex-row flex-wrap justify-center items-center lg:py-1 lg:space-x-3.5">
              {renderMaterialImages(MaterialTypes, selectedCeiling, (e) =>
                setSelectedCeiling(e.target.value)
              )}
              <div className="w-[1px] h-[1px] relative lg:flex-row hidden lg:flex lg:items-center">
              <h2 className="text-sm font-title lg:mx-0 mx-[100%] font-bold lg:text-2xl">{t("CEILING")}</h2>
              </div>
              <h2 className="text-sm font-title lg:mx-0 mx-[100%] font-bold lg:text-2xl lg:hidden">{t("CEILING")}</h2>
            </div>
          </div>
          <div className="flex w-full items-center justify-center mt-2">
            <FaTrash
              size={22}
              color='black'
              className='cursor-pointer hover:animate-pulse 
              hover:animate-infinite mb-3'
              onClick={() => handleOptionsRemove(null)}
            />
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default Materials;
