import React, { useEffect } from "react";

const PopupComponent = ({ title, isClosable, state, setState }) => {

  useEffect(() => {
    if (isClosable === true && state === false) {
      const timer = setTimeout(() => {
        setState(true);
      }, 6700);

      return () => clearTimeout(timer);
    }
  }, [isClosable, state]);

  return (
    <div
      onClick={() => setState(true)}
      className={`bg-white shadow-2xl w-[24rem] h-[6rem] fixed z-50
                flex justify-center items-center border-2 -top-16 right-1/2 
                translate-x-1/2 font-title font-bold transition-transform opacity-100
                ease-in-out duration-700 hover:cursor-pointer border-solid border-black
                ${state === false ?
          `translate-y-[100%]` : `-translate-y-[100%]`}`}>
      {title}
      <div
        className={`absolute bottom-0 left-0 bg-black h-1 
                        transition-all
                    ${state === false ?
            "w-full duration-[6700ms] delay-700" :
            "w-0 duration-300"} ease-in-out`}
      />
    </div>
  )
}

PopupComponent.defaultProps = {
  title: "",
  isClosable: true
}

export default PopupComponent;
