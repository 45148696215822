import { useEffect, useState } from "react";
import enUSImage from "../assets/sucesso_ingles.webp";
import ptBRImage from "../assets/sucesso_portugues.webp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";

export default function ThanksPageRegister() {
  const { i18n } = useTranslation();
  const navigate = useNavigate(); // Hook para redirecionamento
  const [isVisible, setIsVisible] = useState(true);

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  const [isRendering, setIsRendering] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 10000);

    return () => clearTimeout(timer)
  }, [isVisible])

  useEffect(() => {
    if(!isVisible) {
      const timer = setTimeout(() => {
        setIsRendering(false)
      }, 500);
  
      return () => clearTimeout(timer)
    }
  }, [isVisible])

  const handleVideoEnd = () => {
    navigate("/Login"); // Redireciona para /Login quando o vídeo terminar
  };

  return (
    <div className="animate-fade animate-duration-300 relative">
      <div className="w-screen h-screen relative">
        <YouTube
          videoId={i18n.language.toLowerCase() === "pt" ? "V83nGOW3BoM" : "qsXkCqveXVA"} // defaults -> ''
          className="z-20"
          iframeClassName={"w-screen h-screen z-20"}
          opts={opts}
          onEnd={handleVideoEnd} // Chama a função handleVideoEnd quando o vídeo terminar
        />
        {/* <div className="w-[55vw] h-[65vh] flex justify-center items-center absolute
        bottom-0 right-0">
          <Link to="/Login">
          <button 
          className="font-title font-bold text-[2vw] hover:animate-pulse
          bg-red-500 border-solid border-2 animate-duration-[3000ms]
          p-[6%] z-10 text-white border-black text-nowrap">
            {i18n.t("START CREATING!")}
          </button>
          </Link>
        </div> */}
      </div>
      {isRendering && (
      <div 
      onClick={() => setIsVisible(false)}
      className={`fixed w-[10vw] bottom-[2vh] font-paragraph transition-opacity ease-in-out duration-500 p-[2%] z-30 left-[2vw] bg-white border-[1px] border-black border-solid text-[130%]
      ${!isVisible && ("opacity-0 -z-20")}`}>
        Here, you can watch to an explanative video about our site and AI usage.
      </div>
      )}
    </div>
  );
}
