import React, { useEffect } from "react";

const MetaPixel = () => {
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");

    if (cookiesAccepted === "all") {
      // Adicionando o Meta Pixel
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      // Inicializando e rastreando com o Meta Pixel
      if (typeof window !== "undefined") {
        window.fbq("init", "1149922769658036"); // Substitua pelo seu ID do pixel
        window.fbq("track", "PageView");
      }

      // Adicionando o noscript
      const noscript = document.createElement("noscript");
      noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1149922769658036&ev=PageView&noscript=1" />`; // Substitua pelo seu ID do pixel
      document.body.appendChild(noscript);

      // Adicionando o Google Tag Manager
      const gtmScript = document.createElement("script");
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=G-EWFHWT2M77`; // Substitua pelo seu ID do GTAG
      document.head.appendChild(gtmScript);

      gtmScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag("js", new Date());
        gtag("config", "G-EWFHWT2M77");
      };
      // Cleanup function
      return () => {
        const fbScript = document.querySelector(
          'script[src="https://connect.facebook.net/en_US/fbevents.js"]'
        );
        if (fbScript) {
          fbScript.remove();
        }
        if (noscript) {
          noscript.remove();
        }
        const gtmScript = document.querySelector(
          `script[src="https://www.googletagmanager.com/gtag/js?id=G-EWFHWT2M77"]`
        );
        if (gtmScript) gtmScript.remove();
      };
    }
  }, []);

  return null;
};

export default MetaPixel;
