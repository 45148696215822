export function geraPrompt(userChoices,user,nomeImage) {


    let prompt_default = `{
  "2": {
    "inputs": {
      "stop_at_clip_layer": -2,
      "clip": [
        "319",
        1
      ]
    },
    "class_type": "CLIPSetLastLayer",
    "_meta": {
      "title": "CLIP Set Last Layer"
    }
  },
  "6": {
    "inputs": {
      "width": 1024,
      "height": 1344,
      "batch_size": 1
    },
    "class_type": "EmptyLatentImage",
    "_meta": {
      "title": "Empty Latent Image"
    }
  },
  "20": {
    "inputs": {
      "text": "A pristine photograph of a modern bedroom",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "22": {
    "inputs": {
      "seed": [
        "47",
        0
      ],
      "steps": 10,
      "cfg": 1.5,
      "sampler_name": "dpmpp_3m_sde_gpu",
      "scheduler": "sgm_uniform",
      "denoise": [
        "38",
        0
      ],
      "model": [
        "319",
        0
      ],
      "positive": [
        "102",
        0
      ],
      "negative": [
        "102",
        1
      ],
      "latent_image": [
        "6",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "KSampler"
    }
  },
  "23": {
    "inputs": {
      "samples": [
        "22",
        0
      ],
      "vae": [
        "319",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "38": {
    "inputs": {
      "value": 0.98
    },
    "class_type": "easy float",
    "_meta": {
      "title": "Denoise"
    }
  },
  "47": {
    "inputs": {
      "seed": 8,
      "🎲 Manual Random Seed": null
    },
    "class_type": "easy seed",
    "_meta": {
      "title": "EasySeed"
    }
  },
  "52": {
    "inputs": {
      "conditioning_to": [
        "53",
        0
      ],
      "conditioning_from": [
        "20",
        0
      ]
    },
    "class_type": "ConditioningConcat",
    "_meta": {
      "title": "Conditioning (Concat)"
    }
  },
  "53": {
    "inputs": {
      "text": "",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "68": {
    "inputs": {
      "image": "1024x1344.jpg",
      "upload": "image"
    },
    "class_type": "LoadImage",
    "_meta": {
      "title": "Load Image"
    }
  },
  "74": {
    "inputs": {
      "text": "blur, distortion, jiggly, low quality",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "88": {
    "inputs": {
      "strength": 0.6,
      "start_percent": 0,
      "end_percent": 0.5,
      "positive": [
        "52",
        0
      ],
      "negative": [
        "74",
        0
      ],
      "control_net": [
        "322",
        0
      ],
      "image": [
        "328",
        0
      ]
    },
    "class_type": "ControlNetApplyAdvanced",
    "_meta": {
      "title": "Apply ControlNet (Advanced)"
    }
  },
  "102": {
    "inputs": {
      "strength": 0.8,
      "start_percent": 0,
      "end_percent": 0.8,
      "positive": [
        "88",
        0
      ],
      "negative": [
        "88",
        1
      ],
      "control_net": [
        "321",
        0
      ],
      "image": [
        "104",
        0
      ]
    },
    "class_type": "ControlNetApplyAdvanced",
    "_meta": {
      "title": "Apply ControlNet (Advanced)"
    }
  },
  "104": {
    "inputs": {
      "low_threshold": 140,
      "high_threshold": 241,
      "resolution": 1024,
      "image": [
        "68",
        0
      ]
    },
    "class_type": "CannyEdgePreprocessor",
    "_meta": {
      "title": "Canny Edge"
    }
  },
  "301": {
    "inputs": {
      "sharpen_radius": 1,
      "sigma": 0.35000000000000003,
      "alpha": 2,
      "image": [
        "23",
        0
      ]
    },
    "class_type": "ImageSharpen",
    "_meta": {
      "title": "ImageSharpen"
    }
  },
  "317": {
    "inputs": {
      "filename_prefix": "sketch2render_interiors_standard_portraid_V0.2.3",
      "file_type": "JPEG",
      "images": [
        "301",
        0
      ]
    },
    "class_type": "SaveImageExtended",
    "_meta": {
      "title": "Save Image (Extended)"
    }
  },
  "319": {
    "inputs": {
      "ckpt_name": "Arcseed_V0.2.safetensors",
      "key_opt": "",
      "mode": "Auto"
    },
    "class_type": "CheckpointLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Checkpoint Loader (Inspire)"
    }
  },
  "320": {
    "inputs": {
      "model_name": "swift_srgan_2x.pth",
      "key_opt_u": "",
      "mode": "Auto"
    },
    "class_type": "UpscaleLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Upscale Loader (Inspire)"
    }
  },
  "321": {
    "inputs": {
      "control_net_name": "LoraModelCanny.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "322": {
    "inputs": {
      "control_net_name": "LoraModelDepth.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "323": {
    "inputs": {
      "control_net_name": "ttplanetSDXLControlnet_v20Fp16.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "324": {
    "inputs": {
      "clip_name": "clipvis_ViT-H_1.5_.safetensors",
      "key_opt_cv": "",
      "mode": "Auto"
    },
    "class_type": "CLIPVisionLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Clip Loader (Inspire)"
    }
  },
  "325": {
    "inputs": {
      "key": 1
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  },
  "328": {
    "inputs": {
      "seed": 127,
      "denoise_steps": 6,
      "ensemble_size": 3,
      "processing_resolution": 768,
      "scheduler": "LCMScheduler",
      "use_taesd_vae": false,
      "marigold_model": [
        "329",
        0
      ],
      "image": [
        "68",
        0
      ]
    },
    "class_type": "MarigoldDepthEstimation_v2",
    "_meta": {
      "title": "MarigoldDepthEstimation_v2"
    }
  },
  "329": {
    "inputs": {
      "key": 2
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  }
}`;
  
    prompt_default = JSON.parse(prompt_default);
  
    const { floor, walls, ceiling } = userChoices["materials"] || {};
  
  
    const modelos = userChoices["aiModels"] || '';
  let modeloFrase = "";

  switch (modelos) {
    case "DEFAULT":
      modeloFrase = " ";
      break;
    case "RENDER":
      modeloFrase = "A 3D render of";
      break;
    case "CINEMATIC":
      modeloFrase = "A Cinematic professional shot of";
      break;
    case "PHOTOGRAPHER":
      modeloFrase = "A Photograph of";
      break;
    case "ARCHVIZ":
      modeloFrase = "A Professional architectural rendering of";
      break;
    case "PRISTINE":
      modeloFrase = "A Pristine photograph of";
      break;
  }

  const anStyles = [
    "arabic", "art deco", "art nouveau", "asian fusion", "eclectic", "english",
    "industrial", "japandi", "oriental", "urban modern"
  ];
  const style = userChoices["style"] || '';
  
  // Se o estilo não for selecionado, styleFrase será uma string vazia.
  let styleFrase = '';
  if (style) {
    styleFrase = anStyles.includes(style) ? `an ${style}` : `a ${style}`;
  }

  let whereExterior = userChoices["whereExterior"] || '';
  let time = userChoices["time"] || '';
  let colorPalette = userChoices["colorPalette"] || [];
  let what = userChoices["what"] || '';
  let Style = styleFrase;
  let Modes = `${modeloFrase}` || '';
  let weather = userChoices["weather"] || '';

  if (what === "Square") {
    what = "a public plaza";
  }

  // Remove duplicatas da paleta de cores
  colorPalette = [...new Set(colorPalette)];

  // Construir a frase do chão
  let floorFrase = userChoices["floor"] ? `${userChoices["floor"]} floor` : '';

  // Construir a frase das paredes

  let wallsFrase = '';
if (Array.isArray(walls) && walls.length > 0) {
  if (walls.length === 1) {
    wallsFrase = `${walls[0]} walls`;
  } else if (walls.length === 2) {
    wallsFrase = `${walls[0]} and ${walls[1]} walls`;
  } else {
    wallsFrase = `${walls.slice(0, -1).join(', ')} and ${walls[walls.length - 1]} walls`;
  }
}

  // Construir a frase do teto
  let ceilingFrase = userChoices["ceiling"] ? `${userChoices["ceiling"]} ceiling` : '';

  // Construa o prompt apenas com os parâmetros fornecidos
  const promptParts = [
    Modes,
    Style,
    what ? `${what},` : '',  // Adiciona vírgula após what
    time ? `${time},` : '',    // Adiciona vírgula após time
    whereExterior ? `${whereExterior},` : '',
    weather
  ].filter(Boolean).map(part => part.trim()); // Remove valores falsos e trim

  // Junta os elementos em uma única string
  const promptText = promptParts.join(' ').replace(/\s{2,}/g, ' ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

  // Atualiza o texto no nó 20
  prompt_default["20"]["inputs"]["text"] = promptText.trim();
    prompt_default["53"]["inputs"]["text"] = [...colorPalette, floorFrase, wallsFrase, ceilingFrase].filter(Boolean).join(', ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');


    const shapeStrengthMap = {
      1: { "CN1": 0.3, "CN2": 0.3 },
      2: { "CN1": 0.6, "CN2": 0.8 },
      3: { "CN1": 0.8, "CN2": 1.0 }
    };
  
    const shapeExposureMap = {
      1: { "CN1": 0.2, "CN2": 0.5 },
      2: { "CN1": 0.5, "CN2": 0.8 },
      3: { "CN1": 0.8, "CN2": 1.0 }
    };
  
    // Recupera as escolhas do usuário para ShapeStrength e ShapeExposure
    const userShapeStrengthChoice = userChoices["ShapeStrengthRange"];
    const userShapeExposureChoice = userChoices["ShapeExposureRange"];
  
    // Define os valores de acordo com a escolha do usuário
    const selectedShapeStrength = shapeStrengthMap[userShapeStrengthChoice];
    const selectedShapeExposure = shapeExposureMap[userShapeExposureChoice];
  
    // Aplica os valores ao prompt_default para CN1 (88) e CN2 (102)
    prompt_default["88"]["inputs"]["strength"] = selectedShapeStrength["CN1"];
    prompt_default["102"]["inputs"]["strength"] = selectedShapeStrength["CN2"];
  
    prompt_default["88"]["inputs"]["end_percent"] = selectedShapeExposure["CN1"];
    prompt_default["102"]["inputs"]["end_percent"] = selectedShapeExposure["CN2"];
  
    prompt_default["88"]["inputs"]["start_percent"] = 0;
    prompt_default["102"]["inputs"]["start_percent"] = 0;
  
    const aiImaginationMap = {
      5: 3.0,
      4: 2.0,
      3: 1.5,
      2: 1.25,
      1: 1.0
    };
  
  // Recupera a escolha do usuário para AI Imagination Range
  const userAIImaginationChoice = userChoices["AIImmaginationRange"];
  
  // Define o valor de cfg de acordo com a escolha do usuário
  const selectedAIImaginationValue = aiImaginationMap[userAIImaginationChoice];
  
  // Aplica o valor ao prompt_default para cfg
  prompt_default["22"]["inputs"]["cfg"] = selectedAIImaginationValue;

    
    prompt_default["22"]["inputs"]["seed"] = `${userChoices["seed"]}`;
  
    prompt_default["68"]["inputs"]["image"] = `${nomeImage}`;
    
    prompt_default["317"]["inputs"]["filename_prefix"] = `${user}`;
    
    
  
    const prompt = prompt_default;
    //console.log(prompt);
    return prompt;
  }
  