import React, { useState } from "react";
import { useEffect } from "react";
import "./index.css";
import "./App.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { add_Back } from "../../address";
import { IoIosArrowBack } from "react-icons/io";
import NewPopUpComponent from "../Generic/NewPopUpComponent";
import { Context } from "../../App";
import { useContext } from "react";

export default function Login() {
  const [warningMessage, setWarningMessage] = useContext(Context);
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLinkClick = () => {
    // Recarrega a página ao clicar no link
    window.location.href = "/Register";
  };

  const handleOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest(".menu-container")) {
      setIsMenuOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  // INICIO DE LOGIN COM BACK

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const maxEmailLength = 40; // Define o limite de caracteres


  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    if (inputEmail.length <= maxEmailLength) {
      setEmail(inputEmail);
      validateEmail(inputEmail);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+$/i;
    setIsValidEmail(emailRegex.test(email));
  }; 
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowMessage(false); // Reset message visibility

    try {
      const response = await axios.post(`${add_Back}/enviaLink`, { email });

      if (response.status === 200) {
        setMessage(t("E-mail sent"));
      } else {
        setMessage(response.data.error || t("E-mail not found!"));
      }
    } catch (error) {
      setMessage(t("E-mail not found!"));
      console.error("Erro ao enviar solicitação:", error);
    } finally {
      setShowMessage(true); // Show message after response or error
    }
  };

  // FIM DO BLOCO

  useEffect(() => {
    /* Função responsável pelo título das páginas */
    document.title = "Forgot password - Arcseed AI";
    return () => {
      document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
    };
  }, []);

  const [messageIndex, setMessageIndex] = useState(0);

  const getMessage = () => {
    const messages = [t("E-mail not found!"), t("E-mail sent")];
    return messages[messageIndex % messages.length];
  };
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  const handleSubmitClick = () => {
    // Update message index and show message
    setMessageIndex((prevIndex) => (prevIndex + 1) % 2);
    setShowMessage(true);
  };

  return (
    <section className="min-h-screen bg-black flex flex-col">
      <NewPopUpComponent isClosable={true} />
      <div className="flex justify-start m-[5%]">
        <Link to="/">
          {/* Usando o ícone para voltar à página inicial */}
          <button className="rounded-full bg-gray-900 text-white p-2">
            <IoIosArrowBack size={24} />
          </button>
        </Link>
      </div>
      <div className=" w-full flex items-center justify-center h-full">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4  ">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-zinc-100 border-0">
                <div className="flex flex-col justify-center px-4 lg:px-10 py-6 pt-0">
                  <div className="text-blueGray-400 text-center mt-6 mb-3 font-bold">
                    <small className="font-title text-sm">
                      {t("Forgot password")}
                    </small>
                    <p className="font-paragraph font-light text-xl">
                      {t("Type your account e-mail down below")}
                    </p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="relative w-full mb-3">
                      <label
                        className="relative w-full font-paragraph text-left text-xl mb-3"
                        htmlFor="grid-password"
                      >
                        E-mail
                      </label>
                      <input
                        id="emailInput"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        className="border-0 p-2 placeholder-blueGray-300 
                        text-blueGray-600 bg-white rounded font-paragraph font-bold text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="E-mail"
                        required
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-slate-300 font-title bg-opacity-70 hover:bg-opacity-100 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                        onClick={handleSubmitClick}
                      >
                        {t("Send e-mail".toUpperCase())}
                      </button>
                    </div>

                    {showMessage && (
                      <p
                        className={`font-paragraph mt-4 font-light ${
                          message.includes("not found") ? "text-red-600" : ""
                        }`}
                      >
                        {message}
                      </p>
                    )}
                  </form>
                </div>
              </div>
              <div className="flex justify-between -mt-4 px-4 relative">
                <div className="font-paragraph hover:underline text-xl text-white">
                  <Link to="/Login">
                    <small>{t("Login")}</small>
                  </Link>
                </div>
                <div className="font-paragraph hover:underline text-xl text-white">
                  <Link onClick={handleLinkClick}>
                    <small>{t("Create new account")}</small>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
