import { Link } from 'react-scroll';
import { Link as LinkRouter } from 'react-router-dom';
import { FaArrowDown } from "react-icons/fa";
import {useTranslation} from "react-i18next";

function GreetingsScreen() {
  const {t} = useTranslation();

  return (
    <section id="greetings-screen" className="w-full max-w-[100vw] overflow-hidden h-screen relative bg-black flex flex-col justify-center items-center">
      <p className="font-extralight font-paragraph w-full text-[150%] lg:w-fit lg:text-[1.4vw] text-white text-center">{t("We are bringing the future to you!")}</p>
        <div className='relative group'>
        <LinkRouter
        to="/Register" 
        className="cursor-pointer text-white transition 
        duration-300 relative font-extrabold lg:text-[4vw] font-title 
        text-[200%] text-center">
          {t("MAKE IT HAPPEN TODAY WITH US!")}
          <span className="ease-in-out duration-500 
          absolute left-0 -bottom-0
          bg-white h-0.5 w-0 group-hover:w-full"/>
        </LinkRouter>
        </div>
      <div id="footer" className="flex absolute left-0 bottom-0 w-screen max-w-screen justify-center">
      <Link to="solutions" spy={true} 
      smooth={true} 
      offset={0} 
      duration={750}>
            <FaArrowDown
              size={55}
              color="white"
              className="cursor-pointer mb-3"
            />
            </Link>
        </div>
    </section>
  );

}

export default GreetingsScreen;